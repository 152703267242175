import React, { PureComponent } from 'react';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';
import { LargeDesktop } from 'components/MediaQuery';
import Text from 'components/Text';

import cxHelpers from 'util/className';
import { trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
} from 'util/tracking_constants';

import { SETTINGS_ROUTES } from '../../constants';
import withNavItemActive from '../../hocs/withNavItemActive';
import withNavItemData from '../../hocs/withNavItemData';
import NavItem from '../NavItem';

@withNavItemActive(SETTINGS_ROUTES)
@withNavItemData('settings')
@cxHelpers('SettingsNavItem')
export default class SettingsNavItem extends PureComponent {
  static propTypes = {
    url: PropTypes.string.isRequired,
    mobile: PropTypes.bool,
    fullText: PropTypes.bool,
  };

  // Opens in browser tab if cmd+click; otherwise, renders without full refresh
  handleClick = e => {
    trackUxEvent({
      productArea: PRODUCT_AREAS.NAVIGATION,
      eventCategory: EVENT_CATEGORIES.NAV,
      eventAction: EVENT_ACTIONS.SETTINGS_TAB_CLICKED,
    });

    if (!e.metaKey) {
      e.preventDefault();
      window.Homebase.RailsReactBridge.navigateToReactView(this.props.url);
      return false;
    }
  };

  render() {
    const { url, currentRoute, fullText, ...props } = omit(
      this.props,
      'whiteText'
    );

    return (
      <NavItem
        {...props}
        href={url}
        onClick={this.handleClick}
        className={this.cx()}
      >
        <LargeDesktop>
          {desktop =>
            fullText || desktop ? (
              <Text fs14 i18n="nav_links.settings.top_level" />
            ) : (
              <Icon type="cog" size={16} />
            )
          }
        </LargeDesktop>
      </NavItem>
    );
  }
}
