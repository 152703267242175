import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { deleteJSONWithoutParsing } from 'api/fetch';
import Box from 'fe-design-base/atoms/Box';
import Button from 'fe-design-base/molecules/Button';
import { useIsEmbedded } from 'hooks/useIsEmbedded';
import Immutable from 'immutable';

import { setModalPromoCode } from 'actions/modals';
import * as modalActions from 'actions/modals';

import { getUserData } from 'selectors/nav';
import {
  getCurrentCompanyIsEligibleForDsu,
  getCurrentUser,
  getEarlyLifeDiaryStudyExperienceVersion,
  getRolloutEnabled,
} from 'selectors/session';

import { changeToNewTier } from 'features/biller/slice';
import {
  openCalendlyEnterpriseModalCbAction,
  openCalendlyModalCbAction,
  openCheckoutModalCbAction,
  openSawtoothSurvey,
  showClickToCallDialogCbAction,
  signOutFromDsuCbAction,
  startDsuSessionCbAction,
} from 'features/commandBar/commandBarCallbackActions';
import { SESSION_STORAGE_KEYS } from 'features/onboarding/constants';
import { hidePayrollCountdownBannerLocalStorageKey } from 'features/payroll/constants';

import { CalendlyEnterpriseModal } from 'components/CalendlyEnterpriseModal/CalendlyEnterpriseModal';
import { useCalendlyEnterpriseModalHandlers } from 'components/CalendlyEnterpriseModal/hooks';
import { CalendlyModal } from 'components/CalendlyModal/CalendlyModal';
import { useCalendlyModalHandlers } from 'components/CalendlyModal/hooks';

import { useRegisterCommandBarCallbackAction } from 'util/commandBar';
import {
  clearObjectInLocalStorage,
  getObjectFromLocalStorage,
} from 'util/localStorageWrapper';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';
import { useTrackUx, withUxRoot } from 'util/uxEvents';

export type CommandBarProps = {
  children: React.ReactNode;
  userData: Immutable.Map<string, string>;
  currentUser: Immutable.Map<string, string>;
  isEligibleForDsu: boolean;
  onChangeToNewTier: ({
    tierName,
    workflowSource,
    billingFrequency,
  }: {
    tierName: string;
    workflowSource: string;
    billingFrequency: string;
  }) => void;
  onSetModalPromoCode: (promoCode: string) => void;
  showClickToCallDialog: () => void;
  monetizationCommandBarEnabled: boolean;
  earlyLifeDiaryStudyExperienceVersion: number;
};

export const CommandBar = ({
  children,
  userData,
  currentUser,
  isEligibleForDsu,
  onChangeToNewTier,
  onSetModalPromoCode,
  showClickToCallDialog,
  monetizationCommandBarEnabled,
  earlyLifeDiaryStudyExperienceVersion,
}: CommandBarProps) => {
  const { calendlyModalIsOpen, handleCalendlyClick, handleCalendlyClose } =
    useCalendlyModalHandlers();
  const {
    calendlyEnterpriseModalIsOpen,
    handleCalendlyEnterpriseClick,
    handleCalendlyEnterpriseClose,
  } = useCalendlyEnterpriseModalHandlers();
  const [calendlyUrl, setCalendlyUrl] = useState<string | undefined>('');
  const [calendlyEnterpriseUrl, setCalendlyEnterpriseUrl] = useState<
    string | undefined
  >('');
  const isEmbedded = useIsEmbedded();

  const openCalendlyModal = useCallback(
    ({ url }: { url?: string }) => {
      setCalendlyUrl(url);
      handleCalendlyClick();
    },
    [handleCalendlyClick]
  );

  const openCalendlyEnterpriseModal = useCallback(
    ({ url }: { url?: string }) => {
      setCalendlyEnterpriseUrl(url);
      handleCalendlyEnterpriseClick();
    },
    [handleCalendlyEnterpriseClick]
  );

  const dsuSessionStarted = getObjectFromLocalStorage(
    'earlylife.dsuSessionStarted'
  );

  const showDsuButtons =
    isEligibleForDsu &&
    earlyLifeDiaryStudyExperienceVersion === 1 &&
    dsuSessionStarted;

  const signOutFromDsu = useCallback(() => {
    window.localStorage.removeItem(hidePayrollCountdownBannerLocalStorageKey);
    window.sessionStorage.removeItem(
      SESSION_STORAGE_KEYS.TIMESHEETS_GUIDED_SETUP_MAYBE_LATER
    );
    clearObjectInLocalStorage('earlylife.dsuSessionStarted');
    deleteJSONWithoutParsing(userData.get('signOutUrl')).catch(
      window.location.reload
    );

    window.location.reload();
  }, [userData]);

  const trackUx = useTrackUx();

  const handleClickEndSession = useCallback(() => {
    trackUx(EVENT_ACTIONS.BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      early_life_dsu_experiment_end_session_cta: true,
    });
  }, [trackUx]);

  const handleClickGiveFeedback = useCallback(() => {
    trackUx(EVENT_ACTIONS.BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      early_life_dsu_experiment_give_feedback_cta: true,
    });
  }, [trackUx]);

  useRegisterCommandBarCallbackAction(
    'openCheckoutModal',
    openCheckoutModalCbAction({
      onChangeToNewTier,
      setModalPromoCode: onSetModalPromoCode,
    })
  );
  useRegisterCommandBarCallbackAction(
    'openCalendlyModal',
    openCalendlyModalCbAction({
      openCalendlyModal,
    })
  );
  useRegisterCommandBarCallbackAction(
    'openCalendlyEnterpriseModal',
    openCalendlyEnterpriseModalCbAction({
      openCalendlyEnterpriseModal,
    })
  );
  useRegisterCommandBarCallbackAction(
    'openSawtoothSurvey',
    openSawtoothSurvey({ userId: currentUser?.get('id') })
  );
  useRegisterCommandBarCallbackAction(
    'showClickToCallDialog',
    showClickToCallDialogCbAction({
      showClickToCallDialog,
    })
  );
  useRegisterCommandBarCallbackAction(
    'signOutFromDsu',
    signOutFromDsuCbAction({ signOutFromDsu })
  );
  useRegisterCommandBarCallbackAction(
    'startDsuSession',
    startDsuSessionCbAction({
      startDsuSession: () => {
        window.location.reload();
        localStorage.setItem('earlylife.dsuSessionStarted', 'true');
      },
    })
  );

  if (
    isEmbedded ||
    !monetizationCommandBarEnabled ||
    window.location.pathname.includes('/admin')
  ) {
    return children || null;
  }

  return (
    <>
      {children || null}
      {calendlyModalIsOpen && calendlyUrl && (
        <CalendlyModal
          url={calendlyUrl}
          open={calendlyModalIsOpen}
          handleCloseModal={handleCalendlyClose}
        />
      )}
      {calendlyEnterpriseModalIsOpen && calendlyEnterpriseUrl && (
        <CalendlyEnterpriseModal
          origin="command_ai"
          currentUser={currentUser}
          url={calendlyEnterpriseUrl}
          open={calendlyEnterpriseModalIsOpen}
          handleCloseModal={handleCalendlyEnterpriseClose}
        />
      )}
      {showDsuButtons && (
        <Box fixed flex bottom="24px" right="24px" gap={12} zIndex={999}>
          <Button
            variant="secondaryDestructive"
            onClick={handleClickEndSession}
          >
            End session
          </Button>
          <Button variant="primary" onClick={handleClickGiveFeedback}>
            Give feedback
          </Button>
        </Box>
      )}
    </>
  );
};

export default connect(
  state => ({
    userData: getUserData(state),
    currentUser: getCurrentUser(state),
    isEligibleForDsu: getCurrentCompanyIsEligibleForDsu(state),
    monetizationCommandBarEnabled: getRolloutEnabled(
      state,
      'monetization_command_bar'
    ),
    earlyLifeDiaryStudyExperienceVersion:
      getEarlyLifeDiaryStudyExperienceVersion(state),
  }),
  {
    onChangeToNewTier: changeToNewTier,
    onSetModalPromoCode: setModalPromoCode,
    showClickToCallDialog: modalActions.showClickToCallDialog,
  }
)(
  withUxRoot({
    productArea: PRODUCT_AREAS.COMMAND_BAR,
    eventCategory: EVENT_CATEGORIES.COMMAND_BAR,
  })(CommandBar)
);
