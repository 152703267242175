import { createAsyncThunk } from '@reduxjs/toolkit';

import { browserHistory } from 'util/router';

import { postPayrollFirstPayDate, postUploadDocument } from './api';

export const SLICE_NAME = 'productGrowth';

export const createPayrollFirstPayDate = createAsyncThunk<string, string>(
  `${SLICE_NAME}/createPayrollFirstPayDate`,
  (payload, { rejectWithValue }) =>
    postPayrollFirstPayDate(payload)
      .then(() => browserHistory.push('/payroll_onboarding/upload'))
      .catch(error => rejectWithValue(error.body || error.message))
);

export const createUploadDocument = createAsyncThunk(
  `${SLICE_NAME}/createUploadDocument`,
  (document: File, { rejectWithValue }) =>
    postUploadDocument(document).catch(error =>
      rejectWithValue(error.body || error.message)
    )
);
