import Location from 'fe-core/Location';
import { uniq } from 'lodash';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import Job from 'resources/Job';
import Role from 'resources/Role';
import Shift from 'resources/Shift';
import User from 'resources/User';

import { getCurrentLocationId } from 'selectors/session';

import { DEFAULT_ROLE_COLOR } from 'features/scheduleBuilder/constants';
import {
  CONSECUTIVE_SHIFTS_MAX,
  DRAWERS,
  EMPLOYEE_VIEW_FILTERS,
  MODALS,
  SLICE_NAME,
  SORT_BY_WEEK_OPTIONS,
  VIEW_TYPES,
} from 'features/scheduleBuilder/ScheduleBuilderView/constants';
import {
  getUser,
  sortByAttribute,
} from 'features/scheduleBuilder/ScheduleBuilderView/selectors/helpers';
import { containsShiftsInCurrentLocation } from 'features/scheduleBuilder/ScheduleBuilderView/utils/containsShiftsInCurrentLocation';
import {
  generateNextNWeeks,
  getEntityCurrentDate,
  getFirstName,
  getFullName,
  getScheduleAvailabilitiesIds,
  isDateInRange,
  isShiftWithinRange,
} from 'features/scheduleBuilder/util';

import {
  compareDateTime,
  dateFromTime,
  datesInRange,
  df,
  startOfCurrentWeek,
} from 'util/dateTime';
import { toI18n } from 'util/i18n';

import Department from '../resources/Department';
import JobPosition from '../resources/JobPosition';
import Schedule from '../resources/Schedule';
import ScheduleAvailability from '../resources/ScheduleAvailability';
import ScheduleTemplate from '../resources/ScheduleTemplate';
import ShiftConflict from '../resources/ShiftConflict';
import TimeOff from '../resources/TimeOff';
import shiftSortCompare from '../utils/shiftSortCompare';
import sortEmployeeInDepartmentByName from '../utils/sortEmployeeInDepartmentByName';
import timeOffSortCompare from '../utils/timeOffSortCompare';

const SHIFT_FORM_I18N_PATH = 'schedule_builder.react_page.shift_form';

const selectSlice = state => state.get(SLICE_NAME) || {};

export const selectIsJumpstartClosed = createSelector(
  selectSlice,
  slice => slice.isJumpstartClosed
);

export const selectIsAvailabilitiesVisible = createSelector(
  selectSlice,
  slice => slice.isAvailabilitiesVisible
);

export const selectIsTimeOffsVisible = createSelector(
  selectSlice,
  slice => slice.isTimeOffsVisible
);

export const selectIsShiftsAtThisLocationOnly = createSelector(
  selectSlice,
  slice => slice.isShiftsAtThisLocationOnly
);

export const selectNotificationMessage = createSelector(
  selectSlice,
  slice => slice.notificationCenterMessage
);

export const selectNotificationType = createSelector(
  selectSlice,
  slice => slice.notificationCenterType
);

export const selectNotificationIsAI = createSelector(
  selectSlice,
  slice => slice.notificationCenterMessageAI
);

export const selectDepartmentId = createSelector(
  selectSlice,
  slice => slice.departmentId
);

export const selectIsScheduleUsersOnly = createSelector(
  selectSlice,
  slice => slice.employeeViewFilter === EMPLOYEE_VIEW_FILTERS.SCHEDULED
);

export const selectCopyPasteShift = createSelector(
  selectSlice,
  slice => slice.copyPasteShift
);

export const selectIsCopyShiftModeEnabled = createSelector(
  selectCopyPasteShift,
  copiedShift => !!copiedShift
);

export const selectConflictsEnabled = createSelector(
  selectSlice,
  slice => slice.conflictsEnabled
);

export const selectCustomJobIds = createSelector(
  selectSlice,
  slice => slice.customJobIds
);

export const selectSortByDay = createSelector(
  selectSlice,
  slice => slice.sortByDay
);

export const selectCopyWeekModalIsOpen = createSelector(
  selectSlice,
  slice => slice.copyWeekModalIsOpen
);

const decorateShift = shift => ({
  ...shift,
  decoration: {
    date: dateFromTime(shift.attributes.startAt),
  },
});

export const selectDecoratedShifts = createSelector(
  Shift.selectAllEntities,
  shifts => shifts.map(decorateShift)
);

export const selectCurrentDateRange = createSelector(
  selectSlice,
  slice => slice.currentDateRange
);

export const selectCurrentDateRangeStart = createSelector(
  selectCurrentDateRange,
  currentDateRange => currentDateRange?.start
);

export const selectCurrentDateRangeEnd = createSelector(
  selectCurrentDateRange,
  currentDateRange => currentDateRange?.end
);

export const selectViewType = createSelector(
  selectSlice,
  slice => slice.viewType
);

export const selectRangeType = createSelector(
  selectSlice,
  slice => slice.rangeType
);

export const selectIsPastWeek = createSelector(
  selectCurrentDateRangeStart,
  startDate =>
    compareDateTime(startOfCurrentWeek(df('parsable_reversed')), startDate) > 0
);

export const selectJobIdsForUser = createCachedSelector(
  (state, props) => {
    const userId = Job.selectRelationshipId(state, 'user', props.jobId);

    const jobs = User.selectRelatedEntities(state, 'jobs', Job, userId);

    return jobs.map(job => job.id).join(',');
  },
  jobs => (jobs.length ? jobs.split(',') : [])
)((_, props) => `selectJobIdsForUser-${props.jobId}`);

export const selectNextNWeeks = createCachedSelector(
  state => selectCurrentDateRange(state)?.end,
  (_, props) => props.numberOfWeeks,
  (endDate, numberOfWeeks) =>
    generateNextNWeeks({ start: endDate, numberOfWeeks })
)((_, props) => `selectNextNWeeks-${props.numberOfWeeks}`);

export const selectUserEntityByJobId = createCachedSelector(
  (state, props) => Job.selectRelatedEntity(state, 'user', User, props.jobId),
  userEntity => userEntity
)((_, props) => `selectUserEntityByJobId-${props.jobId}`);

export const selectUserAvatarUrlByJobId = createSelector(
  (state, props) => selectUserEntityByJobId(state, { jobId: props.jobId }),
  userEntity => userEntity?.attributes?.avatar
);

export const selectShiftColor = createCachedSelector(
  (state, { id }) => Shift.selectAttribute(state, 'color', id),
  (state, { id }) => {
    const relationshipId = Shift.selectRelationshipId(state, 'role', id);
    return relationshipId
      ? Role.selectAttribute(state, 'color', relationshipId)
      : DEFAULT_ROLE_COLOR;
  },
  (shiftColor, defaultRoleColor) => shiftColor || defaultRoleColor
)((_, { id }) => `selectShiftColor-${id}`);

export const selectUserFirstName = createCachedSelector(
  (state, props) => Job.selectRelatedEntity(state, 'user', User, props.jobId),
  userEntity => getFirstName(userEntity)
)((_, props) => `selectUserFirstName-${props.jobId}`);

export const selectUserNameWithLastInitial = createCachedSelector(
  (state, props) => Job.selectRelatedEntity(state, 'user', User, props.jobId),
  userEntity => getFullName(userEntity, true)
)((_, props) => `selectUserNameWithLastInitial-${props.jobId}`);

export const selectUserName = createCachedSelector(
  (state, props) =>
    selectUserEntityByJobId(state, {
      jobId: Shift.selectRelationshipId(state, 'owner', props.shiftId),
    }),
  userEntity => getFullName(userEntity)
)((_, props) => `selectUserName-${props.shiftId}`);

export const selectFullNameByJob = createCachedSelector(
  (state, props) => selectUserEntityByJobId(state, props),
  userEntity => getFullName(userEntity)
)((_, props) => `selectUserName-${props.jobId}`);

export const selectSearchTerm = createSelector(
  selectSlice,
  slice => slice.searchTerm
);

export const selectDrawerUxContext = createSelector(
  selectSlice,
  slice => slice.drawerUxContext
);

export const selectJumpstartDrawerIsShown = createSelector(
  selectSlice,
  slice => slice.activeDrawer === DRAWERS.jumpstart_drawer
);

export const selectPublishDrawerShown = createSelector(
  selectSlice,
  slice => slice.activeDrawer === DRAWERS.publish_drawer
);

export const selectPrintScheduleDrawerShown = createSelector(
  selectSlice,
  slice => slice.activeDrawer === DRAWERS.print_schedule_drawer
);

export const selectTemplatesDrawerShown = createSelector(
  selectSlice,
  slice => slice.activeDrawer === DRAWERS.templates_drawer
);

export const selectPendingJobLaborTotalsIds = createSelector(
  selectSlice,
  slice => slice.pendingJobLaborTotalsIds || []
);

export const selectCompanyShifts = createCachedSelector(
  selectSlice,
  slice => slice.companyLaborTotals || []
)((_, props) => `selectCompanyShifts-${props.date}`);

export const selectCompanyLaborHoursByJobId = createCachedSelector(
  selectCompanyShifts,
  (_, props) => props.jobId,
  selectCurrentDateRangeStart,
  selectCurrentDateRangeEnd,
  (companyLaborShifts, jobId, startDate, endDate) => {
    const userCompanyShifts =
      companyLaborShifts.find(labor => String(labor.job_id) === jobId)
        ?.shifts || [];

    if (userCompanyShifts.length === 0) return 0;

    const userCompanyShiftsWithinDateRange = userCompanyShifts.filter(shift => {
      const shiftStartDate = shift.start_at.split('T')[0];
      const shiftStart = Date.parse(shiftStartDate);
      const rangeStart = Date.parse(startDate);
      const rangeEnd = Date.parse(endDate);

      return shiftStart >= rangeStart && shiftStart <= rangeEnd;
    });

    return userCompanyShiftsWithinDateRange.reduce(
      (total, shift) => total + shift.hours,
      0
    );
  }
)((_, props) => `selectCompanyLaborHoursByJobId-${props.date}`);

export const selectHasPendingData = createSelector(
  selectSlice,
  slice => slice.hasPendingData
);

// Note: Cannot be used in other selectors since this data is being mutated
// when we build row data (see addRowData)
export const selectShiftsByJobAndDate = createSelector(
  selectDecoratedShifts,
  shifts =>
    shifts.reduce((memo, shift) => {
      const { id: ownerId, type: ownerType } = shift.relationships.owner.data;

      if (ownerType !== 'job') return memo;

      const date = shift.decoration.date;

      memo[ownerId] ||= {};
      memo[ownerId][date] ||= [];
      memo[ownerId][date].push(shift);
      return memo;
    }, {})
);

export const selectAllEmployeeIds = state =>
  Schedule.selectRelationshipIds(state, 'jobs');

export const selectAllTemplates = createSelector(
  ScheduleTemplate.selectAllEntities,
  state => Department.selectAllEntities(state).length,
  (templates, departmentsCount) =>
    templates.reduce((memo, template) => {
      memo.unshift({
        id: template.attributes.id,
        lastModified: template.attributes.updatedAt,
        totalShifts: template.attributes.shiftsCount,
        totalHours: template.attributes.totalHours,
        username: template.attributes.updatedByName,
        title: template.attributes.name,
        departments: toI18n(
          'schedule_builder.templates_drawer.departments_stats',
          {
            props: {
              unique_departments: template.attributes.uniqueDepartmentsCount,
              total_departments: departmentsCount,
            },
          }
        ),
      });

      return memo;
    }, [])
);

export const selectJobsForCurrentLocation = createSelector(
  state =>
    getCurrentLocationId(state) ||
    state.getIn(['session'])?.currentLocation?.id,
  Job.selectAllEntities,
  (currentLocationId, jobs) =>
    jobs?.filter(
      job =>
        job.id &&
        job.relationships?.location?.data?.id === String(currentLocationId)
    )
);

export const selectJobIdArchivedAtMap = createSelector(
  selectJobsForCurrentLocation,
  jobs =>
    jobs?.reduce((memo, job) => {
      if (job.attributes?.archivedAt) {
        memo[job.id] = job.attributes.archivedAt;
      }

      return memo;
    }, {})
);

export const selectJobIdUserIdMap = createSelector(
  selectJobsForCurrentLocation,
  jobs =>
    jobs?.reduce((memo, job) => {
      if (job.relationships?.user?.data?.id) {
        memo[job.id] = job.relationships.user.data.id;
      }
      return memo;
    }, {})
);

export const selectShiftIdJobIdMap = createSelector(
  selectDecoratedShifts,
  shifts =>
    shifts?.reduce((memo, shift) => {
      if (shift.relationships?.owner?.data?.id) {
        memo[shift.id] = shift.relationships?.owner?.data?.id;
      }
      return memo;
    }, {})
);

export const selectJobIdWageRateMap = createSelector(
  Job.selectAllEntities,
  jobs =>
    jobs?.reduce((memo, job) => {
      if (job.attributes?.wageRate) {
        memo[job.id] = job.attributes?.wageRate;
      }
      return memo;
    }, {})
);

export const selectJobIdLocationIdMap = createSelector(
  Job.selectAllEntities,
  jobs =>
    jobs?.reduce((memo, job) => {
      if (job.relationships?.location?.data?.id) {
        memo[job.id] = job.relationships.location.data.id;
      }
      return memo;
    }, {})
);

export const selectJobIdsForCurrentLocation = createSelector(
  state =>
    selectJobsForCurrentLocation(state)
      .map(job => job.id)
      .join(','),
  ids => (ids.length ? ids.split(',') : [])
);

export const selectUserIdUserNameMap = createSelector(
  User.selectAllEntities,
  users =>
    users.reduce((memo, user) => {
      memo[user.id] = {
        fullName: `${user.attributes.firstName ?? ''} ${
          user.attributes.lastName ?? ''
        }`.trim(),
        firstName: user.attributes.firstName,
        lastName: user.attributes.lastName,
      };
      return memo;
    }, {})
);

export const selectDatesInCurrentRange = createSelector(
  selectCurrentDateRangeStart,
  selectCurrentDateRangeEnd,
  (startDate, endDate) => datesInRange(startDate, endDate)
);
export const selectJobIdsForUserScheduled = createCachedSelector(
  selectDecoratedShifts,
  selectDatesInCurrentRange,
  selectJobIdsForUser,
  (shifts, currentDateRange, jobIds) =>
    uniq(
      shifts
        .filter(s => currentDateRange.includes(s.decoration.date))
        .map(s => s.relationships.owner.data.id)
        .filter(value => jobIds.includes(value))
    )
)((_, props) => `selectLocationIdsForUserScheduled-${props.jobId}`);

const selectShiftsByDateAndJob = createSelector(
  selectDecoratedShifts,
  selectJobIdUserIdMap,
  (shifts, jobIdUserIdMap) =>
    shifts
      .filter(shift =>
        Object.keys(jobIdUserIdMap).includes(
          shift?.relationships?.owner?.data?.id
        )
      )
      .reduce((memo, shift) => {
        const {
          decoration: { date },
          relationships: {
            owner: {
              data: { id, type },
            },
          },
        } = shift;

        if (type !== 'job') return memo;

        memo[date] ||= {};
        memo[date][id] = shift;
        return memo;
      }, {})
);

export const selectScheduledEmployeeIds = createSelector(
  selectShiftsByDateAndJob,
  selectDatesInCurrentRange,
  (shifts, datesInCurrentRange) => {
    const jobIds = [];

    datesInCurrentRange.forEach(date => {
      if (shifts[date]) {
        jobIds.push(Object.keys(shifts[date]));
      }
    });

    const jobIdsUnique = jobIds.flat();

    return jobIdsUnique.filter(
      (value, index) => jobIdsUnique.indexOf(value) === index
    );
  }
);

export const selectRoleIds = createSelector(
  (state, props) =>
    Role.selectAllEntities(state, props)
      .sort((roleA, roleB) =>
        roleA.attributes.name
          .toLowerCase()
          .localeCompare(roleB.attributes.name.toLowerCase())
      )
      .map(role => role.id)
      .join(','),
  ids => (ids.length ? ids.split(',') : [])
);

export const selectCustomJobPositionIds = createSelector(
  state => {
    const customJobPositionIds =
      selectCustomJobIds(state).length > 0
        ? selectCustomJobIds(state)
        : Schedule.selectRelationshipIds(state, 'customJobPositions');

    return customJobPositionIds.join(',');
  },
  ids => (ids.length ? ids.split(',') : [])
);

export const selectJobPositionIds = createSelector(
  state =>
    JobPosition.selectAllEntities(state)
      .filter(jobPosition =>
        selectJobIdsForCurrentLocation(state).includes(jobPosition.id)
      )
      .sort((a, b) =>
        sortByAttribute(a.attributes.position, b.attributes.position)
      )
      .map(jobPosition => jobPosition.id)
      .join(','),
  ids => (ids.length ? ids.split(',') : [])
);

export const selectJobPositionIdsBySortWeek = createSelector(
  (state, { sortBy }) => {
    const jobPositionIds =
      sortBy === SORT_BY_WEEK_OPTIONS.POSITION
        ? selectJobPositionIds(state)
        : selectCustomJobPositionIds(state);

    return jobPositionIds.join(',');
  },
  ids => (ids.length ? ids.split(',') : [])
);

const selectFilteredJobs = createSelector(
  state =>
    getCurrentLocationId(state) ||
    state.getIn(['session'])?.currentLocation?.id,
  (_, props) => props.sortBy,
  selectAllEmployeeIds,
  selectScheduledEmployeeIds,
  selectIsScheduleUsersOnly,
  selectUserIdUserNameMap,
  selectJobIdUserIdMap,
  selectSearchTerm,
  selectCustomJobIds,
  Job.selectAllEntities,
  (
    currentLocationId,
    sortBy,
    allEmployeeIds,
    scheduledEmployeeIds,
    isScheduleUsersOnly,
    userIdEmployeeNameMap,
    jobIdUserIdMap,
    searchTerm,
    customJobIds,
    jobs
  ) => {
    let jobIds = isScheduleUsersOnly ? scheduledEmployeeIds : allEmployeeIds;

    const jobsInCurrentLocation = jobs
      .filter(
        job =>
          job.relationships.location.data.id === String(currentLocationId) &&
          jobIds.includes(job.id)
      )
      .map(j => j.id);

    if (sortBy === SORT_BY_WEEK_OPTIONS.POSITION) {
      jobIds = customJobIds.filter(jobId =>
        jobsInCurrentLocation.includes(jobId)
      );
    } else {
      jobIds = jobsInCurrentLocation.sort((a, b) => {
        const userA = getUser(userIdEmployeeNameMap, jobIdUserIdMap, a);
        const userB = getUser(userIdEmployeeNameMap, jobIdUserIdMap, b);

        const firstNameA = userA.firstName?.toLowerCase() || '';
        const firstNameB = userB.firstName?.toLowerCase() || '';

        const lastNameA = userA.lastName?.toLowerCase() || '';
        const lastNameB = userB.lastName?.toLowerCase() || '';

        if (sortBy === SORT_BY_WEEK_OPTIONS.FIRST_NAME) {
          return sortByAttribute(firstNameA, firstNameB, lastNameA, lastNameB);
        }

        if (sortBy === SORT_BY_WEEK_OPTIONS.LAST_NAME) {
          return sortByAttribute(lastNameA, lastNameB, firstNameA, firstNameB);
        }
        return 0;
      });
    }

    if (!searchTerm) return jobIds;

    return jobIds.filter(employeeId => {
      const username =
        userIdEmployeeNameMap[jobIdUserIdMap[employeeId]]?.fullName;
      return new RegExp(searchTerm, 'gi').test(username);
    });
  }
);

export const selectFilteredJobIds = createSelector(
  (state, props) => selectFilteredJobs(state, props).join(','),
  ids => (ids.length ? ids.split(',') : [])
);

export const selectJobIdsSortedByStartTime = createSelector(
  selectJobIdUserIdMap,
  selectUserIdUserNameMap,
  state =>
    selectFilteredJobIds(state, {
      sortBy: VIEW_TYPES.startTime,
    }),
  selectScheduledEmployeeIds,
  selectIsScheduleUsersOnly,
  Shift.selectAllEntities,
  ScheduleAvailability.selectAllEntities,
  TimeOff.selectAllEntities,
  (state, props) => selectDatesInCurrentRange(state, props)[0],
  (
    jobIdUserIdMap,
    userIdUserNameMap,
    filterJobIds,
    scheduledEmployeeIds,
    isScheduleUsersOnly,
    shifts,
    scheduleAvailabilities,
    timeOffs,
    currentDate
  ) => {
    const jobIds = isScheduleUsersOnly ? scheduledEmployeeIds : filterJobIds;

    const sortedShiftJobIds = new Set(
      shifts
        .filter(
          shift =>
            getEntityCurrentDate(shift, currentDate) &&
            shift.relationships.owner.data.type === 'job' &&
            jobIds.includes(shift.relationships.owner.data.id)
        )
        .sort(shiftSortCompare)
        .map(shift => shift.relationships.owner.data.id)
    );

    const sortedTimeOffJobIds = timeOffs
      .filter(
        timeOff =>
          getEntityCurrentDate(timeOff, currentDate) &&
          jobIds.includes(timeOff.relationships.job.data.id)
      )
      .sort(timeOffSortCompare)
      .map(timeOff => timeOff.relationships.job.data.id);

    const sortedPreferredScheduleAvailabilitiesJobIds =
      getScheduleAvailabilitiesIds(
        scheduleAvailabilities,
        currentDate,
        'Preferred',
        jobIds
      );
    const sortedUnavailableScheduleAvailabilitiesJobIds =
      getScheduleAvailabilitiesIds(
        scheduleAvailabilities,
        currentDate,
        'Unavailable',
        jobIds
      );

    const itemIds = new Set([
      ...sortedShiftJobIds,
      ...sortedPreferredScheduleAvailabilitiesJobIds,
      ...sortedUnavailableScheduleAvailabilitiesJobIds,
      ...sortedTimeOffJobIds,
    ]);

    const nonItemIds = jobIds.filter(jobId => !itemIds.has(jobId));

    // eslint-disable-next-line array-callback-return
    const sortedNonItemIds = nonItemIds.sort((id1, id2) => {
      const userNameA = userIdUserNameMap[jobIdUserIdMap[id1]]?.fullName;
      const userNameB = userIdUserNameMap[jobIdUserIdMap[id2]]?.fullName;

      const nameA = userNameA.toLowerCase();
      const nameB = userNameB.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
    });

    const sortedIds = new Set([
      ...sortedShiftJobIds,
      ...sortedPreferredScheduleAvailabilitiesJobIds,
      ...sortedNonItemIds,
      ...sortedUnavailableScheduleAvailabilitiesJobIds,
      ...sortedTimeOffJobIds,
    ]);

    return isScheduleUsersOnly
      ? Array.from(sortedShiftJobIds)
      : Array.from(sortedIds);
  }
);

const selectJobsNotInCurrentLocation = createSelector(
  state =>
    String(
      getCurrentLocationId(state) ||
        state.getIn(['session'])?.currentLocation?.id
    ),
  Job.selectAllEntities,
  selectDecoratedShifts,
  selectCurrentDateRange,
  (currentLocationId, jobs, shifts, dateRange) =>
    jobs?.filter(job => {
      const shiftsInDateRange = shifts.filter(shift =>
        isDateInRange(
          dateFromTime(shift.attributes.startAt),
          dateRange.start,
          dateRange.end
        )
      );

      const jobIdsInShifts = shiftsInDateRange
        .filter(s => s.relationships.owner.data.type === 'job')
        .map(s => s.relationships.owner.data.id);

      return (
        job.id &&
        job.relationships?.location?.data?.id !== currentLocationId &&
        jobIdsInShifts.includes(job.id)
      );
    })
);

export const selectJobIdUserIdMapNotCurrentLocation = createSelector(
  selectJobsNotInCurrentLocation,
  jobs =>
    jobs?.reduce((memo, job) => {
      if (job.relationships?.user?.data?.id) {
        memo[job.id] = job.relationships.user.data.id;
      }
      return memo;
    }, {})
);

export const selectUserIdJobIdMap = createSelector(
  User.selectAllEntities,
  users =>
    users?.reduce((memo, user) => {
      if (user.relationships?.jobs?.data) {
        memo[user.id] = user.relationships?.jobs?.data.map(job => job.id);
      }
      return memo;
    }, {})
);

export const selectJobIdLocationIdMapNotCurrentLocation = createSelector(
  selectJobsNotInCurrentLocation,
  jobs =>
    jobs?.reduce((memo, job) => {
      if (job.relationships?.location?.data?.id) {
        memo[job.id] = job.relationships.location.data.id;
      }
      return memo;
    }, {})
);

export const selectUserIdsInOtherLocations = createSelector(
  selectJobIdUserIdMapNotCurrentLocation,
  selectJobIdUserIdMap,
  (jobIdUserIdMapNotInCurrentLocation, jobIdUserIdMap) => {
    const userIds = Object.values(jobIdUserIdMap);
    const userIdsInCurrentLocation = uniq(
      Object.values(jobIdUserIdMapNotInCurrentLocation).filter(value =>
        userIds.includes(value)
      )
    );
    return userIdsInCurrentLocation;
  }
);

export const selectOtherJobLocationIds = createSelector(
  selectUserIdsInOtherLocations,
  selectJobIdLocationIdMapNotCurrentLocation,
  selectUserIdJobIdMap,
  (userIdsInOtherLocations, jobIdLocationIdMap, userIdJobIdMap) => {
    const jobIds = userIdsInOtherLocations
      .map(userId => userIdJobIdMap[userId])
      .flat();

    const locationIds = uniq(
      jobIds
        .map(jobId => jobIdLocationIdMap[jobId])
        .filter(locationId => locationId)
    );
    return locationIds;
  }
);

const selectNumShiftsByDate = createSelector(
  selectShiftsByDateAndJob,
  shiftsByDateAndJob =>
    Object.entries(shiftsByDateAndJob).reduce((memo, [date, shiftsByJob]) => {
      memo[date] = Object.keys(shiftsByJob).length;
      return memo;
    }, {})
);

export const selectShiftIdsByDate = createCachedSelector(
  (state, { date }) =>
    Shift.selectAllEntities(state)
      .filter(
        shift =>
          dateFromTime(shift.attributes.startAt) === date &&
          containsShiftsInCurrentLocation(
            shift,
            selectJobIdsForCurrentLocation(state)
          )
      )
      .sort(shiftSortCompare)
      .map(shift => shift.id)
      .join(','),
  ids => (ids.length ? ids.split(',') : [])
)((_, props) => `selectShiftIdsByDate-${props.date}`);

export const selectNumEmployeesScheduledOnDate = createCachedSelector(
  selectNumShiftsByDate,
  (state, props) => props.date,
  (numShiftsByDate, date) => numShiftsByDate[date] || 0
)((_, props) => `selectNumEmployeesScheduledOnDate-${props.date}`);

const selectDeletedShiftsCount = state =>
  Schedule.selectAttribute(state, 'deletedShiftsCount') || 0;

const selectUnpublishedCount = createSelector(
  state =>
    getCurrentLocationId(state) ||
    state.getIn(['session'])?.currentLocation?.id,
  selectShiftIdJobIdMap,
  selectJobIdLocationIdMap,
  selectDecoratedShifts,
  selectCurrentDateRange,
  (currentLocationId, shiftIdJobIdMap, jobIdLocationIdMap, shifts, dateRange) =>
    shifts.filter(shift => {
      const ownerId = shiftIdJobIdMap[shift.id];

      const openShiftCurrentLocationId =
        shift.relationships.owner.data.type === 'location' && ownerId;

      const isOpenShiftInCurrentLocation =
        openShiftCurrentLocationId === String(currentLocationId);

      const shiftLocationId = jobIdLocationIdMap[ownerId];

      return (
        isDateInRange(shift.decoration.date, dateRange.start, dateRange.end) &&
        !shift.attributes.isPublished &&
        (shiftLocationId === String(currentLocationId) ||
          isOpenShiftInCurrentLocation)
      );
    })?.length || 0
);

export const selectUnpublishedShiftsCount = createSelector(
  selectUnpublishedCount,
  selectDeletedShiftsCount,
  (unpublishedCount, deletedCount) => unpublishedCount + deletedCount
);

export const selectHasUnpublishedShifts = createSelector(
  selectDeletedShiftsCount,
  selectUnpublishedCount,
  (deletedCount, unpublishedCount) => !!(deletedCount + unpublishedCount)
);

export const selectHasPublishedShifts = createSelector(
  selectDeletedShiftsCount,
  state => Shift.selectWhereAttribute(state, 'isPublished', true)?.length || 0,
  (deletedCount, publishedCount) => !!(deletedCount + publishedCount)
);

export const selectScheduleHasShiftsInCurrentDateRange = createSelector(
  selectHasPublishedShifts,
  selectHasUnpublishedShifts,
  (hasPublishedShifts, hasUnpublishedShifts) =>
    hasPublishedShifts || hasUnpublishedShifts
);

export const selectShouldAllowResendNotifications = createSelector(
  selectHasPublishedShifts,
  selectHasUnpublishedShifts,
  (hasPublishedShifts, hasUnpublishedShifts) =>
    hasPublishedShifts && !hasUnpublishedShifts
);

export const selectShiftsWithConflicts = createSelector(
  state =>
    String(
      getCurrentLocationId(state) ||
        state.getIn(['session'])?.currentLocation?.id
    ),
  selectCurrentDateRange,
  selectShiftIdJobIdMap,
  selectJobIdLocationIdMap,
  state =>
    uniq(
      ShiftConflict.selectAllEntities(state).reduce((memo, shiftConflict) => {
        if (shiftConflict.relationships.shift?.data)
          memo.push(shiftConflict.relationships.shift.data.id);

        return memo;
      }, [])
    ).map(id => Shift.selectEntity(state, id)),
  (currentLocationId, dateRange, shiftIdJobIdMap, jobIdLocationIdMap, shifts) =>
    shifts.filter(shift => {
      const ownerId = shiftIdJobIdMap[shift.id];

      const shiftLocationId = jobIdLocationIdMap[ownerId];

      return (
        isShiftWithinRange(shift, dateRange.start, dateRange.end) &&
        shiftLocationId === String(currentLocationId)
      );
    })
);

export const selectHasConflicts = createSelector(
  selectShiftsWithConflicts,
  shiftsWithConflicts => !!shiftsWithConflicts.length
);

export const selectEmployeesInRoleDepartment = createSelector(
  (state, { roleId }) =>
    (!roleId || roleId === 'uncategorized'
      ? Job.selectWhereAttribute(state, 'defaultRoleId', null)
      : Department.selectAllEntities(state)
          .find(dept =>
            /** Find department with the roleId */
            dept.relationships.roles.data.some(role => role.id === roleId)
          ) /**  Get all the roles in the department found above */
          ?.relationships.roles.data.map(role => role.id)
          .reduce(
            /**  Get all jobs with the roles found above */
            (memo, id) => [
              ...memo,
              ...Job.selectWhereAttribute(state, 'defaultRoleId', Number(id)),
            ],
            []
          )
    ).map(j => j.id),
  employeesInRoleDepartment => employeesInRoleDepartment
);

export const selectEmployeesNotInRoleDepartment = createSelector(
  selectEmployeesInRoleDepartment,
  state => Job.selectAllEntities(state).map(j => j.id),
  (employeesInRoleDepartment, allJobs) =>
    allJobs.filter(jobId => !employeesInRoleDepartment.includes(jobId))
);

export const sortEmployeesInRoleDepartment = createSelector(
  selectEmployeesInRoleDepartment,
  selectJobIdUserIdMap,
  selectUserIdUserNameMap,
  (employeesInRoleDepartment, jobIdUserIdMap, userIdUserNameMap) =>
    employeesInRoleDepartment.sort(
      sortEmployeeInDepartmentByName(jobIdUserIdMap, userIdUserNameMap)
    )
);

export const sortEmployeesNotInRoleDepartment = createSelector(
  selectEmployeesNotInRoleDepartment,
  selectJobIdUserIdMap,
  selectUserIdUserNameMap,
  (employeesNotInRoleDepartment, jobIdUserIdMap, userIdUserNameMap) =>
    employeesNotInRoleDepartment.sort(
      sortEmployeeInDepartmentByName(jobIdUserIdMap, userIdUserNameMap)
    )
);

export const selectRoleEmployeeOptions = createSelector(
  sortEmployeesInRoleDepartment,
  sortEmployeesNotInRoleDepartment,
  selectJobIdUserIdMap,
  selectUserIdUserNameMap,
  (
    employeesInRoleDepartment,
    employeesNotInRoleDepartment,
    jobIdUserIdMap,
    userIdUserNameMap
  ) =>
    [...employeesInRoleDepartment, ...employeesNotInRoleDepartment].reduce(
      (memo, jobId, index) => {
        if (
          (!index && employeesInRoleDepartment.length) ||
          index === employeesInRoleDepartment.length
        )
          memo.push({
            label: !index
              ? toI18n(`${SHIFT_FORM_I18N_PATH}.employees_in_department`)
              : toI18n(`${SHIFT_FORM_I18N_PATH}.all_other_employees`),
            disabled: true,
          });

        const userId = jobIdUserIdMap[jobId];

        if (userIdUserNameMap[userId])
          memo.push({
            label: userIdUserNameMap[userId]?.fullName,
            value: jobId,
          });

        return memo;
      },
      []
    )
);

export const selectEmployeeOptions = createSelector(
  selectJobIdUserIdMap,
  selectJobIdArchivedAtMap,
  (_, props) => props.currentShiftDate,
  selectUserIdUserNameMap,
  (jobIdUserIdMap, jobIdArchivedAtMap, shiftDate, userIdUserNameMap) => {
    Object.entries(jobIdArchivedAtMap).forEach(([jobId, archiveDate]) => {
      const jobArchivedBeforeShiftDate =
        compareDateTime(archiveDate, shiftDate) <= 0;

      if (shiftDate && jobArchivedBeforeShiftDate) {
        delete jobIdUserIdMap[jobId];
      }
    });

    return Object.entries(jobIdUserIdMap)
      .map(([jobId, userId]) => ({
        label: userIdUserNameMap[userId]?.fullName,
        value: jobId,
      }))
      .sort((a, b) => a?.label.localeCompare(b?.label));
  }
);

export const selectShouldShowConsecutiveDayWarning = createCachedSelector(
  selectShiftsByJobAndDate,
  selectDatesInCurrentRange,
  (_, props) => props.jobId,
  (shiftsByJobAndDate, dateRange, jobId) => {
    const shifts = [];
    const jobShiftsByDate = shiftsByJobAndDate[jobId] || {};

    if (!jobShiftsByDate) return false;

    dateRange.forEach(date => {
      if (jobShiftsByDate[date]) {
        shifts.push(Object.keys(jobShiftsByDate[date]));
      }
    });

    const numJobShifts = Object.keys(shifts).length;

    if (!jobShiftsByDate || numJobShifts <= CONSECUTIVE_SHIFTS_MAX)
      return false;

    return (
      numJobShifts === 7 ||
      (numJobShifts > CONSECUTIVE_SHIFTS_MAX &&
        (!jobShiftsByDate[dateRange[0]] || !jobShiftsByDate[dateRange[6]]))
    );
  }
)((_, props) => `selectShouldShowConsecutiveDayWarning-${props.jobId}`);

export const selectUncategorizedShifts = createSelector(
  Shift.selectAllEntities,
  shifts => shifts.filter(shift => !shift.relationships.role?.data)
);

export const selectShiftIdsByRoleAndDate = createCachedSelector(
  (state, { roleId, date }) => {
    const shifts =
      roleId === 'uncategorized'
        ? selectUncategorizedShifts(state)
        : Role.selectRelatedEntities(state, 'shifts', Shift, roleId);

    return shifts
      .sort(shiftSortCompare)
      .filter(
        shift =>
          dateFromTime(shift.attributes.startAt) === date &&
          containsShiftsInCurrentLocation(
            shift,
            selectJobIdsForCurrentLocation(state)
          )
      )
      .map(shift => shift.id)
      .join(',');
  },
  ids => (ids.length ? ids.split(',') : [])
)((_, { roleId, date }) => `selectShiftIdsByRoleAndDate-${roleId}-${date}`);

export const selectOpenTime = state =>
  Location.selectAttribute(
    state,
    'openTime',
    String(
      getCurrentLocationId(state) ||
        state.getIn(['session'])?.currentLocation?.id
    )
  );

export const selectClosingTime = state =>
  Location.selectAttribute(state, 'closingTime', getCurrentLocationId(state));

export const selectEnabledWeeklyOvertime = state =>
  Location.selectAttribute(
    state,
    'enabledWeeklyOvertime',
    String(
      getCurrentLocationId(state) ||
        state.getIn(['session'])?.currentLocation?.id
    )
  );

export const selectWeeklyOvertime = state =>
  Location.selectAttribute(
    state,
    'weeklyOvertime',
    String(
      getCurrentLocationId(state) ||
        state.getIn(['session'])?.currentLocation?.id
    )
  );

export const selectEnabledDailyOvertime = state =>
  Location.selectAttribute(
    state,
    'enabledDailyOvertime',
    String(
      getCurrentLocationId(state) ||
        state.getIn(['session'])?.currentLocation?.id
    )
  );

export const selectDailyOvertime = state =>
  Location.selectAttribute(
    state,
    'dailyOvertime',
    String(
      getCurrentLocationId(state) ||
        state.getIn(['session'])?.currentLocation?.id
    )
  );

export const selectSendToPartnerSyncErrors = createSelector(
  selectSlice,
  slice => slice.syncErrors
);

export const selectSendToPartnerDrawerShown = createSelector(
  selectSlice,
  slice => slice.activeDrawer === DRAWERS.send_to_partner_drawer
);

export const selectSyncShiftStatuses = createSelector(
  selectSlice,
  slice => slice.syncShiftStatus
);

/* convert these TS types when this file is refractored to TS */
export const selectSyncShiftError = createSelector(
  [selectSlice, (_, shiftId) => shiftId],
  (slice, shiftId) => {
    if (!shiftId) return undefined;

    const errorKey = slice.syncShiftStatus?.find(
      shift => shift.id.toString() === shiftId.toString()
    )?.sync_error;

    if (!errorKey) return undefined;

    const validErrors = [
      'missed_job_id',
      'missed_employee_role',
      'wrong_job_partner_id',
      'wrong_role_partner_id',
      'duplicate_shift_id',
      'other',
    ];

    return validErrors.includes(errorKey) ? errorKey : 'other';
  }
);

export const selectPartnerSyncState = createSelector(
  selectSlice,
  slice => slice.partnerSyncState
);

export const selectActiveModal = createSelector(
  selectSlice,
  slice => slice.activeModal
);

export const selectIsPredictiveSchedulingModalActive = createSelector(
  selectSlice,
  slice => slice.activeModal === MODALS.predictive_scheduling_generation_modal
);

export const selectSchedulePredictionGeneration = createSelector(
  selectSlice,
  slice => slice.schedulePredictionGeneration
);

export const selectPredictedShiftsCount = createSelector(
  selectSlice,
  slice => slice.predictedShiftsCount
);
