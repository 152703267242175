import React, { useCallback, useEffect, useState } from 'react';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripeElements } from '@stripe/stripe-js';
import { postJSON } from 'api/fetch';
import Box from 'fe-design-base/atoms/Box';
import RadioButton from 'fe-design-base/atoms/RadioButton';
import Text from 'fe-design-base/atoms/Text';
import Alert from 'fe-design-base/molecules/Alert';
import { List } from 'immutable';
import { isNull } from 'lodash';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

import { PaymentMethodModuleCreditCardPicker } from '../PaymentMethodModule/PaymentMethodModuleCreditCardPicker';
import {
  confirmPayment,
  submitElements,
  updateElements,
} from '../stripeClient';

import { SUCCESS_QUERY_PARAM } from './constants';
import {
  subscribeToOnConfirmAndPay,
  unsubscribeFromOnConfirmAndPay,
} from './events';
import PriceSummary from './PriceSummary';
const { cx } = cxHelpers('PurchaseMethodModuleView');

declare const window: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stripeClient: any;
} & Window;

const PaymentMethodOption = {
  newCard: 'newCard',
  existingCard: 'existingCard',
};

interface ConfirmPaymentParams {
  clientSecret: string;
  redirect: 'if_required' | 'always';
  elements?: StripeElements;
  confirmParams?: Record<string, unknown>;
}
export interface Price {
  amountInCents: number;
  priceSystemId: string;
}
export interface PurchaseMethodModuleViewProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  creditCards: List<any>;
  onSuccess: () => void;
  onLoading: (isLoading: boolean) => void;
  price: Price;
  salesTaxInCents: number;
  subscriptionType: string;
}

export const PurchaseMethodModuleView = ({
  creditCards,
  onSuccess,
  onLoading,
  price,
  salesTaxInCents,
  subscriptionType,
}: PurchaseMethodModuleViewProps) => {
  const hasCreditCards = creditCards.size > 0;
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(
    hasCreditCards ? creditCards.first().get('payment_method_id') : ''
  );
  const trackUx = useTrackUx();
  const [currentPaymentMethodOption, setCurrentPaymentMethodOption] = useState(
    hasCreditCards
      ? PaymentMethodOption.existingCard
      : PaymentMethodOption.newCard
  );

  // TODO: https://joinhomebase.atlassian.net/browse/FE-2199
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleOnChangePaymentMethodOption = useCallback(e => {
    const selectedPaymentMethod = e.target.name;
    setCurrentPaymentMethodOption(selectedPaymentMethod);
    trackUx(EVENT_ACTIONS.RADIO_BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      button_text: 'Payment method',
      current_selection: selectedPaymentMethod,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    window.stripeClient.instance = stripe;
    window.stripeClient.stripeElements = elements;
  }, [stripe, elements]);

  const [errorMessage, setErrorMessage] = useState<string>();
  const createProductSubscription = useCallback(async () => {
    // temporary tracking to debug success event issue
    // DO NOT USE ELSEWHERE!!!
    trackUx(EVENT_ACTIONS.DEBUG_CHECKOUT_STARTED, TRACK_ACTION_TYPES.VIEW);

    if (!stripe || !elements) {
      return;
    }

    onLoading(true);

    const { error: submitError } = await submitElements();

    if (submitError) {
      onLoading(false);
      setErrorMessage(submitError.message);
      trackUx(EVENT_ACTIONS.ERROR_MESSAGE_SHOWN, TRACK_ACTION_TYPES.VIEW, {
        error_msg: submitError.message,
        error_type: 'Invalid card details',
      });
      return;
    }

    try {
      const subscribeResults = await postJSON(
        '/biller/product_subscriptions/subscribe',
        {
          payment_method_id:
            currentPaymentMethodOption === PaymentMethodOption.existingCard
              ? selectedPaymentMethodId
              : null,
          price_system_id: price.priceSystemId,
          subscription_type: subscriptionType,
        }
      );

      // Skip payment confirmation if client_secret is null (e.g. using credit balance)
      if (
        subscribeResults.client_secret &&
        subscribeResults.payment_status === 'payment_confirmation_required'
      ) {
        let params: ConfirmPaymentParams = {
          clientSecret: subscribeResults.client_secret,
          confirmParams: {
            return_url:
              window.location.href +
              `?${SUCCESS_QUERY_PARAM}=true&priceSystemId=${price.priceSystemId}` +
              `&subscriptionId=${subscribeResults.subscription_id}`,
          },
          redirect: 'if_required',
        };

        if (currentPaymentMethodOption === PaymentMethodOption.newCard) {
          params = { ...params, elements };
        }

        const { error } = await confirmPayment(params);

        if (error) {
          onLoading(false);
          setErrorMessage(error.message);
          trackUx(EVENT_ACTIONS.ERROR_MESSAGE_SHOWN, TRACK_ACTION_TYPES.VIEW, {
            error_msg: error.message,
            error_type: 'Unable to confirm payment',
          });
          return;
        }
      }

      // Always confirm the subscription regardless of payment confirmation
      await postJSON('/biller/product_subscriptions/confirm', {
        price_system_id: price.priceSystemId,
        subscription_id: subscribeResults.subscription_id,
        subscription_type: subscriptionType,
      });

      trackUx(EVENT_ACTIONS.DEBUG_CHECKOUT_COMPLETED, TRACK_ACTION_TYPES.VIEW);
      onSuccess();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const error_description = (await e?.response?.json())?.errors || '';
      const message = toI18n('biller.purchase_method_module.error');
      setErrorMessage(message);
      trackUx(EVENT_ACTIONS.ERROR_MESSAGE_SHOWN, TRACK_ACTION_TYPES.VIEW, {
        error_msg: message,
        error_type: 'Support message shown',
        error_description,
      });
    } finally {
      onLoading(false);
    }
  }, [
    currentPaymentMethodOption,
    elements,
    onLoading,
    onSuccess,
    price.priceSystemId,
    selectedPaymentMethodId,
    stripe,
    subscriptionType,
    trackUx,
  ]);

  useEffect(() => {
    subscribeToOnConfirmAndPay(createProductSubscription);

    if (elements && !isNull(price.amountInCents) && !isNull(salesTaxInCents)) {
      updateElements({ amount: price.amountInCents + salesTaxInCents });
    }

    return () => {
      unsubscribeFromOnConfirmAndPay(createProductSubscription);
    };
  }, [
    stripe,
    elements,
    createProductSubscription,
    price.amountInCents,
    salesTaxInCents,
  ]);

  return (
    <Box className={cx()}>
      <Box mt={32} gap={24} column>
        {errorMessage && <Alert variant="error">{errorMessage}</Alert>}
        <Text
          variant="heading3"
          i18n="biller.purchase_method_module.payment_method"
        />
      </Box>
      {hasCreditCards && (
        <Box mt={24}>
          <Box row vtop maxw={340} gap={8}>
            <RadioButton
              uxElement="biller.payment_method_module.existing_card_radio_button"
              name={PaymentMethodOption.existingCard}
              onChange={handleOnChangePaymentMethodOption}
              checked={
                currentPaymentMethodOption === PaymentMethodOption.existingCard
              }
            />
            <Box pt={4} gap={18} column grow={1}>
              <Text
                variant="body"
                color="mono900"
                i18n="biller.change_payment_method.label_select_card"
              />
              {currentPaymentMethodOption ===
                PaymentMethodOption.existingCard && (
                <PaymentMethodModuleCreditCardPicker
                  creditCards={creditCards}
                  value={selectedPaymentMethodId}
                  onChange={setSelectedPaymentMethodId}
                />
              )}
            </Box>
          </Box>

          <Box row vcenter mt={18} gap={8}>
            <RadioButton
              uxElement="biller.payment_method_module.enter_new_credit_card_radio_button"
              name={PaymentMethodOption.newCard}
              onChange={handleOnChangePaymentMethodOption}
              checked={
                currentPaymentMethodOption === PaymentMethodOption.newCard
              }
            />
            <Text
              variant="body"
              color="mono900"
              i18n="biller.change_payment_method.link_enter_new"
            />
          </Box>
        </Box>
      )}
      {(!hasCreditCards ||
        currentPaymentMethodOption === PaymentMethodOption.newCard) && (
        <Box mt={24} className={cx()}>
          <PaymentElement />
        </Box>
      )}
      <PriceSummary
        salesTaxInCents={salesTaxInCents}
        amountInCents={price.amountInCents}
      />
    </Box>
  );
};
