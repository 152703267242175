import React, { useCallback, useEffect, useMemo } from 'react';
import MUIAlert from '@mui/material/Alert';
import withCx, { CxProps } from 'fe-core/util/withCx';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import IconButton from 'fe-design-base/molecules/IconButton';
import { iconNamesMap } from 'fe-design-base/molecules/utils';

import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

import AlertActions from './AlertActions';
import { alertStyles } from './styles';
import { AlertVariant } from './types';

export interface AlertProps {
  /** Content of the alert */
  children: string | React.ReactNode;
  title?: string;
  /** Variant of the alert
   * @default 'info'
   * @enum { 'info' | 'success' | 'warning' | 'error' }
   */
  variant?: AlertVariant;
  /** Component to be used for action */
  action?: React.ReactNode;
  secondaryAction?: React.ReactNode;
  onClose?: () => void;
  /** Boolean to render actions below content */
  forceStacked?: boolean;
  /** If uxElement prop provided, uxTracking will automatically trigger */
  uxElement?: string;
  /** True if rendered in a Toast component */
  isToast?: boolean;
  dataTestId?: string;
  /** True if we want to render AI Border */
  isAI?: boolean;
}

export const Alert = ({
  children,
  title,
  variant = 'info',
  action,
  secondaryAction,
  onClose,
  forceStacked,
  uxElement,
  isToast = false,
  dataTestId,
  isAI,
  cx,
}: AlertProps & CxProps) => {
  const trackUx = useTrackUx(
    useMemo(
      () => ({
        isToast,
        title,
        element: uxElement,
        content: typeof children === 'string' ? children : null,
      }),
      [title, isToast, children, uxElement]
    ) as any
  );

  const handleClose = useCallback(() => {
    if (uxElement) {
      trackUx(EVENT_ACTIONS.DISMISS_CLICKED, TRACK_ACTION_TYPES.CLICK);
    }
    onClose?.();
  }, [onClose, trackUx, uxElement]);

  useEffect(() => {
    if (uxElement) {
      trackUx(EVENT_ACTIONS.ALERT_VIEWED, TRACK_ACTION_TYPES.VIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styles = useMemo(() => alertStyles({ variant }), [variant]);

  return (
    <MUIAlert
      className={cx({ isToast, isAI })}
      sx={styles}
      icon={false}
      data-testid={dataTestId}
    >
      <Box flex>
        <Box row vcenter spacebetween column={forceStacked} w="100%">
          <Box row>
            <Icon
              size={isToast ? 'small' : 'medium'}
              color={isAI ? 'purple900' : `${variant}300`}
              iconName={isAI ? 'AIBolt' : iconNamesMap[variant]}
              mr={isToast ? 12 : 16}
              mt={2}
              shrink={0}
            />
            <Box vcenter column color="mono900">
              {title && (
                <Box mb={2}>
                  <Text variant="bodyBold" color="mono900">
                    {title}
                  </Text>
                </Box>
              )}

              {typeof children === 'string' ? (
                <Text color="mono900" variant={isToast ? 'bodySm' : 'body'}>
                  {children}
                </Text>
              ) : (
                children
              )}

              {forceStacked && (
                <AlertActions
                  action={action}
                  secondaryAction={secondaryAction}
                  forceStacked
                />
              )}
            </Box>
          </Box>

          {!forceStacked && (
            <AlertActions action={action} secondaryAction={secondaryAction} />
          )}
        </Box>
        {onClose && (
          <Box ml={16} alignItemsStart={!isToast} vcenter={isToast}>
            <IconButton size="small" icon="Close" onClick={handleClose} />
          </Box>
        )}
      </Box>
    </MUIAlert>
  );
};

export default withCx<AlertProps>('FDBAlert')(Alert);
