import { useCallback } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { omit } from 'lodash';

import * as sessionSelectors from 'selectors/session';

import { showUpgradeModal } from 'util/upgrades';

interface Props {
  feature: string;
  report?: string;
  paywallVersion?: string;
  locationProperty?: string;
  serverKey?: string;
  onShowUpgradeModal?: (
    target: any,
    options: { focusedFeature: string }
  ) => void;
  featureMinTiers: Map<string, any>;
  reportMinTiers: Map<string, any>;
}

const withUpgradeModal = (WrappedComponent: React.ComponentType<any>) => {
  const ComponentWithUpgradeModal: React.FC<Props> = props => {
    const {
      feature,
      report,
      paywallVersion,
      locationProperty,
      serverKey,
      featureMinTiers,
      reportMinTiers,
    } = props;

    const minTier = useCallback(() => {
      if (report) {
        return reportMinTiers.get(report);
      }
      return featureMinTiers.get(feature);
    }, [feature, report, featureMinTiers, reportMinTiers]);

    const handleShowUpgradeModal = useCallback(
      (target: any, { focusedFeature }: any) =>
        showUpgradeModal(
          minTier(),
          paywallVersion || feature,
          locationProperty,
          serverKey,
          target,
          undefined,
          { focusedFeature }
        ),
      [minTier, paywallVersion, feature, locationProperty, serverKey]
    );

    const componentProps = {
      onShowUpgradeModal: handleShowUpgradeModal,
      ...omit(props, ['featureMinTiers', 'reportMinTiers']),
    };

    return <WrappedComponent {...componentProps} />;
  };

  return connect((state: any) => ({
    featureMinTiers: sessionSelectors.getFeatureMinTiers(state),
    reportMinTiers: sessionSelectors.getReportMinTiers(state),
  }))(ComponentWithUpgradeModal);
};

export default withUpgradeModal;
