export const printContent = (htmlContent: HTMLElement | null) => {
  if (!htmlContent || !htmlContent.innerHTML) return;

  const WinPrint: WindowProxy | null = window.open(
    '',
    '',
    'letf=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
  );

  if (WinPrint) {
    const doc = WinPrint.document;
    doc.open();
    doc.appendChild(htmlContent);
    doc.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
  }
};
