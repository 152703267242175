import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'initializers/types';

import { JobRequest, JobRequestsState } from './types';

const SLICE_NAME = 'jobRequests';

const initialState: JobRequestsState = {
  jobRequests: [],
  loading: true,
  error: null,
  meta: {
    total_count: 0,
  },
};

const jobRequestsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setJobRequests(state, action: PayloadAction<JobRequest[]>) {
      state.jobRequests = action.payload;
    },
    setJobRequestsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setJobRequestsError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    setJobRequestsTotalCount(state, action: PayloadAction<number>) {
      state.meta.total_count = action.payload;
    },
  },
});

// Selectors
const getJobRequestsState = (state: RootState) =>
  state.get('hiringV2').jobRequests;

export const selectJobRequests = (state: RootState): JobRequest[] =>
  getJobRequestsState(state).jobRequests;

export const selectJobRequestsLoading = (state: RootState): boolean =>
  getJobRequestsState(state).loading;

export const selectJobRequestsError = (state: RootState): string | null =>
  getJobRequestsState(state).error;

export const selectJobRequestsTotalCount = (state: RootState): number =>
  getJobRequestsState(state).meta.total_count;

export const {
  setJobRequests,
  setJobRequestsLoading,
  setJobRequestsError,
  setJobRequestsTotalCount,
} = jobRequestsSlice.actions;

export const reducer = jobRequestsSlice.reducer;
