import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';
import { RootState } from 'initializers/types';

import { MY_AVAILABILITY_SLICE } from './constants';

interface Job {
  id: number;
  location_name: string;
  location_id: number;
}

interface User {
  id: number;
  employed: boolean;
}

interface Availability {
  title: string;
  weekday: number;
  all_day: boolean;
  owner_type: 'Job' | 'Account';
  owner_id: number;
  start_at: string | null;
  end_at: string | null;
  id?: number;
}

interface AvailabilityTemplate {
  id: number;
  employee_notes: string | null;
  manager_notes: string | null;
  user_id: number;
  status: 'approved' | 'rejected' | 'unsubmitted';
  availabilities: Availability[];
  start_at: string;
  current: boolean;
  responding_user_full_name: string | null;
  responding_at?: string;
}

export interface AvailabilityResponse {
  jobs: Job[];
  user: User;
  availability_templates: AvailabilityTemplate[];
  approval_required: boolean;
}

interface MyAvailabilityState {
  data: AvailabilityResponse | null;
  loading: boolean;
  error: string | null;
}

const initialState: MyAvailabilityState = {
  data: null,
  loading: true,
  error: null,
};

// THUNKS
export const fetchUserAvailabilities = createAsyncThunk(
  `${MY_AVAILABILITY_SLICE}/fetchUserAvailabilities`,
  async () => fetchJSON('/user/availability.json')
);

const myAvailabilitySlice = createSlice({
  name: MY_AVAILABILITY_SLICE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUserAvailabilities.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserAvailabilities.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchUserAvailabilities.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to fetch availability data';
      });
  },
});

// SELECTORS
export const selectUserAvailability = (state: RootState) =>
  state.get(MY_AVAILABILITY_SLICE).data;

export const selectUserAvailabilityLoading = (state: RootState) =>
  state.get(MY_AVAILABILITY_SLICE).loading;

export const { reducer } = myAvailabilitySlice;
