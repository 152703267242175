import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SortArrows = ({
  alt = 'Sort Arrows Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M22.922 17.672a1.125 1.125 0 0 0-1.59-1.59l-2.577 2.58V2.624c0-.623-.502-1.125-1.125-1.125s-1.125.502-1.125 1.125V18.66l-2.578-2.578a1.125 1.125 0 0 0-1.59 1.59l4.5 4.5c.442.44 1.154.44 1.59 0l4.5-4.5h-.005ZM6.422 1.83a1.12 1.12 0 0 0-1.589 0L.33 6.329a1.125 1.125 0 0 0 1.59 1.59l2.577-2.58v16.036c0 .624.501 1.125 1.125 1.125.623 0 1.125-.501 1.125-1.125V5.34l2.578 2.579a1.125 1.125 0 0 0 1.59-1.59l-4.492-4.5Z"
    />
  </svg>
);
export default SortArrows;
