import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';

import { getCurrentLocationId } from 'selectors/session';

import { getMarketingDiscountCoupon } from 'features/productGrowth/api';

import { ProductsApiType, SubscriptionsApiType } from './slice';

type SubscriptionsResponse = Promise<SubscriptionsApiType>;
type ProductsResponse = Promise<ProductsApiType>;

export const fetchProducts = createAsyncThunk('products/fetchProducts', () =>
  fetchJSON('/tier_products.json')
);

export const fetchFeatures = createAsyncThunk('products/fetchFeatures', () =>
  fetchJSON('/get_features.json')
);

export const fetchMarketingDiscountCoupon = createAsyncThunk(
  'products/fetchMarketingDiscountCoupon',
  getMarketingDiscountCoupon
);

// Tip Manager Actions
export const fetchTipManagerSubscriptions = createAsyncThunk(
  'tipManager/fetchSubscriptions',
  (): SubscriptionsResponse =>
    fetchJSON(
      '/biller/product_subscriptions/index?external_identifier=tip_manager'
    )
);

export const fetchProductTipManager = createAsyncThunk(
  'products/fetchTipManagerProduct',
  (_, { getState }): ProductsResponse =>
    fetchJSON(
      `/products/location/${getCurrentLocationId(
        getState()
      )}?external_identifier=tip_manager`
    )
);

// Task Manager Actions
export const fetchTaskManagerSubscriptions = createAsyncThunk(
  'taskManager/fetchSubscriptions',
  (): SubscriptionsResponse =>
    fetchJSON(
      '/biller/product_subscriptions/index?external_identifier=task_manager'
    )
);

export const fetchProductTaskManager = createAsyncThunk(
  'products/fetchTaskManagerProduct',
  (_, { getState }): ProductsResponse =>
    fetchJSON(
      `/products/location/${getCurrentLocationId(
        getState()
      )}?external_identifier=task_manager`
    )
);
