import { EVENT_CATEGORIES, PRODUCT_AREAS } from 'util/tracking_constants';

export const MODULE_NAME = 'payroll';

export const SUPPORT_PHONES = {
  payroll_active: '4159513835',
  payroll_implementation: '4702605326',
  payroll_sales: '6282043443',
  account_less_than_60_days: '4157636278',
  account_more_than_60_days: '4154939466',
};

export const PAYROLL_SALES_CONTACT = {
  phone: '6282043443',
  email: 'sales-support@joinhomebase.com',
  availabilityI18n: 'payroll.modals.contact.call_payroll_sales.available',
};

export const PAYROLL_SUPPORT_CONTACT = {
  phone: '4159513835',
  email: 'payroll@joinhomebase.com',
  availabilityI18n: 'payroll.modals.contact.call_payroll.available',
};

export const PROCESSING_PERIODS = {
  one_day: 1,
  two_day: 2,
  three_day: 3,
  four_day: 4,
};

export const FREQUENCIES = {
  WEEKLY: 'week',
  EVERY_OTHER_WEEK: 'twice_week',
  TWICE_A_MONTH: 'twice_month',
  ONCE_A_MONTH: 'month',
};

export const FREQUENCIES_LINEAR_FLOW_MAPPER = {
  week: 'weekly',
  twice_week: 'every_other_week',
  twice_month: 'twice_a_month',
  month: 'once_a_month',
};

export const FREQUENCIES_HUMANIZED = {
  week: 'Weekly',
  twice_week: 'Every Other Week',
  twice_month: 'Twice A Month',
  month: 'Once a Month',
};

export const WEEK_DAYS = 7;
export const TWICE_WEEK_DAYS = 14;
export const SUNDAY_DAY = 0;
export const MONDAY_DAY = 1;
export const SATURDAY_DAY = 6;

export const FULL_SCREEN_PATHS = ['/payroll/runs', '/payroll/off_cycle'];

export const PAYROLL_TRACKING = {
  // productArea
  'payroll.productArea': PRODUCT_AREAS.PAYROLL,
  'payroll.productArea.payroll_dashboard': PRODUCT_AREAS.PAYROLL_DASHBOARD,
  // Event categories
  'payroll.event_category.payroll_history':
    EVENT_CATEGORIES.DASHBOARD_PAYROLL_HISTORY,
  'payroll.event_category.edit_payroll_run_modal':
    EVENT_CATEGORIES.EDIT_PAYROLL_RUN_MODAL,
  'payroll.event_category.edit_autopayroll_run_modal':
    EVENT_CATEGORIES.EDIT_AUTOPAYROLL_RUN_MODAL,
  'payroll.event_category.dashboard': EVENT_CATEGORIES.DASHBOARD,
  'payroll.event_category.zero_state_page': EVENT_CATEGORIES.ZERO_STATE_PAGE,
  'payroll.event_category.dashboard_standard_payroll':
    EVENT_CATEGORIES.DASHBOARD_STANDARD_PAYROLL,
  'payroll.event_category.payroll_settings':
    EVENT_CATEGORIES.SETTINGS_PAYROLL_AD,
  'payroll.event_category.late_payroll_run': EVENT_CATEGORIES.LATE_PAYROLL_RUN,
  'payroll.dashboardSetupAd': EVENT_CATEGORIES.DASHBOARD_SETUP_PAYROLL_AD,
  'payroll.event_category.payroll_run_workflow.hours_and_time_off':
    EVENT_CATEGORIES.PAYROLL_RUN_WORKFLOW_HOURS_AND_TIME_OFF,
  'payroll.event_category.payroll_run_workflow.net_pay':
    EVENT_CATEGORIES.PAYROLL_RUN_WORKFLOW_NET_PAY,
  'payroll.event_category.payroll_run_workflow.review_and_submit':
    EVENT_CATEGORIES.PAYROLL_RUN_WORKFLOW_REVIEW_AND_SUBMIT,
  'payroll.implementation.provider_account_access_component':
    EVENT_CATEGORIES.PAYROLL_IMPLEMENTATION_PROVIDER_ACCOUNT_ACCESS_COMPONENT,
  'payroll.dashboard.structured_switcher_reminder_component':
    EVENT_CATEGORIES.PAYROLL_DASHBOARD_STRUCTURED_SWITCHER_REMINDER_COMPONENT,
  'payroll.event_category.reports.employee_and_contractor_overview':
    EVENT_CATEGORIES.PAYROLL_REPORTS,
  'payroll.event_category.payroll_run_widget':
    EVENT_CATEGORIES.PAYROLL_RUN_WIDGET,
  'payroll.event_category.add_single_payroll_location_flow':
    EVENT_CATEGORIES.ADD_SINGLE_PAYROLL_LOCATION_FLOW,
  'payroll.event_category.missed_pr_team_paid_form':
    EVENT_CATEGORIES.MISSED_PR_TEAM_PAID_FORM,
  'payroll.event_category.missed_payroll_payday':
    EVENT_CATEGORIES.MISSED_PAYROLL_PAYDAY,
  'payroll.event_category.missed_payroll_deadline':
    EVENT_CATEGORIES.MISSED_PAYROLL_DEADLINE,
  'payroll.event_category.nsf_prediction_resolution':
    EVENT_CATEGORIES.PAYROLL_NSF_PREDICTION_RESOLUTION,
  'payroll.event_category.dashboard_autopayroll':
    EVENT_CATEGORIES.DASHBOARD_AUTOPAYROLL,
  'payroll.event_category.payroll_run_workflow_submission_confirmation':
    EVENT_CATEGORIES.PAYROLL_RUN_WORKFLOW_SUBMISSION_MODAL_AUTOPAYROLL,
  'payroll.event_category.pay_any_day_promo.banner': 'pay_any_day_promo_banner',
  'payroll.event_category.pay_any_day_promo.invite_team_modal':
    'pay_any_day_promo_invite_team_modal',
  'payroll.event_category.pay_any_day_promo.success_modal':
    'pay_any_day_promo_success_modal',
  'payroll.event_category.pay_any_day_promo.feedback_modal':
    'pay_any_day_promo_feedback_modal',
  // Lead page actions
  'payroll.dashboard.zeroStateShown': 'Zero State Shown',
  'payroll.dashboard.waitlistShown': 'Waitlist Page Shown',
  'payroll.dashboard.scheduleCall': 'Schedule a Call Clicked',
  'payroll.dashboard.bookDemo': 'Book a Demo Clicked',
  'payroll.dashboard.getStarted': 'Get Started Clicked',
  'payroll.dashboard.beginSetup': 'Begin Setup Clicked',
  'payroll.dashboard.continuePayrollSetup': 'Continue Payroll Setup Clicked',
  'payroll.dashboard.videoPlay': 'Video Clicked Play',
  'payroll.dashboard.videoPause': 'Video Clicked Pause',
  'payroll.dashboard.videoEnded': 'Video Ended',
  'payroll.dashboard.joinWaitlist': 'Join Waitlist Clicked',
  'payroll.dashboard.learnMore': 'Learn More Clicked',
  'payroll.dashboard.seeAllFeatures': 'See All Features Clicked',
  'payroll.dashboard.viewPricing': 'View Pricing Clicked',
  'payroll.dashboard.transferProcess': 'See Transfer Process Clicked',
  'payroll.dashboard.integrations': 'Integration Clicked',
  'payroll.dashboard.structured_switcher_reminder.see_instructions':
    'Structured Switcher Reminder - See Instructions Clicked',
  'payroll.dashboard.structured_switcher_reminder.mark_task_as_completed':
    'Mark task as completed Clicked',
  // Payroll Add Single Location Actions
  'payroll.add_single_location.qualifying_questions':
    'Qualifying Questions Shown',
  'payroll.add_single_location.disqualified_step': 'Disqualified Step Shown',
  'payroll.add_single_location.address_form': 'Address Form Shown',
  'payroll.add_single_location.address_submit_clicked_successful':
    'Address Submit Clicked Successful',
  'payroll.add_single_location.address_submit_clicked_unsuccessful':
    'Address Submit Clicked Unsuccessful',
  'payroll.add_single_location.location_successfully_added':
    'Add Single Payroll Location Success',
  // Payroll Summary Modal
  'payroll.dashboard.payroll_summary_modal.shown': 'History Modal Shown',
  'payroll.dashboard.payroll_summary_modal.pay_stub_clicked':
    'Pay Stub Clicked',
  // Payroll History widget
  'payroll.dashboard.payroll_history.resume_payroll_run_clicked':
    'Resume Payroll Run Clicked',
  'payroll.dashboard.payroll_history.delete_draft_clicked':
    'Delete Draft Clicked',
  'payroll.dashboard.payroll_history.payroll_run_clicked':
    'Payroll Run Clicked',
  // Edit Payroll Run Modal
  'payroll.edit_payroll_run_modal.reopen_payroll_clicked':
    'Re-open Payroll Clicked',
  // Edit Autopayroll Run Modal
  'payroll.edit_payroll_run_modal.reopen_autopayroll_clicked':
    'Re-open AutoPayroll Clicked',
  // Dashboard
  'payroll.dashboard.settings_clicked': 'Settings Clicked',
  'payroll.dashboard.manage_workers_comp_clicked': 'Link Clicked',
  'payroll.dashboard.eoy_checklist_clicked': 'EOY Checklist Clicked',
  'payroll.dashboard.run_off_cycle_clicked': 'Run Off-Cycle Payroll Clicked',
  'payroll.dashboard.access_payroll_reports_clicked':
    'Access Payroll Reports Clicked',
  'payroll.dashboard.tax_records_clicked': 'Tax Records Clicked',
  'payroll.dashboard.manage_time_offs_clicked': 'manage_time_offs_clicked',
  'payroll.dashboard.enable_mfa_clicked': 'enable_MFA_clicked',
  // Dashboard - Standard Payroll
  'payroll.dashboard.standard_payroll.view_timesheets_clicked':
    'View Timesheets Clicked',
  'payroll.dashboard.standard_payroll.run_regular_payroll_clicked':
    'Run Regular Payroll Clicked',
  'payroll.dashboard.standard_payroll.resume_payroll_clicked':
    'Resume Payroll Clicked',
  'payroll.dashboard.standard_payroll.edit_payroll_run_clicked':
    'Edit Payroll Run Clicked',
  'payroll.dashboard.standard_payroll.run_late_payroll_clicked':
    'Run Late Payroll Clicked',
  // Payroll Settings
  'payroll.settings.explore_clicked': 'Explore HB Payroll Clicked',
  // Late Payroll Run
  'payroll.late_payroll_run.current_payday_clicked': 'Current Payday Clicked',
  'payroll.late_payroll_run.next_available_payday_clicked':
    'Next Available Payday Clicked',
  // Location Dashboard
  'payroll.dashboard_setup_ad.start_button.clicked': 'Start Button Clicked',
  'payroll.implementation.provider_account_access.see_instructions_clicked':
    'See Instructions Clicked',
  'payroll.implementation.provider_account_access.mark_task_as_completed':
    'Mark task as completed Clicked',
  // Payroll Run Workflow - Hours and Time Off
  'payroll.payroll_run_workflow.hours_and_time_off.page_shown':
    'Hours and Time Off Step Shown',
  'payroll.payroll_run_workflow.hours_and_time_off.next_clicked':
    'Next Clicked',
  // Payroll Run Workflow - Net Pay
  'payroll.payroll_run_workflow.net_pay.page_shown': 'Net Pay Step Shown',
  'payroll.payroll_run_workflow.net_pay.next_clicked': 'Next Clicked',
  // Payroll Run Workflow - Review and Submit
  'payroll.payroll_run_workflow.review_and_submit.page_shown':
    'Submission Step Shown',
  'payroll.payroll_run_workflow.review_and_submit.submit_payroll_clicked':
    'Submit Payroll Clicked',
  'payroll.payroll_run_workflow.review_and_submit.submission_error_shown':
    'Submission Error Shown',
  'payroll.payroll_run_workflow.review_and_submit.submission_error_clicked':
    'Submission Error Clicked',
  'payroll.payroll_run_workflow.review_and_submit.nsf_prevention_checkbox_clicked':
    'NSF Warning Acknowledged',
  // Payroll Reports
  'payroll.reports.payroll_journal.download_clicked':
    'Payroll journal report download clicked',
  'payroll.reports.location_breakdown_report.download_clicked':
    'Payroll location breakdown report download clicked',
  'payroll.reports.contractor_payments.download_clicked':
    'Payroll contractor payments report download clicked',
  'payroll.reports.payroll_summary.download_clicked':
    'Payroll Summary report download clicked',
  'payroll.reports.team_members.download_clicked':
    'Employee and Contractor Information Report download clicked',
  'payroll.reports.w2_preview.download_clicked':
    'W-2 preview report download clicked',
  'payroll.reports.fica_tip_tax_credit.download_clicked':
    'FICA tip tax credit report download clicked',
  'payroll.reports.bank_transactions_summary.download_clicked':
    'Bank transactions summary report download clicked',
};

export const SIGNER_TITLES = [
  'CEO',
  'CFO',
  'COO',
  'CPA',
  'Chairman of the Board',
  'Conservator',
  'Controller',
  'Corporate Officer',
  'Director',
  'Director of Finance',
  'Director of Operations',
  'Executive Director',
  'Finance Manager',
  'Managing Director',
  'Managing Member/Partner Member',
  'Non-for-Profit',
  'Officer Operations Manager',
  'Owner',
  'Partner',
  'Power of Attorney',
  'President',
  'Principal',
  'Trustee',
  'Vice President',
  'Area Vice President',
  'Shareholder',
  'Doctor',
  'Attorney',
  'Treasurer',
];

export const CHECK_ONBOARDING_COMPONENT_TYPES = {
  filing_authorization: 'filing_authorization',
  tax_setup: 'tax_setup',
  payment_setup: 'payment_setup',
  accounting_integration: 'accounting_integration',
  terms_of_service: 'terms_of_service',
};

export const PAYMENT_METHODS = {
  MANUAL: 'manual',
  DIRECT_DEPOSIT: 'direct_deposit',
};

export const RUN_LATE_PAYROLL_URL =
  'https://support.joinhomebase.com/s/article/Run-a-late-payroll';

export const W2_1099_URL =
  'https://support.joinhomebase.com/s/article/Accessing-and-Distributing-W-2s-1099s';

export const hidePayrollCountdownBannerLocalStorageKey = `hidePayrollCountdownBanner${
  new Date().getFullYear() + 1
}`;

export const CHURNED_PAYROLL = 'churned_payroll';
export const ACTIVE_PAYROLL = 'active_payroll';
export const PRE_FIRST_RUN = 'pre_first_run';
export const STARTED_TRANSFER = 'started_transfer';
export const IMPLEMENTATION = 'implementation';

export const REQUIRE_MFA_STATES = [
  IMPLEMENTATION,
  PRE_FIRST_RUN,
  ACTIVE_PAYROLL,
];
export const AUTOPAYROLL_SETTINGS_STATES = [ACTIVE_PAYROLL, PRE_FIRST_RUN];

export const LATE_RUN_TYPES_TRACKING_KEYS = {
  late_manual:
    PAYROLL_TRACKING['payroll.late_payroll_run.current_payday_clicked'],
  late_first_run:
    PAYROLL_TRACKING['payroll.late_payroll_run.current_payday_clicked'],
  late_direct_deposit:
    PAYROLL_TRACKING['payroll.late_payroll_run.next_available_payday_clicked'],
};

export const PAYROLL_DRAWER_COMPONENTS_NAMES = {
  PAYROLL_SUMMARY_DRAWER: 'PAYROLL_SUMMARY_DRAWER',
  PAYROLL_TEAM_MEMBERS_DRAWER: 'PAYROLL_TEAM_MEMBERS_DRAWER',
  PAYROLL_MAIL_TAX_FORMS_DRAWER: 'PAYROLL_MAIL_TAX_FORMS_DRAWER',
  COMPANY_DEFINED_ATTRIBUTES_DRAWER: 'COMPANY_DEFINED_ATTRIBUTES_DRAWER',
  PAYROLL_PREDICTED_FUNDING_FAILURE_DRAWER:
    'PAYROLL_PREDICTED_FUNDING_FAILURE_DRAWER',
};

export const PAYROLL_ERROR_TYPES = {
  multiple_salaried_wages: 'multiple_salaried_wages',
  negative_salaried_hours: 'negative_salaried_hours',
};

export const NEXT_INSURANCE_KEY = 'next_insurance';

export const PAY_ANY_DAY_EMPLOYER_LEARN_MORE_URL =
  'https://joinhomebase.com/pay-any-day-employers';
