import { toI18n } from 'util/i18n';

interface Role {
  id: string;
  name: string;
  department_name: string;
}
export interface RoleOptionDepartment {
  groupLabel: string;
}

interface RoleOptionRole {
  value: string;
  label: string;
}

interface RoleOptionDivider {
  divider: boolean;
}

export type RoleOption =
  | RoleOptionDepartment
  | RoleOptionRole
  | RoleOptionDivider;

const DEPARTMENT_NOT_SET = 'Dept. Not Set';

export const truncateOptionLabel = (label: string, maxLength: number = 30) => {
  if (label.length > maxLength) {
    return `${label.substring(0, maxLength)}...`;
  }
  return label;
};

export const formatRoleOptionsWithDepartments = (roles: Role[]) => {
  // group roles by department_name
  const rolesByDepartment = roles.reduce(
    (acc: Record<string, Role[]>, role: Role) => {
      const deptName = role.department_name || DEPARTMENT_NOT_SET;
      if (!acc[deptName]) {
        acc[deptName] = [];
      }
      acc[deptName].push(role);
      return acc;
    },
    {}
  );

  // Sort departments alphabetically, with DEPARTMENT_NOT_SET at the bottom
  const sortedDepartments = Object.keys(rolesByDepartment).sort(
    (a: string, b: string) => {
      if (a === DEPARTMENT_NOT_SET) return 1;
      if (b === DEPARTMENT_NOT_SET) return -1;
      return a.toLowerCase().localeCompare(b.toLowerCase());
    }
  );

  const roleOptions: RoleOption[] = [];

  sortedDepartments.forEach(department => {
    // Add department header
    roleOptions.push({
      groupLabel:
        department === DEPARTMENT_NOT_SET
          ? toI18n('timesheets.daily_review.filter.department_not_set')
          : truncateOptionLabel(department),
    });

    // Add roles sorted alphabetically
    const sortedRoles = rolesByDepartment[department].sort((a: Role, b: Role) =>
      (a.name || '').toLowerCase().localeCompare((b.name || '').toLowerCase())
    );

    sortedRoles.forEach((role: Role) => {
      roleOptions.push({
        value: role.id,
        label: truncateOptionLabel(role.name),
      });
    });

    // Add divider after each department group
    roleOptions.push({ divider: true });
  });

  // Remove the last divider
  roleOptions.pop();

  return roleOptions;
};
