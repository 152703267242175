export const DAILY_REVIEW_TABLE_COLUMNS = [
  {
    name: 'employee',
    width: 240,
  },
  {
    name: 'worked',
    width: 240,
  },
  {
    name: 'paid_breaks_hours',
    width: 132,
  },
  {
    name: 'unpaid_breaks_hours',
    width: 132,
  },
  {
    name: 'issues',
    width: 212,
  },
  {
    name: 'actions',
    width: 212,
  },
];

export const DEPARTMENT_NOT_SET = 'Dept. Not Set';
