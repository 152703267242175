import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'initializers/types';

import { JobDescription, JobDescriptionState } from './types';

const SLICE_NAME = 'jobDescription';

const initialState: JobDescriptionState = {
  jobDescription: null,
  loading: false,
  error: null,
};

const jobDescriptionSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setSaveJobDescription: (state, action: PayloadAction<JobDescription>) => {
      state.jobDescription = action.payload;
    },
    setSaveJobDescriptionLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSaveJobDescriptionError: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.error = action.payload;
    },
  },
});

// Selectors
const getJobDescriptionState = (state: RootState) =>
  state.get('hiringV2').jobDescription;
export const selectJobDescription = (state: RootState): JobDescription | null =>
  getJobDescriptionState(state).jobDescription;

export const selectJobDescriptionLoading = (state: RootState): boolean =>
  getJobDescriptionState(state).loading;

export const selectJobDescriptionError = (state: RootState): string | null =>
  getJobDescriptionState(state).error;

export const {
  setSaveJobDescription,
  setSaveJobDescriptionLoading,
  setSaveJobDescriptionError,
} = jobDescriptionSlice.actions;

export const reducer = jobDescriptionSlice.reducer;
