import { toI18n } from 'util/i18n';
export const PAYROLL_RUN_TYPES = {
  standard: 'standard',
  off_cycle: 'off_cycle',
};

export const STEPS = {
  earningsStep: 1,
  netPayStep: 2,
  submitStep: 3,
};

export const OFF_CYCLE_STEPS = {
  setupStep: 1,
  teamMemberStep: 2,
  earningsStep: 3,
  netPayStep: 4,
  submitStep: 5,
};

export const allWageTypes = {
  hourly: {
    id: 'hourly',
    label: toI18n(
      'payroll.off_cycle.team_member_selection_table.filter.wage_types.options.hourly'
    ),
  },
  annual: {
    id: 'annual',
    label: toI18n(
      'payroll.off_cycle.team_member_selection_table.filter.wage_types.options.annual'
    ),
  },
};

export const allPayrollClassifications = {
  w2: {
    id: 'w2',
    label: toI18n(
      'payroll.off_cycle.team_member_selection_table.filter.payroll_classification.options.w2'
    ),
  },
  1099: {
    id: '1099',
    label: toI18n(
      'payroll.off_cycle.team_member_selection_table.filter.payroll_classification.options.1099'
    ),
  },
};
