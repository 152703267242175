import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { faArrowToLeft } from '@fortawesome/pro-light-svg-icons/faArrowToLeft';
import { faArrowToRight } from '@fortawesome/pro-light-svg-icons/faArrowToRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { partial } from 'lodash';
import { colors } from 'theme/constants';

import { minimizeLaunchWeekAward } from 'actions/nav';

import { getIsLaunchWeekAwardMinimized, getUserData } from 'selectors/nav';
import {
  getCurrentLocationLaunchWeekSettings,
  getIsLaunchWeekWinner,
} from 'selectors/session';

import LaunchWeekStoryDialog from 'features/Growth/LaunchWeek/LaunchWeekStoryDialog';
import { useWebTimeClock } from 'features/timeClock/hooks/useWebTimeClock';

import Box from 'components/Box';
import Text from 'components/Text';

import { toI18n } from 'util/i18n';
import {
  ELEMENTS,
  EVENT_ACTIONS as UTILS_EVENT_ACTIONS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

import UpgradeButtonNavItem from '../nav/items/UpgradeButtonNavItem';

import GenericMenu from './GenericMenu';
import { EVENT_ACTIONS } from './tracking';

const Footer = ({
  sizes,
  cxEl,
  collapsedState,
  collapseLeftNav,
  isLaunchWeekAwardMinimized,
  isLaunchWeekWinner,
  userData,
  mobile,
  launchWeekSettings,
  onMinimizeLaunchWeekAward,
}) => {
  const [launchWeekAwardBadgeClassName, setLaunchWeekAwardBadgeClassName] =
    useState(
      (isLaunchWeekAwardMinimized ||
        launchWeekSettings.get('award_dismissed') ||
        launchWeekSettings.get('award_started')) &&
        window.sessionStorage.getItem(
          'launch_week_award_badge_pulse_finished'
        ) !== 'true'
        ? 'launch-week-award-badge-animated'
        : 'launch-week-award-badge-static'
    );

  setTimeout(() => {
    setLaunchWeekAwardBadgeClassName('');
    window.sessionStorage.setItem(
      'launch_week_award_badge_pulse_finished',
      true
    );
  }, 3_500);

  const [avatarMenuActive, setAvatarMenuActive] = useState(false);
  const timeClockOnClick = useWebTimeClock();
  const trackUxEvent = useTrackUx();

  const [isLaunchWeekStoryDialogOpen, setIsLaunchWeekStoryDialogOpen] =
    useState(false);
  const handleLaunchWeekStoryDialogOpen = useCallback(() => {
    trackUxEvent(UTILS_EVENT_ACTIONS.BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      element: ELEMENTS.LAUNCH_WEEK_VIEW_AWARD,
    });
    setIsLaunchWeekStoryDialogOpen(true);
  }, [trackUxEvent]);
  const handleLaunchWeekStoryDialogClose = useCallback(() => {
    setIsLaunchWeekStoryDialogOpen(false);
    onMinimizeLaunchWeekAward();
  }, [onMinimizeLaunchWeekAward]);

  const handleCollapseNavToggle = useCallback(() => {
    trackUxEvent(
      collapsedState
        ? EVENT_ACTIONS.EXPAND_CLICKED
        : EVENT_ACTIONS.COLLAPSE_CLICKED,
      TRACK_ACTION_TYPES.CLICK
    );
    collapseLeftNav(!collapsedState);
  }, [collapsedState, collapseLeftNav, trackUxEvent]);

  const avatarSubItems = useMemo(() => {
    const handleAvatarSubItemClick = e => {
      setAvatarMenuActive(false);
      e.preventDefault();
      window.Homebase.RailsReactBridge.navigateToReactView(
        e.currentTarget.getAttribute('href')
      );
      collapseLeftNav(true);
    };

    const createAvatarSubmenu = (name, url) =>
      new Map([
        ['name', toI18n(name)],
        ['url', userData.get(url)],
        ['on_click', handleAvatarSubItemClick],
      ]);

    const signOutItem = new Map([
      ['name', toI18n('nav_links.devise.sign_out')],
      ['url', userData.get('signOutUrl')],
      ['on_click', handleAvatarSubItemClick],
      ['dataMethod', 'delete'],
      ['rel', 'unfollow'],
    ]);

    return [
      createAvatarSubmenu('nav_links.dashboard.user', 'profileUrl'),
      createAvatarSubmenu('nav_links.settings.user', 'settingsUrl'),
      createAvatarSubmenu('nav_links.user_referrals', 'referralsUrl'),
      signOutItem,
    ];
  }, [userData, collapseLeftNav]);

  const footerItems = useMemo(() => {
    const avatarSecIcon = () => (avatarMenuActive ? 'caretUp' : 'caretDown');

    const handleAvatarClick = e => {
      e.preventDefault();
      setAvatarMenuActive(!avatarMenuActive);
    };

    return [
      new Map([
        ['name', toI18n('nav_links.time_clock')],
        ['icon', 'timeclock'],
        ['active', false],
        ['on_click', timeClockOnClick],
      ]),
      new Map([
        ['name', userData.get('fullName')],
        ['avatar_url', userData.get('avatarUrl')],
        ['secondary_icon', avatarSecIcon()],
        ['active', avatarMenuActive],
        ['on_click', handleAvatarClick],
        ['sub_items', avatarSubItems],
      ]),
    ];
  }, [userData, avatarMenuActive, avatarSubItems, timeClockOnClick]);

  return (
    <Box className={cxEl('footer')}>
      {mobile && (
        <Box>
          <Box key="upgrade">
            <Box
              pv={14}
              pl={sizes.leftPadding - 10}
              row
              vcenter
              pointer
              tag="span"
              block
              borderbox
              noWrap
            >
              <UpgradeButtonNavItem
                newHeader
                fullText
                inLeftNav
                onCollapseLeftNav={partial(collapseLeftNav, true)}
              />
            </Box>
          </Box>

          {footerItems.map(item => (
            <GenericMenu
              key={item.get('name')}
              item={item}
              avatarUrl={item.get('avatar_url')}
              sizes={sizes}
              cxEl={cxEl}
              collapsedState={collapsedState}
              collapseLeftNav={partial(collapseLeftNav, true)}
            />
          ))}
        </Box>
      )}

      <Box
        pv={14}
        pl={sizes.leftPadding}
        row
        vcenter
        pointer
        tag="span"
        block
        noWrap
        className={cxEl('collapse')}
        onClick={handleCollapseNavToggle}
      >
        <Box mr={16} tag="span">
          <FontAwesomeIcon
            icon={collapsedState ? faArrowToRight : faArrowToLeft}
            color={colors.primary}
            style={{ width: 20, height: 20 }}
          />
        </Box>

        {!collapsedState && (
          <Box tag="span">
            <Text
              i18n="left_nav.collapse"
              fs={sizes.topMenuFontSize}
              fw600
              color="primary"
            />
          </Box>
        )}
      </Box>
      {!collapsedState &&
        isLaunchWeekWinner &&
        (isLaunchWeekAwardMinimized ||
          launchWeekSettings.get('award_dismissed') ||
          launchWeekSettings.get('award_started')) && (
          <Box
            className={cxEl(launchWeekAwardBadgeClassName)}
            mt="5%"
            w={200}
            onClick={handleLaunchWeekStoryDialogOpen}
            pointer
          >
            <img
              src={require('assets/launch_week_left_nav_badge.png')}
              alt="Launch Week Award Badge"
            />
          </Box>
        )}
      <LaunchWeekStoryDialog
        open={isLaunchWeekStoryDialogOpen}
        onClose={handleLaunchWeekStoryDialogClose}
      />
    </Box>
  );
};

export default connect(
  state => ({
    isLaunchWeekWinner: getIsLaunchWeekWinner(state),
    launchWeekSettings: getCurrentLocationLaunchWeekSettings(state),
    userData: getUserData(state),
    isLaunchWeekAwardMinimized: getIsLaunchWeekAwardMinimized(state),
  }),
  { onMinimizeLaunchWeekAward: minimizeLaunchWeekAward }
)(Footer);
