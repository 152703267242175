import { filter, snakeCase } from 'lodash';

export const titleCase = (string: string) =>
  string
    ?.split(' ')
    .map(
      item =>
        item.substring(0, 1).toUpperCase() + item.substring(1).toLowerCase()
    )
    .join(' ');

export const formatUseTypes = (data: any) => {
  const {
    payrollHowYouPayToday,
    payrollSoftwareName,
    businessAge,
    expectedLaunchDate,
    ...restMotivation
  } = data;

  const useType = filter(
    Object.keys(restMotivation),
    (key: any) => restMotivation[key]
  )
    .map((key: any) => snakeCase(key))
    .join(', ');

  return useType;
};
