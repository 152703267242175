import { List } from 'immutable';
import { isBoolean, keys } from 'lodash';

import { SETTINGS_ROUTES } from 'features/settings/constants';
import { defaultGetInitialState } from 'features/settings/withLocationSettingsForm';

import * as formsUtil from 'util/forms';

// The server expects certain bools to be represented as a "0"(false) or "1"(true).
// Silly server.
const boolToStr = bool => (+bool).toString();

const arrayToObject = (arr, formatter = el => el) =>
  arr.reduce((obj, el, i) => {
    obj[i] = formatter(el);
    return obj;
  }, {});

// ## Server Data Formatters
const PROPERTIES_ATTRIBUTES = [
  'holiday_pay_enabled',
  'holiday_pay_rate',
  'manager_log',
  'unscheduled_remote_clockin_enabled',
  'gps_radius',
  'break_penalty_enabled',
  'break_penalty',
  'sales_reset_time_enabled',
  'sales_reset_time',
  'start_of_workday_offset',
  'open_time',
  'closing_time',
  'availability_approval_enabled',
  'sms_notify_schedule',
  'hide_schedule_from_employees',
  'manage_department',
  'hide_manager_wages',
  'web_timeclock_employee_access',
  'auto_clock_out_enabled',
  'auto_clock_out_minutes',
  'unscheduled_role_selection_enabled',
  'clock_out_reminder',
  'rhode_island_overtime',
  'blue_laws',
  'blue_law_roles',
  'round_clock_ins',
  'round_clock_ins_increment',
  'time_off_limit',
  'time_off_limit_enabled',
  'use_time_off_request_day_min',
  'time_off_request_day_min',
  'paid_time_off',
  'payroll_info_week',
  'pay_date',
  'timesheet_approval_enabled',
  'messaging_employee_clock_out',
  'clock_out_approval',
  'shoutouts_enabled',
  'payroll_import_tips_enabled',
  'timeclock_health_questions_enabled',
  'payroll_payday_settings_day_1',
  'payroll_payday_settings_day_2',
  'breaks_enforced_v2',
  'break_waiver_all_consented',
];

const SCHEDULE_PROPERTIES = ['show_events'];

const COMPANY_ATTRIBUTES = ['common_overtimes', 'messaging_enabled'];

export const SETTINGS_HIGHLIGHT_OVERRIDES = {
  [SETTINGS_ROUTES.LOCATION.CANCEL_ADDONS]: SETTINGS_ROUTES.LOCATION.BILLING,
};

export const formatUserDataForServer = (formData, locations) => {
  const data = { ...formData };
  keys(formData).forEach(key => {
    if (key === 'location_subscriptions') {
      const locationSubscriptions = {};
      keys(locations.toObject()).forEach(
        id =>
          (locationSubscriptions[id] = formData.location_subscriptions.includes(
            parseInt(id, 10)
          ))
      );
      data.location_subscriptions = locationSubscriptions;
    } else if (key === 'notification_settings') {
      data.notification_settings_attributes =
        formData.notification_settings.toJS();
      delete data[key];
    }
  });

  return { user: data };
};

export const formatDataForServer = (formData, location) => {
  const serverPayload = {};
  const data = { ...formData };
  const dataKeys = keys(formData);

  dataKeys.forEach(key => {
    // See comment for `boolToStr` above.
    if (isBoolean(data[key])) {
      data[key] = boolToStr(data[key]);
    }

    if (PROPERTIES_ATTRIBUTES.includes(key)) {
      data.properties_attributes = data.properties_attributes || {};
      data.properties_attributes[key] = data[key];
      delete data[key];
    }

    if (SCHEDULE_PROPERTIES.includes(key)) {
      data.schedule_properties_attributes =
        data.schedule_properties_attributes || {};
      data.schedule_properties_attributes[key] = data[key];
      delete data[key];
    }

    if (COMPANY_ATTRIBUTES.includes(key)) {
      data.company_attributes = data.company_attributes || {
        id: location.get('company_id'),
      };
      data.company_attributes[key] = data[key];
      delete data[key];
    }

    if (key === 'phone') {
      data.phone = formsUtil.serverFormatters.phone(data.phone);
    }

    if (key === 'convert_time_zone_data') {
      serverPayload.convert_time_zone_data =
        data.convert_time_zone_data === '1';
      delete data.convert_time_zone_data;
    }

    // For field arrays like 'holidays' and 'mandated_breaks',
    // server expects attributes to be in the form:
    //  {
    //    "0" => { "id"=>"27", "active"=>"1", "duration"=>"10", ... },
    //    "1" => { "id"=>"28", "active"=>"1", "duration"=>"10", ... },
    //    ...
    //  }
    if (key === 'holidays') {
      data.events_attributes = data.events_attributes || {};
      const holidaysAttributes = arrayToObject(data.holidays, holiday => ({
        ...holiday,
        holiday: true,
      }));

      data.events_attributes = {
        ...data.events_attributes,
        ...holidaysAttributes,
      };
      delete data.holidays;
    }

    if (key === 'mandated_breaks') {
      data.mandated_breaks_attributes = arrayToObject(data.mandated_breaks);
      delete data.mandated_breaks;
    }

    if (key === 'payroll_period' || key === 'payday_settings') {
      const payrollPeriod = data.payroll_period;

      if (payrollPeriod === 'twice_month') {
        data.payroll_info = [
          data.payroll_info_twice_month_1,
          data.payroll_info_twice_month_2,
        ];
        data.payday_settings = [
          data.payday_settings_twice_month_1,
          data.payday_settings_twice_month_2,
        ];
      } else {
        data.payroll_info = [data[`payroll_info_${payrollPeriod}`]];
        data.payday_settings = [data[`payday_settings_${data.payroll_period}`]];
      }

      ['week', 'twice_week', 'month', 'twice_month_1', 'twice_month_2'].forEach(
        period => {
          delete data[`payroll_info_${period}`];
          delete data[`payday_settings_${period}`];
        }
      );
    }
  });

  serverPayload.location = data;

  return serverPayload;
};

export const convertRadioValueToFormState = radioOption => {
  if (radioOption === 'breaks_enforced_v2')
    return {
      breaks_enforced_v2: 1,
    };
  return {
    breaks_enforced_v2: 0,
    break_waiver_all_consented: 0,
  };
};

export const getInitialState = (props, formKeys, fieldArrayFormKeys) => ({
  ...defaultGetInitialState(props, formKeys, fieldArrayFormKeys),
  blue_law_roles: (props.location.get('blue_law_roles') || List()).toArray(),
});
