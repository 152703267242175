import React from 'react';
import { CxProps } from 'fe-core/util/withCx';
import { ColorProp, PaletteProp } from 'fe-design-base/types';

import { BOX_SHADOW_DIRECTIONS, BOX_SHADOW_VARIANTS } from './constants';

export const BOX_ALLOWED_COMPONENTS = [
  'div',
  'span',
  'article',
  'aside',
  'details',
  'figcaption',
  'figure',
  'footer',
  'header',
  'li',
  'main',
  'mark',
  'nav',
  'section',
  'summary',
  'time',
  'table',
  'tr',
  'td',
  'tbody',
  'thead',
  'tfoot',
  'th',
  'ul',
] as const;

export type BoxComponent = (typeof BOX_ALLOWED_COMPONENTS)[number];
export type FlexProp = boolean | StyleProp;
export type FlexProps = Record<string, FlexProp>;

export type BaseProps<P> = P &
  ClassProps &
  StyleProps &
  CxProps & {
    dataTestId?: string;
  };

export type Props<
  P = unknown,
  T extends React.ElementType = React.ElementType
> = BaseProps<P> & { component?: T } & Omit<
    React.ComponentPropsWithRef<T>,
    keyof BaseProps<P>
  >;

export type ClassProps = {
  absolute?: boolean;
  absoluteCover?: boolean;
  alignItemsBaseline?: boolean;
  alignItemsCenter?: boolean;
  alignItemsEnd?: boolean;
  alignItemsStart?: boolean;
  alignItemsStretch?: boolean;
  alignSelfStart: boolean;
  alignSelfCenter: boolean;
  alignSelfEnd: boolean;
  baseline?: boolean;
  block?: boolean;
  borderbox?: boolean;
  bradiusfull?: boolean;
  bradiusl?: boolean;
  bradiusm?: boolean;
  bradiuss?: boolean;
  column?: boolean;
  columnreverse?: boolean;
  ellipsis?: boolean;
  fixed?: boolean;
  flex?: boolean;
  grid?: boolean;
  hcenter?: boolean;
  hide?: boolean;
  hleft?: boolean;
  hright?: boolean;
  inline?: boolean;
  inlineBlock?: boolean;
  justifyContentCenter?: boolean;
  justifyContentEnd?: boolean;
  justifyContentSpaceAround?: boolean;
  justifyContentSpaceBetween?: boolean;
  justifyContentSpaceEvenly?: boolean;
  justifyContentStart?: boolean;
  noWrap?: boolean;
  pointer?: boolean;
  pointerEventsNone?: boolean;
  relative?: boolean;
  row?: boolean;
  rowreverse?: boolean;
  scrollX?: boolean;
  scrollY?: boolean;
  spacearound?: boolean;
  spacebetween?: boolean;
  spaceevenly?: boolean;
  sticky?: boolean;
  stretch?: boolean;
  tcenter?: boolean;
  tleft?: boolean;
  tright?: boolean;
  vcenter?: boolean;
  vtop?: boolean;
  vbottom?: boolean;
  wrap?: boolean;
  wrapreverse?: boolean;
  isAI?: boolean;
  isAIAnimating?: boolean;
};

export type StyleProp = string | number;

export type StyleProps = {
  b?: string;
  bbw?: StyleProp;
  color?: ColorProp | PaletteProp;
  bcolor?: ColorProp | PaletteProp;
  bgcolor?: ColorProp | PaletteProp;
  blw?: StyleProp;
  brw?: StyleProp;
  bs?: string;
  btw?: StyleProp;
  bw?: StyleProp;
  gridTemplateColumns?: string;
  w?: StyleProp;
  h?: StyleProp;
  top?: StyleProp;
  bottom?: StyleProp;
  left?: StyleProp;
  right?: StyleProp;
  lh?: StyleProp;
  m?: StyleProp;
  maxh?: StyleProp;
  maxw?: StyleProp;
  minh?: StyleProp;
  minw?: StyleProp;
  ml?: StyleProp;
  mr?: StyleProp;
  mb?: StyleProp;
  mh?: StyleProp;
  mt?: StyleProp;
  mv?: StyleProp;
  opacity?: StyleProp;
  order?: number;
  overflow?: StyleProp;
  overflowX?: StyleProp;
  overflowY?: StyleProp;
  p?: StyleProp;
  pt?: StyleProp;
  pb?: StyleProp;
  ph?: StyleProp;
  pl?: StyleProp;
  pv?: StyleProp;
  pr?: StyleProp;
  shadow?: (typeof BOX_SHADOW_VARIANTS)[number];
  shadowDirection?: (typeof BOX_SHADOW_DIRECTIONS)[number];
  textDecoration?: StyleProp;
  grow?: StyleProp;
  shrink?: StyleProp;
  basis?: StyleProp;
  z?: StyleProp;
  zIndex?: StyleProp;
  gap?: StyleProp;
  visibility?: StyleProp;
};
