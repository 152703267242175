import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toast from 'fe-design-base/molecules/Toast';
import { ToastProps } from 'fe-design-base/molecules/Toast/Toast';

import { useExtendUx, withUxRoot } from 'util/uxEvents';

import {
  selectIsToastOpen,
  selectToastContent,
  selectToastProps,
  selectToastUxContext,
} from './selectors';
import { actions as globalToastActions } from './slice';

type Props = Omit<ToastProps, 'onClose' | 'children' | 'open'> & {
  onClose?: () => void;
};

const GlobalToast = () => {
  const toastProps = useSelector(selectToastProps);
  const isOpen = useSelector(selectIsToastOpen);
  const content = useSelector(selectToastContent);
  const uxContext = useSelector(selectToastUxContext);

  const dispatch = useDispatch();
  const extendUx = useExtendUx();

  const { onClose, ...restOfProps }: Props = toastProps;

  extendUx(uxContext);

  const handleClose = useCallback(() => {
    dispatch(globalToastActions.closeGlobalToast());
    onClose?.();
  }, [dispatch, onClose]);

  return (
    <Toast open={isOpen} onClose={handleClose} {...restOfProps}>
      {content}
    </Toast>
  );
};

export default withUxRoot({})(GlobalToast);
