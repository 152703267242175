import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { faComment } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keys, omit } from 'lodash';
import PropTypes from 'prop-types';

import { buildGetDrawerOpen } from 'selectors/drawers';
import { getMessagingV3Enabled } from 'selectors/session';

import { MESSENGER_VIEW_DRAWER_KEY } from 'features/drawers/keys';
import { toggleMessengerShown } from 'features/messengerv2/actions';
import { selectTeamHasUnreadMessagesOrConvos } from 'features/messengerv2/selectors';

import Icon from 'components/Icon';
import Text from 'components/Text';

import cxHelpers from 'util/className';
import { trackEvent, trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
} from 'util/tracking_constants';

import withNavItemData from '../../hocs/withNavItemData';
import NavItem from '../NavItem';
const PROP_TYPES = {
  messengerIsShown: PropTypes.bool.isRequired,
  onToggleMessenger: PropTypes.func.isRequired,
  showIndicator: PropTypes.bool.isRequired,
  isText: PropTypes.bool,
  newHeader: PropTypes.bool,
};

const PROP_TYPE_KEYS = keys(PROP_TYPES);

@withNavItemData('messenger')
@connect(
  (state, props) => ({
    messengerIsShown: buildGetDrawerOpen(MESSENGER_VIEW_DRAWER_KEY)(
      state,
      props
    ),
    showIndicator: getMessagingV3Enabled(state)
      ? state.get('messaging')?.totalUnreadCount > 0
      : selectTeamHasUnreadMessagesOrConvos(state, props),
  }),
  { onToggleMessenger: toggleMessengerShown }
)
@cxHelpers('MessengerNavItem')
export default class MessengerNavItem extends PureComponent {
  static propTypes = PROP_TYPES;

  handleToggleMessenger = () => {
    const trackingEvent = this.props.isText
      ? 'Messages Tab Clicked'
      : 'Messages Icon Clicked';
    trackEvent(trackingEvent);
    trackUxEvent({
      productArea: PRODUCT_AREAS.NAVIGATION,
      eventCategory: EVENT_CATEGORIES.NAV,
      eventAction: EVENT_ACTIONS.MESSAGES_TAB_CLICKED,
      properties: { messaging_version: 2 },
    });

    this.props.onToggleMessenger();
  };

  icon() {
    if (this.props.newHeader) {
      return (
        <Text fs24>
          <FontAwesomeIcon icon={faComment} color="white" />
        </Text>
      );
    }

    return <Icon size={16} type="message2" />;
  }

  render() {
    return (
      <NavItem
        {...omit(this.props, PROP_TYPE_KEYS)}
        className={this.cx()}
        onClick={this.handleToggleMessenger}
        active={this.props.messengerIsShown}
        indicator={this.props.showIndicator}
      >
        {this.props.isText ? (
          <Text className="text-messaging" fs14 i18n="nav_links.messaging" />
        ) : (
          this.icon()
        )}
      </NavItem>
    );
  }
}
