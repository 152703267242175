import capitalize from 'lodash/capitalize';

import {
  CONTRACTOR_CLASSIFICATIONS,
  CONTRACTOR_TYPES,
  EMPLOYEE_CLASSIFICATIONS,
} from 'features/team/constants';

import { toI18n } from 'util/i18n';

import { MESSAGE_KEY } from '../AddEmployeeSuccessModal/constants';

import {
  I18N_ERROR_PATH,
  INPUT_ERROR_TYPE,
  INVITE_STATUS,
  JOB_TAX_CLASSIFICATIONS,
  ONBOARDING_OPTIONS,
} from './constants';

const getIsInvitePending = (user, locationId) =>
  user.jobs.some(
    job =>
      job.location_id === locationId &&
      job.archived_at === null &&
      user.invite_status === INVITE_STATUS.PENDING
  );

const getIsTerminated = (user, locationId) =>
  user.jobs.some(job => job.location_id === locationId && job.archived_at);

const getIsMergeRequest = user =>
  user.invite_status === INVITE_STATUS.MERGE_REQUEST_PENDING ||
  user.invite_status === INVITE_STATUS.MERGE_REQUEST_DECLINED;

const getJobTaxClassificationValue = (taxClassification, contractorType) => {
  if (taxClassification === EMPLOYEE_CLASSIFICATIONS.tax_classification) {
    return JOB_TAX_CLASSIFICATIONS[0].value;
  } else if (
    taxClassification === CONTRACTOR_CLASSIFICATIONS.tax_classification
  ) {
    return contractorType === CONTRACTOR_TYPES.INDIVIDUAL
      ? JOB_TAX_CLASSIFICATIONS[1].value
      : JOB_TAX_CLASSIFICATIONS[2].value;
  }
  return null;
};

export const formatResponse = (user, locationId) => {
  if (!user) return null;

  const {
    id: jobId,
    wages,
    level,
    tax_classification,
    contractor_type,
  } = user.jobs.find(current => current.location_id === locationId) || {};

  const userObj = {
    jobId,
    level,
    jobTaxClassification: getJobTaxClassificationValue(
      tax_classification,
      contractor_type
    ),
    userId: user.id,
    firstName: user.first_name,
    lastName: user.last_name || '',
    email: user.email || '',
    phone: user.phone || '',
    type: INPUT_ERROR_TYPE.ACTIVE,
    inviteStatus: user.invite_status,
    roles:
      wages?.map(({ role_name, rate, type }) => ({
        rate,
        type,
        roleName: role_name,
      })) || [],
  };

  if (getIsInvitePending(user, locationId) || getIsMergeRequest(user))
    userObj.type = INPUT_ERROR_TYPE.RESEND_INVITE;
  else if (getIsTerminated(user, locationId))
    userObj.type = INPUT_ERROR_TYPE.REHIRE;

  return userObj;
};

export const formatFullName = (firstName, lastName) =>
  `${capitalize(firstName)}${lastName ? ' ' : ''}${capitalize(lastName)}`;

export const generateInputErrorProps = ({
  errorObj,
  errorMessage,
  rehiringInProgress,
  resendingInvite,
  resentInvite,
  onPrimaryAction,
  onSecondaryAction,
}) => {
  if (!errorObj) return {};

  const isRehire = errorObj.type === INPUT_ERROR_TYPE.REHIRE;
  const hasSecondaryAction =
    !rehiringInProgress && errorObj.type !== INPUT_ERROR_TYPE.ACTIVE;

  const primaryActionText =
    isRehire && rehiringInProgress
      ? toI18n(`${I18N_ERROR_PATH}.cancel_rehire`)
      : toI18n(`${I18N_ERROR_PATH}.view`, {
          props: { name: capitalize(errorObj.firstName) },
        });

  const title = isRehire
    ? toI18n(
        `${I18N_ERROR_PATH}.${
          rehiringInProgress ? 'now_rehiring' : 'rehiring'
        }`,
        {
          props: {
            name: formatFullName(errorObj?.firstName, errorObj?.lastName),
          },
        }
      )
    : null;

  const inputErrorProps = {
    title,
    onPrimaryAction,
    primaryActionText,
    error: errorMessage,
    inviteStatus: isRehire ? 'terminated' : errorObj.inviteStatus,
    secondaryIsDone: resentInvite,
    variant: isRehire ? 'info' : 'error',
    secondaryIsLoading: resendingInvite,
  };

  if (hasSecondaryAction) {
    inputErrorProps.onSecondaryAction = onSecondaryAction;
    inputErrorProps.secondaryActionText = isRehire
      ? toI18n(`${I18N_ERROR_PATH}.confirm_rehire`)
      : toI18n(
          `${I18N_ERROR_PATH}.${
            resentInvite ? 'invite_resent' : 'resend_invite'
          }`
        );
  }

  return inputErrorProps;
};

export const formHasContent = ({
  firstName,
  lastName,
  email,
  phone,
  defaultRoleName,
  wageRate,
}) =>
  Boolean(
    firstName || lastName || email || phone || defaultRoleName || wageRate
  );

const getSendOnBoardingPacket = onboarding =>
  onboarding === ONBOARDING_OPTIONS.INVITE_AND_PACKET;

const getSendSms = (onboarding, sendSms) => {
  if (onboarding === 'invite_only') {
    return sendSms;
  }

  return onboarding ? onboarding !== ONBOARDING_OPTIONS.NOTHING : sendSms;
};

const getJobsAttributes = (
  {
    level,
    hire_date,
    wage_rate,
    wage_type,
    location_id,
    contractor_type,
    default_role_name,
    tax_classification,
    included_in_payroll,
    role_wages_attributes,
  },
  isAdding
) => {
  const sharedAttributes = {
    level,
    hire_date,
    location_id,
    contractor_type,
    tax_classification,
    onboarding_packet_type: tax_classification,
  };

  if (isAdding)
    return {
      all_jobs_attributes: [
        {
          ...sharedAttributes,
          wage_rate,
          wage_type,
          default_role_name,
          role_wages_attributes,
          included_in_payroll,
        },
      ],
    };

  return {
    jobs: [
      {
        ...sharedAttributes,
        wages: [
          {
            rate: wage_rate,
            type: wage_type,
            is_tipped: false,
            role_name: default_role_name,
          },
          ...role_wages_attributes.map(
            ({ role_name, wage_rate: rate, wage_type: type }) => ({
              rate,
              type,
              role_name,
              is_tipped: false,
            })
          ),
        ],
      },
    ],
  };
};

const getPtoPolicyEmployeesAttributes = time_off_policies => {
  const ptoPolicyEmployeesAttributes = [];
  if (time_off_policies?.pto_policy?.id) {
    ptoPolicyEmployeesAttributes.push({
      pto_policy_id: time_off_policies.pto_policy.id,
      accrued_hours: time_off_policies.pto_policy.balance,
    });
  }

  if (time_off_policies?.paid_sick_leave_policy?.id) {
    ptoPolicyEmployeesAttributes.push({
      pto_policy_id: time_off_policies.paid_sick_leave_policy.id,
      accrued_hours: time_off_policies.paid_sick_leave_policy.balance,
    });
  }

  return ptoPolicyEmployeesAttributes;
};

export const createEmployeePayload = (
  {
    email,
    phone,
    level,
    sendSms,
    onboarding,
    includedInPayroll,
    jobTaxClassification,
    hireDate: hire_date,
    wageRate: wage_rate,
    wageType: wage_type,
    lastName: last_name,
    firstName: first_name,
    defaultRoleName: default_role_name,
    wageAttributes: role_wages_attributes,
    namesMatchLegalNames: names_match_legal_names,
    time_off_policies,
  },
  isAdding,
  location_id,
  isAIOLocation,
  isPayrollEnrolled
) => {
  const [taxClassification, contractorType] =
    jobTaxClassification?.split('-') || new Array(2).fill(null);

  const ptoPolicyEmployeesAttributes =
    getPtoPolicyEmployeesAttributes(time_off_policies);

  return {
    send_sms: getSendSms(
      isAIOLocation && !isPayrollEnrolled ? onboarding : null,
      sendSms
    ),
    send_onboarding_packet: getSendOnBoardingPacket(
      isAIOLocation || isPayrollEnrolled ? onboarding : null
    ),
    nothing_right_now: onboarding === ONBOARDING_OPTIONS.NOTHING_RIGHT_NOW,
    user: {
      email,
      phone,
      last_name,
      first_name,
      names_match_legal_names,
      pto_policy_employees_attributes: ptoPolicyEmployeesAttributes,
      ...getJobsAttributes(
        {
          level,
          wage_rate,
          wage_type,
          hire_date,
          location_id,
          contractor_type: contractorType,
          default_role_name,
          tax_classification: taxClassification,
          included_in_payroll: isPayrollEnrolled ? includedInPayroll : false,
          role_wages_attributes,
        },
        isAdding
      ),
    },
  };
};

export const getMessageKey = ({
  send_sms,
  send_onboarding_packet,
  user: { email, phone },
}) => {
  if (email && send_onboarding_packet) return MESSAGE_KEY.EMAIL_PACKET_INVITE;
  if (email && send_sms) return MESSAGE_KEY.EMAIL_INVITE;
  if (phone && send_onboarding_packet) return MESSAGE_KEY.TEXT_PACKET_INVITE;
  if (phone && send_sms) return MESSAGE_KEY.TEXT_INVITE;
};
