import { borderSizes, sizes } from 'fe-design-base/molecules/Avatar/Avatar';

import { Overrides } from '../../types';
import baseColors from '../colors';

export const avatarOverrides: Overrides = {
  MuiAvatar: {
    styleOverrides: {
      root: {
        '&.FDBAvatar': {
          borderColor: `${baseColors.mono0} !important`,

          '&.FDBAvatar--xl': {
            width: sizes.xl,
            height: sizes.xl,
            border: `${borderSizes.xl}px solid`,
            fontWeight: 800,
            fontSize: '48px',
          },
          '&.FDBAvatar--large': {
            width: sizes.large,
            height: sizes.large,
            border: `${borderSizes.large}px solid`,
            fontWeight: 700,
            fontSize: '20px',
          },
          '&.FDBAvatar--medium': {
            width: sizes.medium,
            height: sizes.medium,
            border: `${borderSizes.medium}px solid`,
            fontWeight: 700,
            fontSize: '14px',
          },
          '&.FDBAvatar--small': {
            width: sizes.small,
            height: sizes.small,
            border: `${borderSizes.small}px solid`,
            fontWeight: 700,
            fontSize: '12px',
          },

          '&.FDBAvatar--secondary': {
            color: baseColors.purple700,
            backgroundColor: baseColors.purple100,
          },
        },
      },
    },
  },
};
