import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgColorTwitter = ({
  alt = 'Twitter Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="#000"
      d="M18.9 1h3.681l-8.041 9.318L24 23h-7.405l-5.805-7.69L4.156 23H.47l8.6-9.969L0 1h7.593l5.241 7.028L18.9 1Zm-1.294 19.768h2.04L6.481 3.115h-2.19l13.314 17.653Z"
    />
  </svg>
);
export default SvgColorTwitter;
