import './UpgradeButtonNavItem.scss';

import React, { PureComponent } from 'react';
import MediaQuery from 'react-responsive';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import { BILLER_BUTTON_TEXT } from 'features/biller/constants';
import { MANAGE_PLANS_PATH } from 'features/managePlan/constants';

import Box from 'components/Box';
import Button from 'components/clickables/Button';
import withCurrentRoute from 'components/hocs/withCurrentRoute';
import { LargeDesktop, Mobile } from 'components/MediaQuery';
import Text from 'components/Text';

import cxHelpers from 'util/className';
import { trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
} from 'util/tracking_constants';

import withNavItemData from '../../hocs/withNavItemData';

import TrialAwarenessUpgradeButton from './TrialAwarenessUpgradeButton';

@withNavItemData('upgradeButton')
@withCurrentRoute
@cxHelpers('UpgradeButtonNavItem')
export default class UpgradeButtonNavItem extends PureComponent {
  static propTypes = {
    indicator: PropTypes.bool,
    fullText: PropTypes.bool,
    newHeader: PropTypes.bool,
    inLeftNav: PropTypes.bool,
    showTrialText: PropTypes.bool,
    onCollapseLeftNav: PropTypes.func,
    activeTrialEndDate: PropTypes.string,
  };

  handleClick = () => {
    trackUxEvent({
      productArea: PRODUCT_AREAS.NAVIGATION,
      eventCategory: EVENT_CATEGORIES.NAV,
      eventAction: EVENT_ACTIONS.BUTTON_CLICKED,
      properties: {
        button_text: BILLER_BUTTON_TEXT.UPGRADE_NOW,
      },
    });
    window.Homebase.RailsReactBridge.navigateToReactView(MANAGE_PLANS_PATH);
    this.props.onCollapseLeftNav?.();
  };

  renderNotificationIndicator() {
    return this.props.indicator ? (
      <span className={this.cxEl('notification-indicator')} />
    ) : null;
  }

  theme = () => {
    if (this.props.inLeftNav) return 'primary-purple';
    return this.props.newHeader
      ? 'secondary-purple-gray-medium-light'
      : 'secondary-blue';
  };

  render() {
    const {
      fullText,
      newHeader,
      currentRoute,
      showTrialText,
      onCollapseLeftNav,
      activeTrialEndDate,
    } = this.props;

    return (
      <MediaQuery minWidth={fullText ? 480 : 1030}>
        {minWidth => (
          <Box
            {...omit(
              this.props,
              'indicator',
              'fullText',
              'dispatch',
              'newHeader',
              'inLeftNav',
              'onCollapseLeftNav',
              'currentRoute',
              'showTrialText',
              'activeTrialEndDate'
            )}
            className={this.cx()}
            mr={5}
            style={{
              display: currentRoute === MANAGE_PLANS_PATH ? 'none' : 'block',
            }}
          >
            {activeTrialEndDate ? (
              <TrialAwarenessUpgradeButton
                onCollapseLeftNav={onCollapseLeftNav}
                activeTrialEndDate={activeTrialEndDate}
              />
            ) : (
              <Button
                className={this.cxEl('button')}
                theme={this.theme()}
                size={newHeader ? 'large' : null}
                onClick={this.handleClick}
              >
                {minWidth ? (
                  <LargeDesktop>
                    {largeDesktop =>
                      largeDesktop || fullText ? (
                        <Text
                          lh={newHeader ? 2 : null}
                          fs={newHeader ? 14 : null}
                          i18n={
                            showTrialText
                              ? 'nav_links.tiers.see_pricing'
                              : 'nav_links.tiers.top_level_upgrade'
                          }
                        />
                      ) : (
                        <Text
                          lh={newHeader ? 2 : null}
                          fs={newHeader ? 14 : null}
                          i18n="nav_links.tiers.top_level_shortened"
                        />
                      )
                    }
                  </LargeDesktop>
                ) : (
                  <Mobile>
                    <Text
                      lh={newHeader ? 2 : null}
                      fs={newHeader ? 14 : null}
                      i18n="left_nav.top_level_upgrade"
                    />
                  </Mobile>
                )}
              </Button>
            )}
            {this.renderNotificationIndicator()}
          </Box>
        )}
      </MediaQuery>
    );
  }
}
