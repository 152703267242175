import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withCurrentRoute from 'components/hocs/withCurrentRoute';
import Text from 'components/Text';

import cxHelpers from 'util/className';

import withNavItemData from '../../hocs/withNavItemData';
import NavItem from '../NavItem';

@withCurrentRoute
@withNavItemData('webTimeclock')
@cxHelpers('WebTimeclockNavItem')
export default class WebTimeclockNavItem extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    currentRoute: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
  };

  // Opens in browser tab if cmd+click; otherwise, renders without full refresh
  handleClick = e => {
    if (!e.metaKey) {
      e.preventDefault();
      window.Homebase.RailsReactBridge.navigateToReactView(this.props.url);
      return false;
    }
  };

  render() {
    const { url, currentRoute, ...props } = this.props;

    return (
      <NavItem
        {...props}
        href={this.props.url}
        onClick={this.handleClick}
        active={currentRoute === url}
        className={this.cx()}
      >
        <Text fs14 i18n="nav_links.time_clock" />
      </NavItem>
    );
  }
}
