import { Map } from 'immutable';
import { createSelector } from 'reselect';

import {
  getFeatureMinTiers,
  getHasFeature,
  getRepackagingEnabled,
} from 'selectors/session';
import { getLocationEmployees } from 'selectors/timesheets';

import { capitalizeFirstLetter } from 'util/formatter';

const buildGeneralSelector = key => state =>
  state.getIn(['timecardModal', key]);

export const getShifts = buildGeneralSelector('shifts');
export const getMandatedBreaks = buildGeneralSelector('mandatedBreaks');
export const getLocationRoles = buildGeneralSelector('roles');
export const getChangedShiftId = buildGeneralSelector('changedShiftId');
export const getCurrentShiftId = buildGeneralSelector('currentShiftId');
export const getHideTipsSection = buildGeneralSelector('hideTipsSection');
export const getIsSubmitting = buildGeneralSelector('isSubmitting');
export const getEditMode = buildGeneralSelector('editMode');
export const getStartingPath = buildGeneralSelector('startingPath');
export const getExpandBreaks = buildGeneralSelector('expandBreaks');
export const getChangesMade = buildGeneralSelector('changesMade');

export const getValidationErrors = state =>
  buildGeneralSelector('validationErrors')(state)?.toJS();

export const getCurrentShift = createSelector(
  getShifts,
  getCurrentShiftId,
  (shifts, currentShiftId) =>
    shifts.find(shift => shift.get('id') === currentShiftId) ||
    Map({
      id: currentShiftId,
      date: shifts.get(0) && shifts.get(0).get('date'),
      actual_start_at: shifts.get(0) && shifts.get(0).get('actual_start_at'),
      actual_end_at: shifts.get(0) && shifts.get(0).get('actual_end_at'),
    })
);

export const getEditableShift = buildGeneralSelector('editableShift');

export const getIsOpen = buildGeneralSelector('isOpen');
export const getIsLoading = buildGeneralSelector('isLoading');

export const getHasTimecard = createSelector(
  getCurrentShift,
  shift => !!shift.get('actual_start_at')
);

export const getEmployeeOptions = createSelector(
  getLocationEmployees,
  employees =>
    employees
      .map(e => ({ value: e.get('job_id'), label: e.get('full_name') }))
      .toArray()
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      )
);

export const getRoleOptions = createSelector(
  getLocationRoles,
  (state, roleId) => roleId,
  (roles, roleId) =>
    roles
      ? roles
          .filter(role => roleId === role.get('id') || !role.get('archived_at'))
          .map(role => ({
            value: role.get('id'),
            label: role.get('name'),
          }))
          .toArray()
      : []
);

export const getGpsPhotosEnabled = state =>
  getHasFeature(state, { feature: 'mobile_gps_photos' });

export const getTimecardNotesEnabled = createSelector(
  state => getHasFeature(state, { feature: 'timecard_notes' }),
  getRepackagingEnabled,
  (hasFeature, repackagingEnabled) => (repackagingEnabled ? hasFeature : true)
);

export const getMinTierNameForTimecardNotesFeature = createSelector(
  state => getFeatureMinTiers(state).get('timecard_notes'),
  tierName => capitalizeFirstLetter(tierName)
);
