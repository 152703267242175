import ai from '!!sass-variable-loader!./_ai.scss';

import baseColors from './colors';

// These styles here do no include border radius which needs to defined specifically
export const aiAfterStyles = {
  content: '""',
  position: ai.position,
  inset: ai.afterInset,
  zIndex: ai.afterZIndex,
  backgroundImage: `conic-gradient(
        from var(--angle),
        ${baseColors.purple500},
        ${baseColors.purple500},
        ${baseColors.accent500},
        ${baseColors.accent500},
        ${baseColors.highlight500},
        ${baseColors.accent500},
        ${baseColors.purple500}
      )`,
};

export const aiBeforeStyles = {
  content: '""',
  position: ai.position,
  inset: ai.beforeInset,
  zIndex: ai.beforeZIndex,
};

export const aiAnimatingStyles = {
  animation: `${ai.animatingSpeed} spin linear infinite`,
  '@keyframes spin': {
    from: {
      '--angle': '0deg',
    },
    to: {
      '--angle': '360deg',
    },
  },
};
