import { fromJS, Map } from 'immutable';

import { actionTypes as employeeViewActionTypes } from 'actions/employeeView';
import { actionTypes as hiringActionTypes } from 'actions/hiring';
import { actionTypes as notificationActionTypes } from 'actions/notifications';
import { actionTypes as scheduleBuilderActionTypes } from 'actions/scheduleBuilder';
import { actionTypes as sessionActionTypes } from 'actions/session';
import { actionTypes as settingsActionTypes } from 'actions/settings';
import { actionTypes as timesheetsActionTypes } from 'actions/timesheets';

const INITIAL_STATE = fromJS({
  currentUser: {},
  currentApplicant: {},
  currentLocation: {},
  permissions: {},
  standardizedRoles: [],
  states: [],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case sessionActionTypes.UPDATE_LEFT_NAVIGATION:
      return state.setIn(['nav', 'left_nav_menu'], fromJS(action.payload.data));

    case sessionActionTypes.FETCH_PARTNER_EMPLOYEES_SUCCESS:
      return state.set('partnerJobPermissions', fromJS(action.payload));

    case timesheetsActionTypes.UPDATE_LOCATION_ONBOARDING_PROPERTIES_SUCCESS:
      return state.mergeDeepIn(['currentLocation'], action.meta);

    case sessionActionTypes.UPDATE_CURRENT_COMPANY:
      return state.mergeIn(['currentCompany'], action.payload);

    case sessionActionTypes.UPDATE_CURRENT_LOCATION: {
      const newPermissionRoles = state.getIn(['permissions', 'role']).toJS();
      let newState = state;

      // Update role permissions that are within session and used in other parts of the app
      // This change ensures that when we navigate to another react page that we have
      // updated permission if we update permissions on the team page and
      // then navigate to another react page
      if (
        !state.getIn(['currentUser', 'is_owner']) &&
        state.getIn(['currentUser', 'is_managerial'])
      ) {
        Object.keys(newPermissionRoles).forEach(permissionKey => {
          const managerType = state.getIn(['currentUser', 'is_gm'])
            ? 'gm'
            : 'manager';
          newPermissionRoles[permissionKey] =
            action.payload[`${managerType}_${permissionKey}`] !== '0';
        });

        newState = state.setIn(
          ['permissions', 'role'],
          fromJS(newPermissionRoles)
        );
      }

      return newState.mergeIn(['currentLocation'], {
        ...action.payload,
        messaging_enabled:
          action.payload.company_attributes &&
          action.payload.company_attributes?.messaging_enabled === '1',
      });
    }

    case sessionActionTypes.CREATE_MONETIZATION_CTA_EVENT_SUCCESS:
    case sessionActionTypes.UPDATE_FEATURE_FLAGS:
      return state.mergeIn(['featureFlags'], action.payload);
    case sessionActionTypes.UPDATE_SECURITY_MFA_DETAILS:
      return state.mergeIn(['currentUser'], action.payload);
    case sessionActionTypes.UPDATE_PAYROLL_PAYMENT_ATTEMPTS_INFO:
      return state.mergeIn(['payroll', 'paymentAttemptsInfo'], action.payload);
    case sessionActionTypes.UPDATE_PREDICTED_FAILURE_WIRE_INTENTION_SUCCESS:
      return state.setIn(
        ['payroll', 'company', 'predictedFailureWireIntention'],
        true
      );
    case sessionActionTypes.UPDATE_PREDICTED_REQUIREMENT_SUCCESS:
      return state.setIn(
        ['payroll', 'company', 'predictedFundingFailureRequirement'],
        null
      );

    case scheduleBuilderActionTypes.PUBLISH_SCHEDULE_SUCCESS:
      return state.mergeIn(['featureFlags'], {
        showAddShiftNotesReminder: action.payload.showAddShiftNotesReminder,
        showSmsTooltip: action.payload.showSmsTooltip,
      });

    case sessionActionTypes.UPDATE_SESSION:
      return state.merge(fromJS(action.payload));

    case 'signUpV2/createOwner/fulfilled':
    case sessionActionTypes.UPDATE_EXPERIMENTS:
      return state.mergeIn(['experiments'], fromJS(action.payload));

    case sessionActionTypes.DISABLE_FIRST_TIME_POPUP_REQUEST:
      return state.setIn(
        ['currentUser', 'first_time_popups', action.meta.key],
        'true'
      );

    case sessionActionTypes.DISABLE_APPLICANT_FIRST_TIME_POPUP_REQUEST:
      return state.setIn(
        ['currentApplicant', 'first_time_popups', action.meta.key],
        'true'
      );

    case settingsActionTypes.CREATE_API_KEY_SUCCESS:
      return state.setIn(
        ['currentCompany', 'oauth_access_token'],
        fromJS(action.payload)
      );

    case sessionActionTypes.SET_COMPANY_ADDS_EMPLOYEES_MANUALLY:
      return state.setIn(['currentCompany', 'addsEmployeesManually'], true);

    case settingsActionTypes.ADP_CREDIT_TIPS_TYPE_UPDATE_SUCCESS:
      return state.setIn(
        ['currentCompany', 'adp_properties', 'credit_tips_type'],
        action.meta.type
      );

    case settingsActionTypes.UPDATE_CURRENT_USER_SUCCESS:
      return state.mergeIn(['currentUser'], action.meta.formData);

    case settingsActionTypes.FETCH_USER_SETTINGS_DATA_SUCCESS:
      return state.mergeIn(['currentUser'], action.payload);

    case settingsActionTypes.SAVE_LOCATION_SETTINGS_SUCCESS:
      return state.mergeIn(
        ['currentLocation', 'start_of_week'],
        action.payload.start_of_week
      );

    case settingsActionTypes.FETCH_SETTINGS_TAX_SETUP_DATA:
      return state.setIn(
        ['currentLocation', 'tax_setup_component'],
        new Map(Object.entries(action.payload.data))
      );

    case sessionActionTypes.SET_ANNOUNCEMENT_CAN_SHOW_TO_FALSE:
      return state.mergeIn(
        ['currentUser', 'announcements', action.payload, 'can_show'],
        false
      );

    case sessionActionTypes.REFRESH_ANNOUNCEMENTS_TARGET_PATH_MATCH: {
      // For a predetermined set of Announcements which match the browser's current path,
      // set their `target_path_match` value to `true` in Redux. Set the rest to `false`.
      const existingAnnouncements = state.getIn([
        'currentUser',
        'announcements',
      ]);

      if (existingAnnouncements) {
        const updatedAnnouncements = existingAnnouncements.map(announcement => {
          const matchingAnnouncements = action.payload || Map();
          const targetPathMatch =
            !!matchingAnnouncements.includes(announcement);

          return announcement.merge({ target_path_match: targetPathMatch });
        });

        return state.mergeIn(
          ['currentUser', 'announcements'],
          updatedAnnouncements
        );
      }

      return state;
    }

    case settingsActionTypes.FETCH_TIME_ZONES_SUCCESS:
      return state.set('timeZones', fromJS(action.payload.timeZones));

    case hiringActionTypes.CREATE_JOB_POST_SUCCESS:
      return state.setIn(['currentCompany', 'companyHasJobRequests'], true);

    case notificationActionTypes.DISMISS_SHIFT_TRADE_UPSELL:
      return state.setIn(
        ['currentUser', 'showShiftTradeUpsellInterrupt'],
        false
      );

    case sessionActionTypes.CLOSE_PASSWORD_RESET_MODAL:
      return state.setIn(['session', 'showPasswordResetModal'], false);

    case sessionActionTypes.SET_LAST_PASSWORD_RESET:
      return state.setIn(
        ['currentUser', 'last_password_reset_at'],
        new Date().toISOString()
      );

    case sessionActionTypes.COMPLETE_COMPANY_ONE_TIME_EVENT: {
      const companyOneTimeEvents = state.getIn([
        'featureFlags',
        'companyOneTimeEvents',
      ]);
      return state
        .set('showScrim', false)
        .mergeIn(
          ['featureFlags', 'companyOneTimeEvents'],
          (companyOneTimeEvents || []).push(action.payload.eventName)
        );
    }

    case sessionActionTypes.COMPLETE_USER_ONE_TIME_EVENT: {
      const userOneTimeEvents = state.getIn([
        'featureFlags',
        'companyOneTimeEvents',
      ]);
      return state.mergeIn(
        ['featureFlags', 'userOneTimeEvents'],
        (userOneTimeEvents || []).push(action.payload.eventName)
      );
    }

    case sessionActionTypes.COMPLETE_LOCATION_ONE_TIME_EVENT: {
      const locationOneTimeEvents = state.getIn([
        'featureFlags',
        'locationOneTimeEvents',
      ]);
      return state.mergeIn(
        ['featureFlags', 'locationOneTimeEvents'],
        (locationOneTimeEvents || []).push(action.payload.eventName)
      );
    }

    case sessionActionTypes.CREATE_ONE_TIME_EVENT_SUCCESS: {
      const entity = action.payload.owner_type;
      const oneTimeEvents =
        state.getIn(['featureFlags', `${entity}OneTimeEvents`]) || fromJS([]);
      return state.setIn(
        ['featureFlags', `${entity}OneTimeEvents`],
        oneTimeEvents.push(action.payload.event_name)
      );
    }

    case sessionActionTypes.FORCE_SHOW_SCRIM:
      return state.set('showScrim', true);

    case sessionActionTypes.UPDATE_COMPANY_PAYROLL_STATE:
      return state.setIn(['payroll', 'companyState'], action.payload);

    case sessionActionTypes.UPDATE_COMPANY_ELIGIBLE_FOR_MULTI_LOCATION_PAYROLL:
      return state.setIn(
        ['payroll', 'companyEligibleForMultiLocationPayroll'],
        action.payload
      );

    case sessionActionTypes.UPDATE_GOOGLE_API_SCRIPT_LOADED:
      return state.set('isGoogleMapsApiLoaded', true);

    case sessionActionTypes.UPDATE_PROVIDER_INFO:
      return state.setIn(
        ['currentLocation', 'payroll_provider'],
        new Map(action.payload)
      );

    case sessionActionTypes.TOGGLE_HEALTH_QUESTIONS_ENABLED:
      return state.setIn(
        ['featureFlags', 'isHealthQuestionsEnabled'],
        action.payload.show
      );

    case employeeViewActionTypes.SIGN_I9_SUCCESS:
      return state.setIn(
        ['currentUser', 'signature'],
        fromJS(action.payload.signature)
      );

    case 'bulkTerminate/terminateEmployees/fulfilled':
    case employeeViewActionTypes.TERMINATION_FORM_SUCCESS: {
      const oldUsersCount = state.get('activeUsersCount');
      const usersArchived = !!action.payload.archived_jobs
        ? Object.keys(action.payload.archived_jobs).length
        : 1;

      return state.set('activeUsersCount', oldUsersCount - usersArchived);
    }

    case sessionActionTypes.UPDATE_PAYROLL_FLAGS: {
      return state.setIn(
        ['payroll_flags', action.payload.key],
        action.payload.data
      );
    }

    case sessionActionTypes.UPDATE_PAYROLL_QUIZ_BANNER:
      return state.setIn(['payroll', 'showPayrollQuizBanner'], action.payload);

    case sessionActionTypes.UPDATE_AUTO_PAYROLL_FEATURE_ENABLED:
      return state.setIn(
        ['featureFlags', 'isAutoPayrollFeatureEnabled'],
        action.payload
      );

    case sessionActionTypes.UPDATE_ACTIVE_USERS_COUNT: {
      const oldUsersCount = state.get('activeUsersCount');
      return state.set('activeUsersCount', oldUsersCount + 1);
    }

    case sessionActionTypes.SET_ACTIVE_TIP_POLICY_ID:
      return state.setIn(
        ['currentLocation', 'active_tip_policy_id'],
        action.payload
      );

    default:
      return state;
  }
};
