import { createAsyncThunk } from '@reduxjs/toolkit';

import { createOneTimeEvent } from 'actions/session';

import {
  fetchListData,
  patchUpdateLocationSettings,
  putUpdateUserSettings,
} from './api';
import { SLICE_NAME } from './constants';

interface SettingsPayload {
  [key: string]: any;
}

interface LocationSettingsPayload extends SettingsPayload {
  locationId: number;
  completionEventName?: string;
  setSubmitting: (isSubmitting: boolean) => void;
}

interface UserSettingsPayload extends SettingsPayload {
  completionEventName?: string;
  setSubmitting: (isSubmitting: boolean) => void;
}

interface ErrorResponse {
  body?: any;
  message: string;
}

export const getListData = createAsyncThunk(
  `${SLICE_NAME}/listData`,
  async (_, { rejectWithValue }) => {
    try {
      return await fetchListData();
    } catch (error) {
      const err = error as ErrorResponse;
      return rejectWithValue(err.body || err.message);
    }
  }
);

export const updateLocationSettings = createAsyncThunk(
  `${SLICE_NAME}/updateLocationSettings`,
  async (
    {
      completionEventName,
      setSubmitting,
      ...settings
    }: LocationSettingsPayload,
    { dispatch, rejectWithValue }
  ) => {
    try {
      await patchUpdateLocationSettings(settings);
      if (completionEventName) {
        dispatch(createOneTimeEvent(completionEventName) as any);
      }
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      const err = error as ErrorResponse;
      return rejectWithValue({ message: err.body || err.message });
    }
  }
);

export const updateUserSettings = createAsyncThunk(
  `${SLICE_NAME}/updateUserSettings`,
  async (
    { completionEventName, setSubmitting, ...settings }: UserSettingsPayload,
    { dispatch, rejectWithValue }
  ) => {
    try {
      await putUpdateUserSettings(settings);
      if (completionEventName) {
        dispatch(createOneTimeEvent(completionEventName) as any);
      }
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      const err = error as ErrorResponse;
      return rejectWithValue({ message: err.body || err.message });
    }
  }
);
