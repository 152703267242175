import { ReactNode, useCallback, useEffect, useId, useMemo } from 'react';
import MUIDialog from '@mui/material/Dialog';
import withCx, { CxProps } from 'fe-core/util/withCx';
import { Context } from 'fe-core/UxTracking/types';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import ActionButtons, {
  ActionButtonsProps,
} from 'fe-design-base/molecules/ActionButtons';
import IconButton from 'fe-design-base/molecules/IconButton';
import { useIsMediumScreen } from 'fe-design-base/utils/useMediaQuery';

import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx, withUxPartition } from 'util/uxEvents';

interface DialogProps extends ActionButtonsProps {
  open: boolean;
  className?: string;
  title?: string;
  width?: number | string;
  bgcolor?: string;
  noPadding?: boolean;
  hideCloseIcon?: boolean;
  disableBackdropClick?: boolean;
  fullScreen?: boolean;
  onClose: (event: object, reason: string) => void;
  children: (close: (event: object, reason: string) => void) => ReactNode;
  uxElement?: string;
  uxContext?: Context;
}

const Dialog = ({
  className,
  open,
  title,
  width = 'fit-content',
  noPadding,
  hideCloseIcon,
  onClose,
  disableBackdropClick,
  fullScreen,
  bgcolor,
  children,
  uxElement,
  uxContext,
  cx,
  cxEl,
  ...actionButtonsProps
}: DialogProps & CxProps) => {
  const trackUx = useTrackUx(
    useMemo(
      () => ({
        element: uxElement,
      }),
      [uxElement]
    ) as any
  );

  const handleClose = useCallback(
    // TODO: DB https://joinhomebase.atlassian.net/browse/FE-2199
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (event, reason) => {
      if (hideCloseIcon && reason === 'escapeKeyDown') return;
      if (disableBackdropClick && reason === 'backdropClick') return;

      onClose?.(event, reason);
    },
    [disableBackdropClick, hideCloseIcon, onClose]
  );

  const handleCloseClick = useCallback(
    // TODO: DB https://joinhomebase.atlassian.net/browse/FE-2199
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    e => {
      if (uxElement)
        trackUx(
          EVENT_ACTIONS.DISMISS_CLICKED,
          TRACK_ACTION_TYPES.CLICK,
          uxContext
        );
      onClose(e, 'closeButtonClick');
    },
    [onClose, trackUx, uxContext, uxElement]
  );

  useEffect(() => {
    if (open && uxElement)
      trackUx(EVENT_ACTIONS.MODAL_VIEWED, TRACK_ACTION_TYPES.VIEW, uxContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, uxElement]);

  const titleId: string | undefined = useId();
  const descriptionId: string | undefined = useId();
  const isFullScreen: boolean | undefined = !useIsMediumScreen() || fullScreen;
  const paperProps = useMemo(() => {
    if (isFullScreen || !width) return {};

    return {
      sx: {
        width,
      },
    };
  }, [isFullScreen, width]);

  const renderChildren = () => {
    if (!uxContext) {
      return children(onClose);
    }

    const Content = withUxPartition(uxContext)(() => children(onClose));
    return <Content />;
  };

  const renderActionButtons = () => {
    if (!uxContext) {
      return <ActionButtons {...actionButtonsProps} />;
    }

    const Buttons = withUxPartition(uxContext)(ActionButtons);
    return <Buttons {...actionButtonsProps} />;
  };

  return (
    <MUIDialog
      className={`${cx()}${className ? ` ${className}` : ''}`}
      open={open}
      onClose={handleClose}
      aria-labelledby={titleId}
      aria-describedby={descriptionId}
      fullScreen={isFullScreen}
      PaperProps={paperProps}
    >
      {open && (
        <Box
          p={noPadding ? 0 : 32}
          h={isFullScreen ? '100%' : undefined}
          bgcolor={bgcolor}
        >
          {!hideCloseIcon && (
            <Box absolute top={8} right={8}>
              <IconButton
                onClick={handleCloseClick}
                icon="Close"
                size="small"
                dataTestId="FDBDialog-close-icon"
              />
            </Box>
          )}
          {title && (
            <Box mt={32} mb={24}>
              <Text id={titleId} variant="heading2">
                {title}
              </Text>
            </Box>
          )}
          <div id={descriptionId}>{renderChildren()}</div>
          {actionButtonsProps.buttons && (
            <Box mt={36}>{renderActionButtons()}</Box>
          )}
        </Box>
      )}
    </MUIDialog>
  );
};

export default withCx<DialogProps>('FDBDialog')(Dialog);
