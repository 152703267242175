import * as routes from 'api';

import { showArchiveApplicantsModal } from 'actions/modals';

import * as entitiesSelectors from 'selectors/entities';
import * as hiringSelectors from 'selectors/hiring';

import {
  ADD_APPLICANT_MANUALLY_KEY,
  APPLICATION_NOTES_DRAWER_KEY,
  HIRING_PAGE_TIPS_DRAWER_KEY,
  MANAGE_APPLICANTS_ZERO_STATE_DRAWER_KEY,
} from 'features/drawers/keys';
import * as hiringUtil from 'features/hiring/util';

import * as flashNotice from 'util/flashNotice';
import { buildFormActionTypes } from 'util/forms';
import { toI18n } from 'util/i18n';
import {
  createAsyncDeleteAction,
  createAsyncGetAction,
  createAsyncPatchAction,
  createAsyncPostAction,
  createAsyncPutAction,
  withAlerts,
} from 'util/redux';
import { browserHistory } from 'util/router';
import { checkForServerErrors } from 'util/serverError';
import {
  EVENT_NAMES,
  logHiringEvent,
  MISC_EVENT_VALUES,
  trackEvent,
} from 'util/tracking';

const { UPDATE_FIELD: UPDATE_COMPANY_PROFILE_FORM_FIELD } =
  buildFormActionTypes('COMPANY_PROFILE_FORM');

export const actionTypes = {
  UPDATE_COMPANY_PROFILE_FORM_FIELD,
  FETCH_DASHBOARD_DATA_REQUEST: 'HIRING/FETCH_DASHBOARD_DATA_REQUEST',
  FETCH_DASHBOARD_DATA_SUCCESS: 'HIRING/FETCH_DASHBOARD_DATA_SUCCESS',
  FETCH_DASHBOARD_DATA_FAILURE: 'HIRING/FETCH_DASHBOARD_DATA_FAILURE',
  ARCHIVE_JOB_REQUEST_REQUEST: 'HIRING/ARCHIVE_JOB_REQUEST_REQUEST',
  ARCHIVE_JOB_REQUEST_SUCCESS: 'HIRING/ARCHIVE_JOB_REQUEST_SUCCESS',
  ARCHIVE_JOB_REQUEST_FAILURE: 'HIRING/ARCHIVE_JOB_REQUEST_FAILURE',
  REPOST_JOB_REQUEST_REQUEST: 'HIRING/REPOST_JOB_REQUEST_REQUEST',
  REPOST_JOB_REQUEST_SUCCESS: 'HIRING/REPOST_JOB_REQUEST_SUCCESS',
  REPOST_JOB_REQUEST_FAILURE: 'HIRING/REPOST_JOB_REQUEST_FAILURE',
  ARCHIVE_LOCATION_PROFILE_REQUEST: 'HIRING/ARCHIVE_LOCATION_PROFILE_REQUEST',
  ARCHIVE_LOCATION_PROFILE_SUCCESS: 'HIRING/ARCHIVE_LOCATION_PROFILE_SUCCESS',
  ARCHIVE_LOCATION_PROFILE_FAILURE: 'HIRING/ARCHIVE_LOCATION_PROFILE_FAILURE',
  REPOST_LOCATION_PROFILE_REQUEST: 'HIRING/REPOST_LOCATION_PROFILE_REQUEST',
  REPOST_LOCATION_PROFILE_SUCCESS: 'HIRING/REPOST_LOCATION_PROFILE_SUCCESS',
  REPOST_LOCATION_PROFILE_FAILURE: 'HIRING/REPOST_LOCATION_PROFILE_FAILURE',
  FETCH_PROMOTE_DATA_REQUEST: 'HIRING/FETCH_PROMOTE_DATA_REQUEST',
  FETCH_PROMOTE_DATA_SUCCESS: 'HIRING/FETCH_PROMOTE_DATA_SUCCESS',
  FETCH_PROMOTE_DATA_FAILURE: 'HIRING/FETCH_PROMOTE_DATA_FAILURE',
  FETCH_COMPANY_PROFILE_REQUEST: 'HIRING/FETCH_COMPANY_PROFILE_REQUEST',
  FETCH_COMPANY_PROFILE_SUCCESS: 'HIRING/FETCH_COMPANY_PROFILE_SUCCESS',
  UPDATE_JOB_REQUEST_POSTED_AT: 'HIRING/UPDATE_JOB_REQUEST_POSTED_AT',
  FETCH_COMPANY_PROFILE_FAILURE: 'HIRING/FETCH_COMPANY_PROFILE_FAILURE',
  UPDATE_COMPANY_PROFILE_REQUEST: 'HIRING/UPDATE_COMPANY_PROFILE_REQUEST',
  UPDATE_COMPANY_PROFILE_SUCCESS: 'HIRING/UPDATE_COMPANY_PROFILE_SUCCESS',
  UPDATE_COMPANY_PROFILE_FAILURE: 'HIRING/UPDATE_COMPANY_PROFILE_FAILURE',
  TOGGLE_EDITING_COMPANY_PROFILE_FORM:
    'HIRING/TOGGLE_EDITING_COMPANY_PROFILE_FORM',
  SUBMIT_JOB_REQUEST_APPLICATION_FORM_REQUEST:
    'HIRING/SUBMIT_JOB_REQUEST_APPLICATION_FORM_REQUEST',
  SUBMIT_JOB_REQUEST_APPLICATION_FORM_SUCCESS:
    'HIRING/SUBMIT_JOB_REQUEST_APPLICATION_FORM_SUCCESS',
  SUBMIT_JOB_REQUEST_APPLICATION_FORM_FAILURE:
    'HIRING/SUBMIT_JOB_REQUEST_APPLICATION_FORM_FAILURE',
  SUBMIT_LOCATION_APPLICATION_FORM_REQUEST:
    'HIRING/SUBMIT_LOCATION_APPLICATION_FORM_REQUEST',
  SUBMIT_LOCATION_APPLICATION_FORM_SUCCESS:
    'HIRING/SUBMIT_LOCATION_APPLICATION_FORM_SUCCESS',
  SUBMIT_LOCATION_APPLICATION_FORM_FAILURE:
    'HIRING/SUBMIT_LOCATION_APPLICATION_FORM_FAILURE',
  FETCH_INITIAL_MANAGE_APPLICANTS_REQUEST:
    'HIRING/FETCH_INITIAL_MANAGE_APPLICANTS_REQUEST',
  FETCH_INITIAL_MANAGE_APPLICANTS_SUCCESS:
    'HIRING/FETCH_INITIAL_MANAGE_APPLICANTS_SUCCESS',
  FETCH_INITIAL_MANAGE_APPLICANTS_FAILURE:
    'HIRING/FETCH_INITIAL_MANAGE_APPLICANTS_FAILURE',
  FETCH_APPLICANT_DATA_REQUEST: 'HIRING/FETCH_APPLICANT_DATA_REQUEST',
  FETCH_APPLICANT_DATA_SUCCESS: 'HIRING/FETCH_APPLICANT_DATA_SUCCESS',
  FETCH_APPLICANT_DATA_FAILURE: 'HIRING/FETCH_APPLICANT_DATA_FAILURE',
  TOGGLE_APPLICATION_LIKED_REQUEST: 'HIRING/TOGGLE_APPLICATION_LIKED_REQUEST',
  TOGGLE_APPLICATION_LIKED_SUCCESS: 'HIRING/TOGGLE_APPLICATION_LIKED_SUCCESS',
  TOGGLE_APPLICATION_LIKED_FAILURE: 'HIRING/TOGGLE_APPLICATION_LIKED_FAILURE',
  PASS_APPLICATION_REQUEST: 'HIRING/PASS_APPLICATION_REQUEST',
  PASS_APPLICATION_SUCCESS: 'HIRING/PASS_APPLICATION_SUCCESS',
  PASS_APPLICATION_FAILURE: 'HIRING/PASS_APPLICATION_FAILURE',
  UNPASS_APPLICATION_REQUEST: 'HIRING/UNPASS_APPLICATION_REQUEST',
  UNPASS_APPLICATION_SUCCESS: 'HIRING/UNPASS_APPLICATION_SUCCESS',
  UNPASS_APPLICATION_FAILURE: 'HIRING/UNPASS_APPLICATION_FAILURE',
  ARCHIVE_APPLICATIONS_REQUEST: 'HIRING/ARCHIVE_APPLICATIONS_REQUEST',
  ARCHIVE_APPLICATIONS_SUCCESS: 'HIRING/ARCHIVE_APPLICATIONS_SUCCESS',
  ARCHIVE_APPLICATIONS_FAILURE: 'HIRING/ARCHIVE_APPLICATIONS_FAILURE',
  HIRE_APPLICATION_REQUEST: 'HIRING/HIRE_APPLICATION_REQUEST',
  HIRE_APPLICATION_SUCCESS: 'HIRING/HIRE_APPLICATION_SUCCESS',
  HIRE_APPLICATION_FAILURE: 'HIRING/HIRE_APPLICATION_FAILURE',
  FETCH_APPLICANT_PROFILE_REQUEST: 'HIRING/FETCH_APPLICANT_PROFILE_REQUEST',
  FETCH_APPLICANT_PROFILE_SUCCESS: 'HIRING/FETCH_APPLICANT_PROFILE_SUCCESS',
  FETCH_APPLICANT_PROFILE_FAILURE: 'HIRING/FETCH_APPLICANT_PROFILE_FAILURE',
  TOGGLE_EDITING_APPLICANT_PROFILE: 'HIRING/TOGGLE_EDITING_APPLICANT_PROFILE',
  SUBMIT_APPLICANT_PROFILE_REQUEST: 'HIRING/SUBMIT_APPLICANT_PROFILE_REQUEST',
  SUBMIT_APPLICANT_PROFILE_SUCCESS: 'HIRING/SUBMIT_APPLICANT_PROFILE_SUCCESS',
  SUBMIT_APPLICANT_PROFILE_FAILURE: 'HIRING/SUBMIT_APPLICANT_PROFILE_FAILURE',
  FETCH_STANDARDIZED_ROLES_REQUEST: 'HIRING/FETCH_STANDARDIZED_ROLES_REQUEST',
  FETCH_STANDARDIZED_ROLES_SUCCESS: 'HIRING/FETCH_STANDARDIZED_ROLES_SUCCESS',
  FETCH_STANDARDIZED_ROLES_FAILURE: 'HIRING/FETCH_STANDARDIZED_ROLES_FAILURE',
  CREATE_JOB_POST_REQUEST: 'HIRING/CREATE_JOB_POST_REQUEST',
  CREATE_JOB_POST_SUCCESS: 'HIRING/CREATE_JOB_POST_SUCCESS',
  CREATE_JOB_POST_FAILURE: 'HIRING/CREATE_JOB_POST_FAILURE',
  UPDATE_JOB_POST_REQUEST: 'HIRING/UPDATE_JOB_POST_REQUEST',
  UPDATE_JOB_POST_SUCCESS: 'HIRING/UPDATE_JOB_POST_SUCCESS',
  UPDATE_JOB_POST_FAILURE: 'HIRING/UPDATE_JOB_POST_FAILURE',
  CREATE_JOB_POST_BOOST_REQUEST: 'HIRING/CREATE_JOB_POST_BOOST_REQUEST',
  CREATE_JOB_POST_BOOST_SUCCESS: 'HIRING/CREATE_JOB_POST_BOOST_SUCCESS',
  CREATE_JOB_POST_BOOST_FAILURE: 'HIRING/CREATE_JOB_POST_BOOST_FAILURE',
  BOOK_INTERVIEW_REQUEST: 'HIRING/BOOK_INTERVIEW_REQUEST',
  BOOK_INTERVIEW_SUCCESS: 'HIRING/BOOK_INTERVIEW_SUCCESS',
  BOOK_INTERVIEW_FAILURE: 'HIRING/BOOK_INTERVIEW_FAILURE',
  SUBMIT_INTERVIEW_DETAILS_REQUEST: 'HIRING/SUBMIT_INTERVIEW_DETAILS_REQUEST',
  SUBMIT_INTERVIEW_DETAILS_SUCCESS: 'HIRING/SUBMIT_INTERVIEW_DETAILS_SUCCESS',
  SUBMIT_INTERVIEW_DETAILS_FAILURE: 'HIRING/SUBMIT_INTERVIEW_DETAILS_FAILURE',
  OPEN_HIRE_MODAL: 'HIRING/OPEN_HIRE_MODAL',
  CLOSE_HIRE_MODAL: 'HIRING/CLOSE_HIRE_MODAL',
  CREATE_JOB_APPLICATION_NOTE_REQUEST:
    'HIRING/CREATE_JOB_APPLICATION_NOTE_REQUEST',
  CREATE_JOB_APPLICATION_NOTE_SUCCESS:
    'HIRING/CREATE_JOB_APPLICATION_NOTE_SUCCESS',
  CREATE_JOB_APPLICATION_NOTE_FAILURE:
    'HIRING/CREATE_JOB_APPLICATION_NOTE_FAILURE',
  DELETE_JOB_APPLICATION_NOTE_REQUEST:
    'HIRING/DELETE_JOB_APPLICATION_NOTE_REQUEST',
  DELETE_JOB_APPLICATION_NOTE_SUCCESS:
    'HIRING/DELETE_JOB_APPLICATION_NOTE_SUCCESS',
  DELETE_JOB_APPLICATION_NOTE_FAILURE:
    'HIRING/DELETE_JOB_APPLICATION_NOTE_FAILURE',
  SUBMIT_WHITELISTING_WIDGET_REQUEST:
    'HIRING/SUBMIT_WHITELISTING_WIDGET_REQUEST',
  SUBMIT_WHITELISTING_WIDGET_SUCCESS:
    'HIRING/SUBMIT_WHITELISTING_WIDGET_SUCCESS',
  SUBMIT_WHITELISTING_WIDGET_FAILURE:
    'HIRING/SUBMIT_WHITELISTING_WIDGET_FAILURE',
  TOGGLE_APPLICATION_NOTES_DRAWER_OPEN:
    'HIRING/TOGGLE_APPLICATION_NOTES_DRAWER_OPEN',
  TOGGLE_HIRING_DASHBOARD_PAGE_TIPS_DRAWER_OPEN:
    'HIRING/TOGGLE_HIRING_DASHBOARD_PAGE_TIPS_DRAWER_OPEN',
  TOGGLE_MANAGE_APPLICANTS_ZERO_STATE_DRAWER_OPEN:
    'HIRING/TOGGLE_MANAGE_APPLICANTS_ZERO_STATE_DRAWER_OPEN',
  SET_MOBILE_APPLICANT_PROFILE_TAB_VIEW:
    'HIRING/SET_MOBILE_APPLICANT_PROFILE_TAB_VIEW',
  FETCH_JOB_REQUEST_SUCCESS_DATA_REQUEST:
    'HIRING/FETCH_JOB_REQUEST_SUCCESS_DATA_REQUEST',
  FETCH_JOB_REQUEST_SUCCESS_DATA_SUCCESS:
    'HIRING/FETCH_JOB_REQUEST_SUCCESS_DATA_SUCCESS',
  FETCH_JOB_REQUEST_SUCCESS_DATA_FAILURE:
    'HIRING/FETCH_JOB_REQUEST_SUCCESS_DATA_FAILURE',
  ADD_APPLICANTS_MANUALLY_DRAWER: 'HIRING/ADD_APPLICANTS_MANUALLY_DRAWER',
  SUBMIT_APPLICATION_QUESTIONS_REQUEST:
    'HIRING/SUBMIT_APPLICATION_QUESTIONS_REQUEST',
  SUBMIT_APPLICATION_QUESTIONS_SUCCESS:
    'HIRING/SUBMIT_APPLICATION_QUESTIONS_SUCCESS',
  SUBMIT_APPLICATION_QUESTIONS_FAILURE:
    'HIRING/SUBMIT_APPLICATION_QUESTIONS_FAILURE',
  REQUEST_ADD_APPLICANT_ACCESS_REQUEST:
    'HIRING/ADD_APPLICANT_MANUALLY/REQUEST_ADD_APPLICANT_ACCESS_REQUEST',
  REQUEST_ADD_APPLICANT_ACCESS_FAILURE:
    'HIRING/ADD_APPLICANT_MANUALLY/REQUEST_ADD_APPLICANT_ACCESS_FAILURE',
  REQUEST_ADD_APPLICANT_ACCESS_SUCCESS:
    'HIRING/ADD_APPLICANT_MANUALLY/REQUEST_ADD_APPLICANT_ACCESS_SUCCESS',
  CHECK_STATUS_OF_ABILITY_TO_ADD_APPLICANTS_REQUEST:
    'HIRING/ADD_APPLICANT_MANUALLY/CHECK_STATUS_OF_ABILITY_TO_ADD_APPLICANTS_REQUEST',
  CHECK_STATUS_OF_ABILITY_TO_ADD_APPLICANTS_SUCCESS:
    'HIRING/ADD_APPLICANT_MANUALLY/CHECK_STATUS_OF_ABILITY_TO_ADD_APPLICANTS_SUCCESS',
  CHECK_STATUS_OF_ABILITY_TO_ADD_APPLICANTS_FAILURE:
    'HIRING/ADD_APPLICANT_MANUALLY/CHECK_STATUS_OF_ABILITY_TO_ADD_APPLICANTS_FAILURE',
};

export const fetchInitialData = () =>
  createAsyncGetAction(
    routes.hiringDashboardRoute(),
    [
      actionTypes.FETCH_DASHBOARD_DATA_REQUEST,
      actionTypes.FETCH_DASHBOARD_DATA_SUCCESS,
      actionTypes.FETCH_DASHBOARD_DATA_FAILURE,
    ],
    {
      bailout: state =>
        hiringSelectors.getJobRequestsLoaded(state) ||
        hiringSelectors.getHiringDashboardIsFetching(state),
    }
  );

export const archiveJobRequest = id =>
  withAlerts(
    createAsyncPutAction(routes.archiveJobRequestRoute(id), [
      actionTypes.ARCHIVE_JOB_REQUEST_REQUEST,
      actionTypes.ARCHIVE_JOB_REQUEST_SUCCESS,
      actionTypes.ARCHIVE_JOB_REQUEST_FAILURE,
    ]),
    {
      success: toI18n('hiring.promote.success_alert', {
        props: {
          entity: toI18n('hiring.promote.job'),
          action: toI18n('hiring.promote.deactivated'),
        },
      }),
    }
  );

export const openAddApplicantsManuallyDrawer = (
  defaultRole,
  defaultLocation
) => ({
  type: actionTypes.ADD_APPLICANTS_MANUALLY_DRAWER,
  payload: {
    drawerKey: ADD_APPLICANT_MANUALLY_KEY,
    defaultRole,
    defaultLocation,
    drawerOpen: true,
  },
});

export const repostJobRequestAction = id =>
  createAsyncPatchAction(routes.repostJobRequestRoute(id), [
    { type: actionTypes.REPOST_JOB_REQUEST_REQUEST, meta: { id } },
    actionTypes.REPOST_JOB_REQUEST_SUCCESS,
    actionTypes.REPOST_JOB_REQUEST_FAILURE,
  ]);

export const repostJobRequest =
  (id, unhiredApplicationsGreaterThan21Days, isLocationCard) =>
  async dispatch => {
    const actionResponse = await dispatch(repostJobRequestAction(id));

    if (!actionResponse.error) {
      const job = toI18n('hiring.promote.job');
      const reactivated = toI18n('hiring.promote.reactivated');
      const successAlert = toI18n('hiring.promote.success_alert', {
        props: { entity: job, action: reactivated },
      });

      if (unhiredApplicationsGreaterThan21Days.size > 0) {
        dispatch(
          showArchiveApplicantsModal(
            unhiredApplicationsGreaterThan21Days,
            id,
            isLocationCard
          )
        );
      } else {
        flashNotice.show('notice', successAlert);

        browserHistory.replace({
          pathname: routes.jobRequestSuccessRoute(id),
          state: { fromDashboard: true },
        });
      }

      trackEvent(
        EVENT_NAMES.repostedJobRequest,
        { category: MISC_EVENT_VALUES.hiring },
        { ga: true, fb: true }
      );
    }
  };

export const archiveLocationProfile = id =>
  withAlerts(
    createAsyncPutAction(routes.archiveLocationProfileRoute(id), [
      actionTypes.ARCHIVE_LOCATION_PROFILE_REQUEST,
      actionTypes.ARCHIVE_LOCATION_PROFILE_SUCCESS,
      actionTypes.ARCHIVE_LOCATION_PROFILE_FAILURE,
    ]),
    {
      success: toI18n('hiring.promote.success_alert', {
        props: {
          entity: toI18n('hiring.promote.location'),
          action: toI18n('hiring.promote.deactivated'),
        },
      }),
    }
  );

export const repostLocationProfile =
  (id, unhiredApplicationsGreaterThan21Days, isLocationCard) => dispatch =>
    dispatch(
      withAlerts(
        createAsyncPutAction(routes.repostLocationProfileRoute(id), [
          actionTypes.REPOST_LOCATION_PROFILE_REQUEST,
          actionTypes.REPOST_LOCATION_PROFILE_SUCCESS,
          actionTypes.REPOST_LOCATION_PROFILE_FAILURE,
        ]),
        {
          success:
            unhiredApplicationsGreaterThan21Days.size < 1 &&
            toI18n('hiring.dashboard.location_repost_success'),
          onSuccess: () => {
            if (unhiredApplicationsGreaterThan21Days.size > 0) {
              dispatch(
                showArchiveApplicantsModal(
                  unhiredApplicationsGreaterThan21Days,
                  id,
                  isLocationCard
                )
              );
            }
            trackEvent(
              EVENT_NAMES.repostedLocation,
              { category: MISC_EVENT_VALUES.hiring },
              { ga: true, fb: true }
            );
          },
        }
      )
    );

export const fetchPromoteData = () =>
  createAsyncGetAction(
    routes.hiringJobRequestsPromoteRoute(),
    [
      actionTypes.FETCH_PROMOTE_DATA_REQUEST,
      actionTypes.FETCH_PROMOTE_DATA_SUCCESS,
      actionTypes.FETCH_PROMOTE_DATA_FAILURE,
    ],
    {
      bailout: state =>
        hiringSelectors.getHiringSettingsVerificationComplete(state) !==
        undefined,
    }
  );

export const updateJobRequestPostedAt = (id, postedAt) => ({
  type: actionTypes.UPDATE_JOB_REQUEST_POSTED_AT,
  payload: { id, posted_at: postedAt },
});

export const fetchCompanyProfile = id =>
  createAsyncGetAction(
    routes.companyProfileRoute(id),
    [
      actionTypes.FETCH_COMPANY_PROFILE_REQUEST,
      actionTypes.FETCH_COMPANY_PROFILE_SUCCESS,
      actionTypes.FETCH_COMPANY_PROFILE_FAILURE,
    ],
    {
      bailout: state => hiringSelectors.getCompanyProfilesLoaded(state),
    }
  );

export const fetchApplicantProfile = () => {
  const requestId = 'fetch_applicant_profile';

  return createAsyncGetAction(
    routes.applicantProfileRoute(),
    [
      {
        type: actionTypes.FETCH_APPLICANT_PROFILE_REQUEST,
        meta: { requestId },
      },
      {
        type: actionTypes.FETCH_APPLICANT_PROFILE_SUCCESS,
        meta: { requestId },
      },
      {
        type: actionTypes.FETCH_APPLICANT_PROFILE_FAILURE,
        meta: { requestId },
      },
    ],
    {
      bailout: state => hiringSelectors.getApplicantProfileIsLoaded(state),
    }
  );
};

export const toggleEditingApplicantProfile = isEditing => ({
  type: actionTypes.TOGGLE_EDITING_APPLICANT_PROFILE,
  payload: {
    isEditing,
  },
});

export const submitApplicantProfile = (payload, opts = {}) => {
  const formattedPayload = hiringUtil.formatApplicantProfileForServer(
    payload.form,
    payload.oldApplicant
  );
  const requestId = opts.uploadOnly
    ? 'submit_applicant_profile_upload_only'
    : 'submit_applicant_profile';

  const data = {
    applicant: formattedPayload,
  };

  const success = opts.noMessage
    ? false
    : opts.successMessage ||
      toI18n('hiring.applicant_profile.applicant_panel.save_success');

  return withAlerts(
    createAsyncPutAction(
      routes.applicantProfileApplicantRoute(data),
      [
        {
          type: actionTypes.SUBMIT_APPLICANT_PROFILE_REQUEST,
          meta: { requestId },
        },
        {
          type: actionTypes.SUBMIT_APPLICANT_PROFILE_SUCCESS,
          meta: { requestId },
        },
        {
          type: actionTypes.SUBMIT_APPLICANT_PROFILE_FAILURE,
          meta: { requestId },
        },
      ],
      { body: data }
    ),
    {
      noErrorFlash: true,
      success,
      ...opts,
    }
  );
};

// TODO Still used in one place. Remove?
export const fetchStandardizedRoles = () =>
  createAsyncGetAction(
    routes.standardizedRoles(),
    [
      actionTypes.FETCH_STANDARDIZED_ROLES_REQUEST,
      actionTypes.FETCH_STANDARDIZED_ROLES_SUCCESS,
      actionTypes.FETCH_STANDARDIZED_ROLES_FAILURE,
    ],
    {
      bailout: state => entitiesSelectors.getStandardizedRolesLoaded(state),
    }
  );

export const toggleEditingCompanyProfileForm = (
  isEditing,
  companyProfile,
  locationProfiles
) => ({
  type: actionTypes.TOGGLE_EDITING_COMPANY_PROFILE_FORM,
  payload: {
    isEditing,
    formData: hiringUtil.buildCompanyProfileFormData(
      companyProfile,
      locationProfiles
    ),
  },
});

export const submitCompanyProfileForm =
  companyProfileId => (dispatch, getState) => {
    const fields = hiringSelectors.companyProfileFormSelectors.getFields(
      getState()
    );
    const data = hiringUtil.formatCompanyProfileFormForServer(fields);

    return checkForServerErrors(
      createAsyncPatchAction(
        routes.updateCompanyProfileRoute(companyProfileId),
        [
          actionTypes.UPDATE_COMPANY_PROFILE_REQUEST,
          actionTypes.UPDATE_COMPANY_PROFILE_SUCCESS,
          actionTypes.UPDATE_COMPANY_PROFILE_FAILURE,
        ],
        { body: data }
      )
    )(dispatch);
  };

export const submitJobRequestApplicationForm = payload => {
  const data = {
    hiring_applicant: payload.form,
    source: payload.source,
    profile_url: payload.location_profile_url,
    job_request_url: payload.job_request_url,
    hb_user: payload.hb_user,
    job_alerts: payload.jobAlerts,
  };

  return withAlerts(
    createAsyncPostAction(
      routes.applicantsCreateRoute(data),
      [
        actionTypes.SUBMIT_JOB_REQUEST_APPLICATION_FORM_REQUEST,
        actionTypes.SUBMIT_JOB_REQUEST_APPLICATION_FORM_SUCCESS,
        actionTypes.SUBMIT_JOB_REQUEST_APPLICATION_FORM_FAILURE,
      ],
      { body: data }
    ),
    {
      success: toI18n('hiring.application_form.application_sent'),
      onSuccess: response => {
        logHiringEvent({
          product_location: 'job_request_page',
          action: 'light_job_request_form_success',
        });

        window.location = response.payload.redirect_path;
      },
    }
  );
};

export const submitLocationApplicationForm = payload => {
  const data = {
    hiring_applicant: payload.form,
    profile_url: payload.location_profile_url,
  };

  return withAlerts(
    createAsyncPostAction(
      routes.applicantsCreateRoute(data),
      [
        actionTypes.SUBMIT_LOCATION_APPLICATION_FORM_REQUEST,
        actionTypes.SUBMIT_LOCATION_APPLICATION_FORM_SUCCESS,
        actionTypes.SUBMIT_LOCATION_APPLICATION_FORM_FAILURE,
      ],
      { body: data }
    ),
    {
      success: toI18n('hiring.application_form.application_sent'),
      onSuccess: response => {
        logHiringEvent({
          product_location: 'careers_page',
          action: 'light_job_request_form_success',
        });

        window.location = response.payload.redirect_path;
      },
    }
  );
};

export const fetchInitialManageApplicants = (type, id) => {
  const meta = { requestId: `manage-applicants-${type}-${id}` };

  return withAlerts(
    createAsyncGetAction(routes.hiringManageApplicants(type, id), [
      { type: actionTypes.FETCH_INITIAL_MANAGE_APPLICANTS_REQUEST, meta },
      { type: actionTypes.FETCH_INITIAL_MANAGE_APPLICANTS_SUCCESS, meta },
      { type: actionTypes.FETCH_INITIAL_MANAGE_APPLICANTS_FAILURE, meta },
    ]),
    {
      onError: action => {
        if (action.payload.status === 404) {
          window.Homebase.showPreloader();
          window.location = '/hiring/dashboard';
        }
      },
    }
  );
};

export const fetchApplicantData = (id, token) => {
  const meta = { id, requestId: `applicant-${token}` };

  return createAsyncGetAction(
    routes.applicationApplicant(id),
    [
      { type: actionTypes.FETCH_APPLICANT_DATA_REQUEST, meta },
      { type: actionTypes.FETCH_APPLICANT_DATA_SUCCESS, meta },
      { type: actionTypes.FETCH_APPLICANT_DATA_FAILURE, meta },
    ],
    {
      bailout: state =>
        hiringSelectors.getBailoutFetchApplicantData(state, { id }),
    }
  );
};

export const toggleApplicationLiked = id => (dispatch, getState) => {
  const reduxState = getState();
  const liked = hiringSelectors
    .getApplicantDataById(reduxState, { id })
    .get('liked');
  const state = hiringSelectors
    .getApplicantDataById(reduxState, { id })
    .get('state');
  const meta = { id, liked, state };

  return dispatch(
    withAlerts(
      createAsyncPutAction(routes.applicationToggleLiked(id), [
        { type: actionTypes.TOGGLE_APPLICATION_LIKED_REQUEST, meta },
        { type: actionTypes.TOGGLE_APPLICATION_LIKED_SUCCESS, meta },
        { type: actionTypes.TOGGLE_APPLICATION_LIKED_FAILURE, meta },
      ])
    )
  );
};

export const passApplication = (id, state) => (dispatch, getState) => {
  const liked = hiringSelectors
    .getApplicantDataById(getState(), { id })
    .get('liked');
  const meta = { id, state, liked };

  return withAlerts(
    createAsyncPutAction(routes.applicationPass(id), [
      { type: actionTypes.PASS_APPLICATION_REQUEST, meta },
      { type: actionTypes.PASS_APPLICATION_SUCCESS, meta },
      { type: actionTypes.PASS_APPLICATION_FAILURE, meta },
    ])
  )(dispatch);
};

export const unpassApplication = (id, state) => (dispatch, getState) => {
  const liked = hiringSelectors
    .getApplicantDataById(getState(), { id })
    .get('liked');
  const meta = { id, state, liked };

  return dispatch(
    createAsyncPutAction(routes.applicationUnpass(id), [
      { type: actionTypes.UNPASS_APPLICATION_REQUEST, meta },
      { type: actionTypes.UNPASS_APPLICATION_SUCCESS, meta },
      { type: actionTypes.UNPASS_APPLICATION_FAILURE, meta },
    ])
  );
};

export const archiveApplications = ids => dispatch => {
  const meta = { ids };

  return dispatch(
    createAsyncPutAction(
      routes.applicationsArchive(),
      [
        { type: actionTypes.ARCHIVE_APPLICATIONS_REQUEST, meta },
        { type: actionTypes.ARCHIVE_APPLICATIONS_SUCCESS, meta },
        { type: actionTypes.ARCHIVE_APPLICATIONS_FAILURE, meta },
      ],
      {
        body: { ids },
      }
    )
  );
};

export const openHireModal = applicant => ({
  type: actionTypes.OPEN_HIRE_MODAL,
  payload: {
    applicant,
  },
});

export const closeHireModal = () => ({
  type: actionTypes.CLOSE_HIRE_MODAL,
});

export const hireApplication = applicant => dispatch => {
  const id = applicant.get('id');
  const meta = { id, requestId: `hire-application-${id}` };

  return dispatch(
    withAlerts(
      createAsyncPutAction(routes.applicationHire(id), [
        { type: actionTypes.HIRE_APPLICATION_REQUEST, meta },
        { type: actionTypes.HIRE_APPLICATION_SUCCESS, meta },
        { type: actionTypes.HIRE_APPLICATION_FAILURE, meta },
      ])
    )
  );
};

export const bookInterview = data =>
  createAsyncPostAction(
    '/hiring/interview_availabilities',
    [
      { type: actionTypes.BOOK_INTERVIEW_REQUEST },
      { type: actionTypes.BOOK_INTERVIEW_SUCCESS },
      { type: actionTypes.BOOK_INTERVIEW_FAILURE },
    ],
    { body: data }
  );

export const submitInterviewDetails = (id, data, meta) =>
  createAsyncPutAction(
    `/hiring/job_applications/${id}/interview_requested`,
    [
      { type: actionTypes.SUBMIT_INTERVIEW_DETAILS_REQUEST, meta },
      { type: actionTypes.SUBMIT_INTERVIEW_DETAILS_SUCCESS, meta },
      { type: actionTypes.SUBMIT_INTERVIEW_DETAILS_FAILURE, meta },
    ],
    { body: data }
  );

export const fetchJobRequestSuccessData = id =>
  createAsyncGetAction(
    routes.jobRequestSuccessRoute(id),
    [
      actionTypes.FETCH_JOB_REQUEST_SUCCESS_DATA_REQUEST,
      actionTypes.FETCH_JOB_REQUEST_SUCCESS_DATA_SUCCESS,
      actionTypes.FETCH_JOB_REQUEST_SUCCESS_DATA_FAILURE,
    ],
    {}
  );

export const createJobRequestBoost = ({
  jobRequestId,
  onSuccess,
  partnerName,
  claimedVia,
  budgetInCents,
}) => {
  const data = {
    job_request_id: jobRequestId,
    partner_name: partnerName,
    claimed_via: claimedVia,
    budget_in_cents: budgetInCents,
  };

  return withAlerts(
    createAsyncPostAction(
      routes.hiringJobRequestBoostsRoute(),
      [
        { type: actionTypes.CREATE_JOB_POST_BOOST_REQUEST },
        { type: actionTypes.CREATE_JOB_POST_BOOST_SUCCESS },
        { type: actionTypes.CREATE_JOB_POST_BOOST_FAILURE },
      ],
      { body: data }
    ),
    { onSuccess }
  );
};

export const createJobPost = ({
  title,
  description,
  locationId,
  role,
  roleType,
  wageRate,
  wageRateMax,
  cashoutDetailsIncluded,
  syndicationSettings,
  availability,
  onSuccess,
  onError,
  onDone,
}) => {
  const meta = {
    requestId: `submit-job-post-${role}-${locationId}`,
  };

  return withAlerts(
    createAsyncPostAction(
      routes.jobRequestsRoute(),
      [
        { type: actionTypes.CREATE_JOB_POST_REQUEST, meta },
        { type: actionTypes.CREATE_JOB_POST_SUCCESS, meta },
        { type: actionTypes.CREATE_JOB_POST_FAILURE, meta },
      ],
      {
        body: {
          hiring_job_request: {
            title,
            role,
            role_type: roleType,
            location_id: locationId,
            cashout_details_included: cashoutDetailsIncluded,
            target_wage_rate: wageRate,
            target_wage_rate_max: wageRateMax,
            description,
            syndication_setting_attributes: syndicationSettings,
            week_availabilities_attributes: availability,
          },
        },
      }
    ),
    {
      onSuccess,
      onError,
      onDone,
    }
  );
};

export const updateJobPost = ({
  id,
  title,
  description,
  locationId,
  wageRate,
  wageRateMax,
  availability,
  availabilityId,
  onSuccess,
  onError,
  onDone,
}) => {
  const meta = { requestId: `update-job-post-${id}` };
  const fullAvailability = { ...availability, id: availabilityId };

  return withAlerts(
    createAsyncPutAction(
      routes.jobRequestRoute(id),
      [
        { type: actionTypes.UPDATE_JOB_POST_REQUEST, meta },
        { type: actionTypes.UPDATE_JOB_POST_SUCCESS, meta },
        { type: actionTypes.UPDATE_JOB_POST_FAILURE, meta },
      ],
      {
        body: {
          hiring_job_request: {
            id,
            title,
            location_id: locationId,
            target_wage_rate: wageRate,
            target_wage_rate_max: wageRateMax,
            description,
            week_availabilities_attributes: fullAvailability,
          },
        },
      }
    ),
    {
      onSuccess,
      onError,
      onDone,
    }
  );
};

export const createJobApplicationNote = ({
  text,
  applicationId,
  onSuccess,
}) => {
  const meta = {
    requestId: `create-job-application-note-${applicationId}`,
    applicationId,
  };
  return withAlerts(
    createAsyncPostAction(
      routes.applicationNotes(applicationId),
      [
        { type: actionTypes.CREATE_JOB_APPLICATION_NOTE_REQUEST, meta },
        { type: actionTypes.CREATE_JOB_APPLICATION_NOTE_SUCCESS, meta },
        { type: actionTypes.CREATE_JOB_APPLICATION_NOTE_FAILURE, meta },
      ],
      {
        body: { text },
      }
    ),
    {
      onSuccess,
    }
  );
};

export const deleteJobApplicationNote = ({ applicationId, id }) => {
  const meta = {
    requestId: `delete-job-application-note-${id}`,
    noteId: id,
    applicationId,
  };
  return withAlerts(
    createAsyncDeleteAction(routes.applicationNote(applicationId, id), [
      { type: actionTypes.DELETE_JOB_APPLICATION_NOTE_REQUEST, meta },
      { type: actionTypes.DELETE_JOB_APPLICATION_NOTE_SUCCESS, meta },
      { type: actionTypes.DELETE_JOB_APPLICATION_NOTE_FAILURE, meta },
    ])
  );
};

export const submitWhitelistingForm = ({
  website,
  primaryAts,
  syndicationEmail,
  address1,
  address2,
  state,
  city,
  zip,
  onSuccess,
}) => {
  const meta = { requestId: 'post-verification' };
  return withAlerts(
    createAsyncPatchAction(
      routes.hiringSettings(),
      [
        { type: actionTypes.SUBMIT_WHITELISTING_WIDGET_REQUEST, meta },
        { type: actionTypes.SUBMIT_WHITELISTING_WIDGET_SUCCESS, meta },
        { type: actionTypes.SUBMIT_WHITELISTING_WIDGET_FAILURE, meta },
      ],
      {
        body: {
          settings: {
            website,
            primary_ats: primaryAts,
            syndication_email: syndicationEmail,
            business_address_attributes: {
              address_1: address1,
              address_2: address2,
              state,
              city,
              zip,
            },
          },
        },
      }
    ),
    {
      onSuccess,
    }
  );
};

export const toggleApplicationNotesDrawerOpen = open => ({
  type: actionTypes.TOGGLE_APPLICATION_NOTES_DRAWER_OPEN,
  payload: {
    drawerKey: APPLICATION_NOTES_DRAWER_KEY,
    drawerOpen: !!open,
  },
});

export const toggleHiringDashboardPageTipsDrawerOpen = open => ({
  type: actionTypes.TOGGLE_HIRING_DASHBOARD_PAGE_TIPS_DRAWER_OPEN,
  payload: {
    drawerKey: HIRING_PAGE_TIPS_DRAWER_KEY,
    drawerOpen: !!open,
  },
});

export const toggleManageApplicantsZeroStateDrawerOpen = open => ({
  type: actionTypes.TOGGLE_MANAGE_APPLICANTS_ZERO_STATE_DRAWER_OPEN,
  payload: {
    drawerKey: MANAGE_APPLICANTS_ZERO_STATE_DRAWER_KEY,
    drawerOpen: !!open,
  },
});

export const setMobileApplicantProfileTabView = payload => ({
  type: actionTypes.SET_MOBILE_APPLICANT_PROFILE_TAB_VIEW,
  payload,
});

export const submitApplicationQuestions = (id, formattedQuestions) => {
  const meta = { formattedQuestions };

  return withAlerts(
    createAsyncPostAction(
      routes.hiringApplicationQuestionsRoute(id),
      [
        { type: actionTypes.SUBMIT_APPLICATION_QUESTIONS_REQUEST, meta },
        { type: actionTypes.SUBMIT_APPLICATION_QUESTIONS_SUCCESS, meta },
        { type: actionTypes.SUBMIT_APPLICATION_QUESTIONS_FAILURE, meta },
      ],
      {
        body: {
          application_questions: formattedQuestions.screener,
          eeo_questions: formattedQuestions.eeo,
        },
      }
    )
  );
};

export const requestAddApplicantAccess = () =>
  withAlerts(
    createAsyncPutAction(routes.hiringRequestAddApplicantAccess(), [
      { type: actionTypes.REQUEST_ADD_APPLICANT_ACCESS_REQUEST },
      { type: actionTypes.REQUEST_ADD_APPLICANT_ACCESS_SUCCESS },
      { type: actionTypes.REQUEST_ADD_APPLICANT_ACCESS_FAILURE },
    ]),
    {
      success: toI18n('hiring.application_questions.on_success_banner'),
    }
  );

export const checkStatusOfAbilityToAddApplicants = () =>
  withAlerts(
    createAsyncPutAction(routes.hiringCheckStatusOfAbilityToAddApplicants(), [
      { type: actionTypes.CHECK_STATUS_OF_ABILITY_TO_ADD_APPLICANTS_REQUEST },
      { type: actionTypes.CHECK_STATUS_OF_ABILITY_TO_ADD_APPLICANTS_SUCCESS },
      { type: actionTypes.CHECK_STATUS_OF_ABILITY_TO_ADD_APPLICANTS_FAILURE },
    ])
  );
