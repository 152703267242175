import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'initializers/types';
import { StreamChat } from 'stream-chat';

import { MESSENGER_SLICE_NAME } from './constants';

type UnreadMessageIndicator = {
  location: {
    name: string;
    unreadCount: number;
  };
}[];

const initialState: {
  unreadMessageIndicator: UnreadMessageIndicator;
  totalUnreadCount: number;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
} = {
  unreadMessageIndicator: [],
  totalUnreadCount: 0,
  status: 'idle',
  error: null,
};

export const setTotalUnreadCount = createAsyncThunk(
  `${MESSENGER_SLICE_NAME}/setTotalUnreadCount`,
  async (totalUnreadCount: number, { rejectWithValue }) => {
    try {
      return totalUnreadCount;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  }
);

export const setUnreadMessageIndicators = createAsyncThunk(
  `${MESSENGER_SLICE_NAME}/setUnreadMessageIndicators`,
  async ({ client, teamIds }: { client: StreamChat; teamIds: string[] }) => {
    const unreadMessageIndicator = await Promise.all(
      teamIds.map(async teamId => {
        const channels = await client.queryChannels({
          type: 'team',
          team: teamId,
          members: { $in: [client.userID || ''] },
        });

        const unreadCount = channels.reduce(
          (sum, ch) => sum + (ch.state.unreadCount || 0),
          0
        );

        return {
          location: {
            name: teamId,
            unreadCount,
          },
        };
      })
    );

    return unreadMessageIndicator;
  }
);

const slice = createSlice({
  name: MESSENGER_SLICE_NAME,
  initialState,
  reducers: {
    markMessagesAsRead(state, action) {
      state.totalUnreadCount = Math.max(
        0,
        state.totalUnreadCount - action.payload
      );
    },
  },
  extraReducers: builder => {
    builder.addCase(setUnreadMessageIndicators.fulfilled, (state, action) => {
      state.unreadMessageIndicator = action.payload;
      state.status = 'succeeded';
    });
    builder.addCase(setUnreadMessageIndicators.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(setUnreadMessageIndicators.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload as string;
    });
    builder.addCase(setTotalUnreadCount.fulfilled, (state, action) => {
      state.totalUnreadCount = action.payload;
      state.status = 'succeeded';
    });
    builder.addCase(setTotalUnreadCount.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(setTotalUnreadCount.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload as string;
    });
  },
});

export const selectUnreadMessageIndicators = (state: RootState) =>
  state[MESSENGER_SLICE_NAME].unreadMessageIndicator;

export const selectTotalUnreadCount = (state: RootState) =>
  state[MESSENGER_SLICE_NAME].totalUnreadCount;

export const { markMessagesAsRead } = slice.actions;

export const { reducer } = slice;
