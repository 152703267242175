import MUIAvatar from '@mui/material/Avatar';
import withCx, { CxProps } from 'fe-core/util/withCx';

type SizeProps = {
  [index: string]: number;
};
export const sizes: SizeProps = {
  small: 24,
  medium: 32,
  large: 48,
  xl: 120,
};

export const borderSizes: SizeProps = {
  small: 1,
  medium: 2,
  large: 2,
  xl: 3,
};

export const AVATAR_VARIANTS = ['secondary'] as const;
export const AVATAR_SIZES = ['small', 'medium', 'large', 'xl'] as const;
export type AvatarVariant = (typeof AVATAR_VARIANTS)[number];
export type AvatarSize = (typeof AVATAR_SIZES)[number];

export interface AvatarProps {
  size?: AvatarSize;
  initials?: string;
  image?: any;
  alt?: string;
  variant?: AvatarVariant;
}

const Avatar = ({
  initials = '',
  image,
  alt = 'avatar',
  size = 'medium',
  variant = 'secondary',
  cx,
}: AvatarProps & CxProps) => {
  const displayedInitials = size === 'small' ? initials.charAt(0) : initials;

  return (
    <MUIAvatar
      src={image}
      alt={alt}
      className={cx({ [variant]: true, [size]: true })}
    >
      {displayedInitials}
    </MUIAvatar>
  );
};
export default withCx<AvatarProps>('FDBAvatar')(Avatar);
