import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const SIERRA_CHAT_LAUNCHER_VISIBLE_ROUTES = ['/help', '/settings'];
const SIERRA_CHAT_LAUNCHER_HIDDEN_ROUTES = ['/schedule'];

export const SierraChat = () => {
  const location = useLocation();

  const toggleSierraChatLauncher = useCallback((pathname: string) => {
    const sierraLauncher: HTMLDivElement | null = document.querySelector(
      'div[data-sierra-chat-launcher]'
    );

    if (!sierraLauncher || !pathname) return;

    const { forceShowChatButton = false } = window?.sierraConfig || {};

    // Hide or show Sierra AI chat button depending on the path
    const isPathInVisibleRoutes = SIERRA_CHAT_LAUNCHER_VISIBLE_ROUTES.some(
      path => pathname.startsWith(path)
    );
    const isPathInHiddenRoutes = SIERRA_CHAT_LAUNCHER_HIDDEN_ROUTES.some(path =>
      pathname.startsWith(path)
    );
    const showSierraChatButton =
      (isPathInVisibleRoutes || forceShowChatButton) && !isPathInHiddenRoutes;

    const styles = showSierraChatButton
      ? { position: 'relative', display: 'block', zIndex: '1000' }
      : { position: '', display: 'none', zIndex: '-1' };

    Object.assign(sierraLauncher.style, styles);
  }, []);

  useEffect(() => {
    const onDocumentLoad = () => toggleSierraChatLauncher(location.pathname);

    if (document.readyState === 'complete') {
      toggleSierraChatLauncher(location.pathname);
    } else {
      window.addEventListener('load', onDocumentLoad);
    }

    return () => {
      window.removeEventListener('load', onDocumentLoad);
    };
  }, [toggleSierraChatLauncher, location.pathname]);

  return null;
};
