import React, { PureComponent } from 'react';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import Text from 'components/Text';

import cxHelpers from 'util/className';
import { setOriginContextByUrl, trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
} from 'util/tracking_constants';

import { SCHEDULE_ROUTES } from '../../constants';
import withNavItemActive from '../../hocs/withNavItemActive';
import withNavItemData from '../../hocs/withNavItemData';
import NavItem from '../NavItem';
import NavItemWithSubNav from '../NavItemWithSubNav';

@withNavItemActive(SCHEDULE_ROUTES)
@withNavItemData('schedule')
@cxHelpers('ScheduleNavItem')
export default class ScheduleNavItem extends PureComponent {
  static propTypes = {
    url: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  };

  renderText = () => <Text fs14 i18n="nav_links.schedule.top_level" />;

  handleClick = () => {
    setOriginContextByUrl(this.props.url);
    trackUxEvent({
      productArea: PRODUCT_AREAS.NAVIGATION,
      eventCategory: EVENT_CATEGORIES.NAV,
      eventAction: EVENT_ACTIONS.SCHEDULE_TAB_CLICKED,
    });
  };

  render() {
    const { url, currentRoute, ...props } = omit(this.props, 'whiteText');

    const Component = props.menuItems ? NavItemWithSubNav : NavItem;

    return (
      <Component
        {...props}
        href={url}
        onClick={this.handleClick}
        currentRoute={currentRoute}
        className={this.cx()}
      >
        {this.renderText()}
      </Component>
    );
  }
}
