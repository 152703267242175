import { DRAWER_COMPONENTS_NAME } from 'features/onboarding/constants';

const { PAY_YOUR_TEAM_CARD, SCHEDULER_CARD, TIME_PAY_CARD } =
  DRAWER_COMPONENTS_NAME;

export const SLICE_NAME = 'quickStartGuide';

export const ROUTES = {
  FETCH_DATA: '/quick_start_guide.json',
  ADD_EMPLOYEE: '/employees',
};

export const ALERT_LIST = [
  {
    id: 'scheduling',
    copy: 'payroll_postsignup_inception.alert.scheduling',
    bottomDrawerComponent: SCHEDULER_CARD,
    qsgTask: 'preview_scheduling_task',
  },
  {
    id: 'time_tracking',
    copy: 'payroll_postsignup_inception.alert.time_tracking',
    bottomDrawerComponent: TIME_PAY_CARD,
    qsgTask: 'preview_time_tracking_task',
  },
  {
    id: 'payroll',
    copy: 'payroll_postsignup_inception.alert.payroll',
    bottomDrawerComponent: PAY_YOUR_TEAM_CARD,
    qsgTask: 'preview_payroll_task',
  },
];
