import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import { RootState } from 'initializers/types';

import { getTimeOffPolicyEnhancementsEnabled } from 'selectors/session';

import { FORM_WITH_STEPS_BOX_STYLE } from 'features/team/components/AddEmployeeForm/constants';

import { cxHelpers } from 'util/className';

import SectionTitle from '../../components/SectionTitle';
import { fetchPolicyOptions, selectPolicyOptions } from '../../slice';

import PolicySelection from './PolicySelection';
import { TimeOffPolicyOptions } from './types';
const { cx } = cxHelpers('TimeOffSection');

const TimeOffSection = ({
  showFormWithSteps = false,
}: {
  showFormWithSteps: boolean;
}) => {
  const dispatch = useDispatch();
  const rolloutEnabled = useSelector(getTimeOffPolicyEnhancementsEnabled);
  useEffect(() => {
    if (rolloutEnabled) {
      dispatch(fetchPolicyOptions());
    }
  }, [dispatch, rolloutEnabled]);

  const ptoPolicyOptions: TimeOffPolicyOptions[] = useSelector(
    (state: RootState) => selectPolicyOptions(state, 'pto')
  );
  const sickLeavePolicyOptions: TimeOffPolicyOptions[] = useSelector(
    (state: RootState) => selectPolicyOptions(state, 'paid_sick_leave')
  );

  if (!rolloutEnabled) {
    return null;
  }

  if (ptoPolicyOptions.length === 1 && sickLeavePolicyOptions.length === 1) {
    return null;
  }

  return (
    <Box
      className={cx()}
      mv={28}
      {...(showFormWithSteps && FORM_WITH_STEPS_BOX_STYLE)}
    >
      <SectionTitle section="time_off" />
      {ptoPolicyOptions.length > 1 && (
        <PolicySelection
          timeOffPolicyOptions={ptoPolicyOptions}
          policyType="pto_policy"
        />
      )}
      {sickLeavePolicyOptions.length > 1 && (
        <PolicySelection
          timeOffPolicyOptions={sickLeavePolicyOptions}
          policyType="paid_sick_leave_policy"
        />
      )}
    </Box>
  );
};

export default TimeOffSection;
