import { fromJS, List, Set } from 'immutable';
import { combineReducers } from 'redux-immutable';

import {
  actionTypes,
  fetchBillingData,
  preloadBillingData,
} from 'actions/settings';

import { actions as billerActions } from 'features/biller';

import { timestamp } from 'util/dateTime';

const INITIAL_API_FORM_STATE = fromJS({
  requestingKey: false,
});

const apiForm = (state = INITIAL_API_FORM_STATE, action) => {
  switch (action.type) {
    case actionTypes.CREATE_API_KEY_REQUEST:
      return state.merge({ requestingKey: true });

    case actionTypes.CREATE_API_KEY_SUCCESS:
    case actionTypes.CREATE_API_KEY_FAILURE:
      return state.merge({ requestingKey: false });

    default:
      return state;
  }
};

const isLoadingUser = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_SETTINGS_DATA_REQUEST:
      return true;

    case actionTypes.FETCH_USER_SETTINGS_DATA_SUCCESS:
    case actionTypes.FETCH_USER_SETTINGS_DATA_FAILURE:
      return false;

    default:
      return state;
  }
};
const isLoading = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LOCATIONS_DATA_REQUEST:
      return true;

    case actionTypes.FETCH_LOCATIONS_DATA_SUCCESS:
    case actionTypes.FETCH_LOCATIONS_DATA_FAILURE:
      return false;

    case actionTypes.FETCH_SELF_LOCATION_CREATE_DATA_SUCCESS:
      return false;

    default:
      return state;
  }
};

const partnerLocationsImportIsLoading = (state = false, action) => {
  switch (action.type) {
    case actionTypes.IMPORT_PARTNER_LOCATIONS_REQUEST:
      return true;

    case actionTypes.IMPORT_PARTNER_LOCATIONS_SUCCESS:
    case actionTypes.IMPORT_PARTNER_LOCATIONS_FAILURE:
      return false;

    default:
      return state;
  }
};

const payrollModalErrors = (state = new List(), action) => {
  switch (action.type) {
    case actionTypes.UPDATE_LOCATION_SETTINGS_REQUEST:
      return state.clear();
    case actionTypes.UPDATE_LOCATION_SETTINGS_FAILURE:
      return state.merge(fromJS(action.payload?.response?.errors));
    default:
      return state;
  }
};

export const applyToAllLocations = (state = false, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_APPLY_TO_ALL_LOCATIONS:
      return action.payload.value;

    default:
      return state;
  }
};

export const isSaving = (state = Set(), action) => {
  switch (action.type) {
    case actionTypes.UPDATE_LOCATION_SETTINGS_REQUEST:
      return state.add(action.meta.requestId);

    case actionTypes.UPDATE_LOCATION_SETTINGS_SUCCESS:
    case actionTypes.UPDATE_LOCATION_SETTINGS_FAILURE:
      return state.remove(action.meta?.requestId);

    default:
      return state;
  }
};

const DEFAULT_SELF_LOCATION_CREATE = fromJS({
  showModal: false,
});

export const selfLocationCreate = (
  state = DEFAULT_SELF_LOCATION_CREATE,
  action
) => {
  switch (action.type) {
    case actionTypes.CLOSE_SELF_LOCATION_CREATE_MODAL:
      return state.merge({ showModal: false });

    default:
      return state;
  }
};

export const lastSaved = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SAVE_LOCATION_SETTINGS_SUCCESS:
      return `${action.meta.formName}__${timestamp()}`;

    default:
      return state;
  }
};

const INITIAL_GENERAL_STATE = fromJS({
  highlightedBlock: null,
});

export const general = (state = INITIAL_GENERAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.HIGHLIGHT_BLOCK:
      return state.merge({ highlightedBlock: action.payload.highlightedBlock });
    default:
      return state;
  }
};

const INITIAL_BILLING_STATE = fromJS({
  transactions: [],
  subscriptions: [],
  payrollSubscription: [],
  tipManagerSubscriptions: [],
  taskManagerSubscriptions: [],
  isLoading: true,
  isDirty: false,
});

export const billing = (state = INITIAL_BILLING_STATE, action) => {
  switch (action.type) {
    case `${preloadBillingData.pending}`:
      return state.set('isDirty', false);
    case `${fetchBillingData.fulfilled}`:
      return state.merge({
        transactions: fromJS(action.payload.transactions),
        subscriptions: fromJS(action.payload.subscriptions),
        payrollSubscription: fromJS(action.payload.payroll_subscription),
        tipManagerSubscriptions: fromJS(
          action.payload.tip_manager_subscriptions
        ),
        taskManagerSubscriptions: fromJS(
          action.payload.task_manager_subscriptions
        ),
        isLoading: false,
        isDirty: false,
      });
    case `${billerActions.paymentMethodUpdated}`:
      return state.set('isDirty', true);
    default:
      return state;
  }
};

export default combineReducers({
  general,
  apiForm,
  isLoading,
  isLoadingUser,
  partnerLocationsImportIsLoading,
  applyToAllLocations,
  isSaving,
  selfLocationCreate,
  lastSaved,
  billing,
  payrollModalErrors,
});
