/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from 'reselect';

import {
  getCurrentLocation,
  getCurrentLocationTierId,
  getGhostPackagingManagePlanPageExperimentEnabled,
  getHasActiveTrialPeriod,
  getStarterKitPayrollPackage,
  getStarterPackageEnabled,
} from 'selectors/session';

import { selectIsSubscribedAnnually } from 'features/biller/selectors';
import {
  selectGhostEmployeeCapV1ExperimentEnabled,
  selectGhostEmployeeCapV2ExperimentEnabled,
} from 'features/enforcement/EnforcementBanner/selectors';
import { planCardCtaType } from 'features/managePlan/util';
import {
  getIsFetchingPayrollInfo,
  getPayrollPackage,
} from 'features/payroll/PayrollInfo/PayrollInfoSlice';

import {
  selectExceedsCapLimit,
  selectIsCurrentLocationTierBasic,
} from '../enforcement/EnforcementBanner/selectors';

export const selectAllProducts = (state: any) => state.get('managePlan');

export const selectFeatures = (state: any) => state.get('managePlan').features;

export const selectIsSelectedBillingCycleAnnual = (state: any) =>
  state.get('managePlan').isSelectedBillingCycleAnnual;

export const selectLocationInNonPayingUnsecuredTrial = (state: any) =>
  state.getIn(['session', 'monetization', 'locationInNonPayingUnsecuredTrial']);

export const selectLocationIsOnStarterPayrollPackage = createSelector(
  getStarterPackageEnabled,
  getStarterKitPayrollPackage,
  (starterPackageEnabled, starterKitPayrollPackage) =>
    starterPackageEnabled && Boolean(starterKitPayrollPackage)
);

export const selectGhostEmployeeExperimentsEnabled = createSelector(
  getGhostPackagingManagePlanPageExperimentEnabled,
  selectGhostEmployeeCapV1ExperimentEnabled,
  selectGhostEmployeeCapV2ExperimentEnabled,
  (
    ghostPackagingManagePlanPageExperimentEnabled,
    ghostEmployeeCapV1ExperimentEnabled,
    ghostEmployeeCapV2ExperimentEnabled
  ) =>
    ghostPackagingManagePlanPageExperimentEnabled ||
    ghostEmployeeCapV1ExperimentEnabled ||
    ghostEmployeeCapV2ExperimentEnabled
);

export const selectShouldShowEnforcementBottomSheet = createSelector(
  selectGhostEmployeeExperimentsEnabled,
  selectExceedsCapLimit,
  selectIsCurrentLocationTierBasic,
  (
    ghostEmployeeExperimentsEnabled,
    exceedsCapLimit,
    isCurrentLocationTierBasic
  ) =>
    ghostEmployeeExperimentsEnabled &&
    exceedsCapLimit &&
    !isCurrentLocationTierBasic
);

export const selectPlanCardCta = createSelector(
  (_: any, props: any) => props.tierId,

  (_: any, props: any) => props.tierName,
  getCurrentLocationTierId,
  selectIsSubscribedAnnually,
  selectIsSelectedBillingCycleAnnual,
  getHasActiveTrialPeriod,
  selectLocationIsOnStarterPayrollPackage,
  (
    tierId,
    tierName,
    currentLocationTierId,
    isSubscribedAnnually,
    isSelectedBillingCycleAnnual,
    hasActiveTrialPeriod,
    locationIsOnStarterPayrollPackage
  ) =>
    planCardCtaType({
      tierId,
      tierName,
      isSubscribedAnnually,
      hasActiveTrialPeriod,
      currentLocationTierId,
      isSelectedBillingCycleAnnual,
      locationIsOnStarterPayrollPackage,
    })
);

export const selectProducts = createSelector(
  selectAllProducts,
  selectIsSelectedBillingCycleAnnual,
  (products, isSelectedBillingCycleAnnual) => {
    const { monthlyProducts, annualProducts } = products;

    return isSelectedBillingCycleAnnual ? annualProducts : monthlyProducts;
  }
);

export const selectHasAnnualProducts = createSelector(
  selectAllProducts,
  ({ annualProducts }) => !!annualProducts.length
);

export const selectIsUSCustomer = createSelector(
  getCurrentLocation,
  currentLocation => currentLocation.get('country_code') === 'US'
);

export const selectProductTipManagerIsLoading = (state: any) =>
  state.get('managePlan').tipManager?.isLoading;

export const selectProductTipManagerIsSubscribed = (state: any) =>
  state.get('managePlan').tipManager?.isSubscribed;

export const selectProductTipManager = (state: any) =>
  state.get('managePlan').tipManager;

export const selectProductTipManagerPrice = (state: any) =>
  state.get('managePlan').tipManager?.price;

export const selectProductTaskManagerIsLoading = (state: any) =>
  state.get('managePlan').taskManager?.isLoading;

export const selectProductTaskManagerIsSubscribed = (state: any) =>
  state.get('managePlan').taskManager?.isSubscribed;

export const selectProductTaskManager = (state: any) =>
  state.get('managePlan').taskManager;

export const selectProductTaskManagerPrice = (state: any) =>
  state.get('managePlan').taskManager?.price;

export const selectIsPageDataLoaded = createSelector(
  selectFeatures,
  getPayrollPackage,
  getIsFetchingPayrollInfo,
  (features, payrollPackage, isFetchingPayrollInfo) =>
    Boolean(features.length) && payrollPackage && !isFetchingPayrollInfo
);

export const getMarketingAttachedDiscountCoupon = (state: any) =>
  state.get('managePlan')?.marketingAttachedDiscountCoupon;
