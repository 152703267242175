import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';
import { Map } from 'immutable';

import { SLICE_NAME } from './constants';
import { SessionState } from './interface';

const initialState: SessionState = {
  canManageEmployees: false,
  status: 'idle',
};

export const fetch = createAsyncThunk(`${SLICE_NAME}/fetch`, async () => {
  const session = await fetchJSON('/schedule_builder/unserialized_session');
  return session;
});

const sliceState = (state: Map<string, any>) => state.get(SLICE_NAME) || {};

export const selectIsLoaded = (state: Map<string, any>) =>
  sliceState(state).status === 'succeeded';

export const selectCanManageEmployees = (state: Map<string, any>): boolean =>
  sliceState(state).canManageEmployees;

export const selectColorMappingVersion = (
  state: Map<string, any>
): number | undefined => sliceState(state).colorMappingVersion;

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetch.pending, state => {
        state.status = 'pending';
        state.error = undefined;
      })
      .addCase(fetch.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.id = action.payload.id;
        state.canManageEmployees = action.payload.canManageEmployees;
        state.colorMappingVersion = action.payload.colorMappingVersion;
        state.error = undefined;
      })
      .addCase(fetch.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const reducer = slice.reducer;
