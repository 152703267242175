import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { addLocationRole } from 'actions/timesheets';

import { getCompanyRolesArray } from 'selectors/addTeam';
import { getPacketProps } from 'selectors/drawers';

import ContactSection from 'features/team/components/AddEmployeeForm/Sections/ContactSection';
import JobDetailsSection from 'features/team/components/AddEmployeeForm/Sections/JobDetailsSection';
import OnboardingSection from 'features/team/components/AddEmployeeForm/Sections/OnboardingSection';
import PayrollSection from 'features/team/components/AddEmployeeForm/Sections/PayrollSection';
import TimeOffSection from 'features/team/components/AddEmployeeForm/Sections/TimeOffSection';
import { HR_STYLE } from 'features/team/constants';
import { closeAddNewRoleModal, openToast } from 'features/team/slice';

import AddNewRoleModal from './AddNewRoleModal';
import { formHasContent } from './util';

const AddEmployeeForm = ({
  companyRolesArray,
  contactInfoRequired,
  contactSectionRef,
  formFilloutRecommended,
  isAIOLocation,
  isPayrollEnrolled,
  onCloseAddNewRoleModal,
  onOpenToast,
  onResetContactInfoRequired,
  onSetHasEmailError,
  onSetHasPhoneError,
  onSetIsAdding,
  onSetRehireId,
  onSetRehireLevel,
  onboardingSectionRef,
  packetProps,
  rehireLevel,
  currentTeamMember,
}) => {
  const { setFieldValue, values } = useFormikContext();
  const dispatch = useDispatch();

  const [roleFieldValue, setRoleFieldValue] = useState('');
  const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    onResetContactInfoRequired();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.email, values.phone, values.onboarding]);

  const companyAndSelectedRoles = useMemo(() => {
    const rolesArray = companyRolesArray.concat(selectedRoles);
    return rolesArray.filter(
      (role, pos) => Boolean(role) && rolesArray.indexOf(role) === pos
    );
  }, [companyRolesArray, selectedRoles]);

  const inRolesArray = useCallback(
    value =>
      companyAndSelectedRoles
        .map(role => role.toLowerCase())
        .includes(value?.toLowerCase()),
    [companyAndSelectedRoles]
  );

  const addNewRole = useCallback(
    (value, resetForm) => {
      if (value === '' || inRolesArray(value)) {
        return;
      }

      dispatch(addLocationRole(value));

      setFieldValue(roleFieldValue, value);
      onOpenToast({ roleValue: value });
      onCloseAddNewRoleModal();
      resetForm();
    },
    [
      inRolesArray,
      onCloseAddNewRoleModal,
      onOpenToast,
      roleFieldValue,
      setFieldValue,
      dispatch,
    ]
  );

  const handleOnTabClose = useCallback(
    ev => {
      ev.preventDefault();
      if (formHasContent(values)) ev.returnValue = '';
    },
    [values]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleOnTabClose);

    return () => window.removeEventListener('beforeunload', handleOnTabClose);
  }, [handleOnTabClose]);

  const showOnboardingSection =
    isPayrollEnrolled ||
    (isAIOLocation && packetProps.newHireOnboardingActivated);

  return (
    <>
      <AddNewRoleModal inRolesArray={inRolesArray} onAddNewRole={addNewRole} />
      <ContactSection
        showContactInfoRequired={!showOnboardingSection}
        contactInfoRequired={contactInfoRequired}
        formFilloutRecommended={formFilloutRecommended}
        onSetIsAdding={onSetIsAdding}
        onSetRehireId={onSetRehireId}
        onSetHasEmailError={onSetHasEmailError}
        onSetHasPhoneError={onSetHasPhoneError}
        contactSectionRef={contactSectionRef}
        onSetRehireLevel={onSetRehireLevel}
        isPayrollEnrolled={isPayrollEnrolled}
        currentTeamMember={currentTeamMember}
      />
      <hr style={HR_STYLE} />
      <JobDetailsSection
        companyAndSelectedRoles={companyAndSelectedRoles}
        isPayrollEnrolled={isPayrollEnrolled}
        rehireLevel={rehireLevel}
        selectedRoles={selectedRoles}
        setRoleFieldValue={setRoleFieldValue}
        setSelectedRoles={setSelectedRoles}
      />
      <TimeOffSection />
      {isPayrollEnrolled && (
        <>
          <hr style={HR_STYLE} />
          <PayrollSection />
        </>
      )}
      {showOnboardingSection && (
        <>
          <hr style={HR_STYLE} />
          <OnboardingSection
            contactInfoRequired={contactInfoRequired}
            onboardingSectionRef={onboardingSectionRef}
            isPayrollEnrolled={isPayrollEnrolled}
          />
        </>
      )}
    </>
  );
};

AddEmployeeForm.propTypes = {
  companyRolesArray: PropTypes.object,
  contactInfoRequired: PropTypes.bool.isRequired,
  formFilloutRecommended: PropTypes.bool.isRequired,
  isAIOLocation: PropTypes.bool.isRequired,
  isPayrollEnrolled: PropTypes.bool.isRequired,
  onCloseAddNewRoleModal: PropTypes.func,
  onOpenToast: PropTypes.func,
  onResetContactInfoRequired: PropTypes.func.isRequired,
  onSetHasEmailError: PropTypes.func.isRequired,
  onSetHasPhoneError: PropTypes.func.isRequired,
  onSetIsAdding: PropTypes.func.isRequired,
  onSetIsCheckingEmail: PropTypes.func.isRequired,
  onSetIsCheckingPhone: PropTypes.func.isRequired,
  onSetRehireId: PropTypes.func.isRequired,
  onSetRehireLevel: PropTypes.func.isRequired,
  rehireLevel: PropTypes.string,
};

export default connect(
  state => ({
    companyRolesArray: getCompanyRolesArray(state),
    packetProps: getPacketProps(state),
  }),
  {
    onCloseAddNewRoleModal: closeAddNewRoleModal,
    onOpenToast: openToast,
  }
)(AddEmployeeForm);
