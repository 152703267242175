/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetchJSON, patchJSON, putJSON } from 'api/fetch';

import { resolveError } from 'util/error';

import { locationRoute, userPath } from '../../../api';

import { ROUTES } from './constants';

interface UpdateLocationSettingsParams {
  locationId: number;
  [key: string]: any;
}

interface UpdateUserSettingsParams {
  [key: string]: any;
}

export const fetchListData = (): Promise<any> =>
  fetchJSON(ROUTES.FETCH_DATA).catch(resolveError);

export const patchUpdateLocationSettings = ({
  locationId,
  ...formValues
}: UpdateLocationSettingsParams): Promise<any> =>
  patchJSON(locationRoute(locationId), formValues).catch(resolveError);

export const putUpdateUserSettings = (
  formValues: UpdateUserSettingsParams
): Promise<any> => putJSON(userPath(), formValues).catch(resolveError);
