import * as routes from 'api';
import { putJSON } from 'api/fetch';

import * as sessionSelectors from 'selectors/session';

import {
  createAsyncGetAction,
  createAsyncPostAction,
  createAsyncPutAction,
  withAlerts,
} from 'util/redux';

// # Action Types
export const actionTypes = {
  UPDATE_SESSION: 'SESSION/UPDATE_SESSION',
  UPDATE_EXPERIMENTS: 'SESSION/UPDATE_EXPERIMENTS',
  DISABLE_FIRST_TIME_POPUP_REQUEST: 'SESSION/DISABLE_FIRST_TIME_POPUP_REQUEST',
  DISABLE_FIRST_TIME_POPUP_SUCCESS: 'SESSION/DISABLE_FIRST_TIME_POPUP_SUCCESS',
  DISABLE_FIRST_TIME_POPUP_FAILURE: 'SESSION/DISABLE_FIRST_TIME_POPUP_FAILURE',
  DISABLE_PUBLISH_UPCOMING_SCHEDULES_POPUP_REQUEST:
    'SESSION/DISABLE_PUBLISH_UPCOMING_SCHEDULES_POPUP_REQUEST',
  DISABLE_PUBLISH_UPCOMING_SCHEDULES_POPUP_SUCCESS:
    'SESSION/DISABLE_PUBLISH_UPCOMING_SCHEDULES_POPUP_SUCCESS',
  DISABLE_PUBLISH_UPCOMING_SCHEDULES_POPUP_FAILURE:
    'SESSION/DISABLE_PUBLISH_UPCOMING_SCHEDULES_POPUP_FAILURE',
  DISABLE_APPLICANT_FIRST_TIME_POPUP_REQUEST:
    'SESSION/DISABLE_APPLICANT_FIRST_TIME_POPUP_REQUEST',
  DISABLE_APPLICANT_FIRST_TIME_POPUP_SUCCESS:
    'SESSION/DISABLE_APPLICANT_FIRST_TIME_POPUP_SUCCESS',
  DISABLE_APPLICANT_FIRST_TIME_POPUP_FAILURE:
    'SESSION/DISABLE_APPLICANT_FIRST_TIME_POPUP_FAILURE',
  DISABLE_TIMESHEETS_ZERO_STATE_DRAWER:
    'SESSION/DISABLE_TIMESHEETS_ZERO_STATE_DRAWER',
  UPDATE_COMPANY_PAYROLL_STATE: 'SESSION/UPDATE_COMPANY_PAYROLL_STATE',
  UPDATE_COMPANY_ELIGIBLE_FOR_MULTI_LOCATION_PAYROLL:
    'SESSION/UPDATE_COMPANY_ELIGIBLE_FOR_MULTI_LOCATION_PAYROLL',
  SKIP_FTU_CHECKLIST_STEP: 'SESSION/SKIP_FTU_CHECKLIST_STEP',
  SET_COMPANY_ADDS_EMPLOYEES_MANUALLY:
    'SESSION/SET_COMPANY_ADDS_EMPLOYEES_MANUALLY',
  FETCH_PARTNER_EMPLOYEES_REQUEST: 'SESSION/FETCH_PARTNER_EMPLOYEES_REQUEST',
  FETCH_PARTNER_EMPLOYEES_SUCCESS: 'SESSION/FETCH_PARTNER_EMPLOYEES_SUCCESS',
  FETCH_PARTNER_EMPLOYEES_FAILURE: 'SESSION/FETCH_PARTNER_EMPLOYEES_FAILURE',
  UPDATE_CURRENT_COMPANY: 'SESSION/UPDATE_CURRENT_COMPANY',
  UPDATE_CURRENT_LOCATION: 'SESSION/UPDATE_CURRENT_LOCATION',
  UPDATE_FEATURE_FLAGS: 'SESSION/UPDATE_FEATURE_FLAGS',
  SET_QUICKBOOKS_OAUTH2_AUTH_REQUEST: 'SET_QUICKBOOKS_OAUTH2_AUTH_REQUEST',
  SET_QUICKBOOKS_OAUTH2_AUTH_SUCCESS: 'SET_QUICKBOOKS_OAUTH2_AUTH_SUCCESS',
  SET_QUICKBOOKS_OAUTH2_AUTH_FAILURE: 'SET_QUICKBOOKS_OAUTH2_AUTH_FAILURE',
  DISABLE_QUICKBOOKS_AUTH_MODAL_REQUEST:
    'DISABLE_QUICKBOOKS_AUTH_MODAL_REQUEST',
  DISABLE_QUICKBOOKS_AUTH_MODAL_SUCCESS:
    'DISABLE_QUICKBOOKS_AUTH_MODAL_SUCCESS',
  DISABLE_QUICKBOOKS_AUTH_MODAL_FAILURE:
    'DISABLE_QUICKBOOKS_AUTH_MODAL_FAILURE',
  CREATE_ONE_TIME_EVENT_REQUEST: 'CREATE_ONE_TIME_EVENT_REQUEST',
  CREATE_ONE_TIME_EVENT_SUCCESS: 'CREATE_ONE_TIME_EVENT_SUCCESS',
  CREATE_ONE_TIME_EVENT_FAILURE: 'CREATE_ONE_TIME_EVENT_FAILURE',
  CREATE_MONETIZATION_CTA_EVENT_REQUEST:
    'CREATE_MONETIZATION_CTA_EVENT_REQUEST',
  CREATE_MONETIZATION_CTA_EVENT_SUCCESS:
    'CREATE_MONETIZATION_CTA_EVENT_SUCCESS',
  CREATE_MONETIZATION_CTA_EVENT_FAILURE:
    'CREATE_MONETIZATION_CTA_EVENT_FAILURE',
  UPDATE_FEATURE_THROTTLING_REQUEST: 'UPDATE_FEATURE_THROTTLING_REQUEST',
  UPDATE_FEATURE_THROTTLING_SUCCESS: 'UPDATE_FEATURE_THROTTLING_SUCCESS',
  UPDATE_FEATURE_THROTTLING_FAILURE: 'UPDATE_FEATURE_THROTTLING_FAILURE',
  SET_ANNOUNCEMENT_CAN_SHOW_TO_FALSE: 'SET_ANNOUNCEMENT_CAN_SHOW_TO_FALSE',
  REFRESH_ANNOUNCEMENTS_TARGET_PATH_MATCH:
    'REFRESH_ANNOUNCEMENTS_TARGET_PATH_MATCH',
  CLOSE_PASSWORD_RESET_MODAL: 'SESSION/CLOSE_PASSWORD_RESET_MODAL',
  SET_LAST_PASSWORD_RESET: 'SESSION/SET_LAST_PASSWORD_RESET',
  COMPLETE_COMPANY_ONE_TIME_EVENT: 'SESSION/COMPLETE_COMPANY_ONE_TIME_EVENT',
  COMPLETE_USER_ONE_TIME_EVENT: 'SESSION/COMPLETE_USER_ONE_TIME_EVENT',
  COMPLETE_LOCATION_ONE_TIME_EVENT: 'SESSION/COMPLETE_LOCATION_ONE_TIME_EVENT',
  FORCE_SHOW_SCRIM: 'SESSION/FORCE_SHOW_SCRIM',
  TOGGLE_HEALTH_QUESTIONS_ENABLED: 'SESSION/TOGGLE_HEALTH_QUESTIONS_ENABLED',
  UPDATE_PROVIDER_INFO: 'SESSION/UPDATE_PROVIDER_INFO',
  UPDATE_GOOGLE_API_SCRIPT_LOADED: 'SESSION/UPDATE_GOOGLE_API_SCRIPT_LOADED',
  UPDATE_LEFT_NAVIGATION: 'SESSION/UPDATE_LEFT_NAVIGATION',
  UPDATE_PAYROLL_FLAGS: 'SESSION/UPDATE_PAYROLL_FLAGS',
  UPDATE_PAYROLL_PAYMENT_ATTEMPTS_INFO:
    'SESSION/UPDATE_PAYROLL_PAYMENT_ATTEMPTS_INFO',
  UPDATE_PAYROLL_QUIZ_BANNER: 'SESSION/UPDATE_PAYROLL_QUIZ_BANNER',
  UPDATE_AUTO_PAYROLL_FEATURE_ENABLED:
    'SESSION/UPDATE_AUTO_PAYROLL_FEATURE_ENABLED',
  UPDATE_ACTIVE_USERS_COUNT: 'SESSION/UPDATE_ACTIVE_USERS_COUNT',
  UPDATE_SECURITY_MFA_DETAILS: 'SESSION/UPDATE_SECURITY_MFA_DETAILS',
  UPDATE_PREDICTED_FAILURE_WIRE_INTENTION_REQUEST:
    'UPDATE_PREDICTED_FAILURE_WIRE_INTENTION_REQUEST',
  UPDATE_PREDICTED_FAILURE_WIRE_INTENTION_SUCCESS:
    'UPDATE_PREDICTED_FAILURE_WIRE_INTENTION_SUCCESS',
  UPDATE_PREDICTED_FAILURE_WIRE_INTENTION_FAILURE:
    'UPDATE_PREDICTED_FAILURE_WIRE_INTENTION_FAILURE',
  UPDATE_PREDICTED_REQUIREMENT_SUCCESS: 'UPDATE_PREDICTED_REQUIREMENT_SUCCESS',
  SET_ACTIVE_TIP_POLICY_ID: 'SESSION/SET_ACTIVE_TIP_POLICY_ID',
};

export const updateLeftNavigation = data => ({
  type: actionTypes.UPDATE_LEFT_NAVIGATION,
  payload: { data },
});
export const updateLastPasswordReset = () => ({
  type: actionTypes.SET_LAST_PASSWORD_RESET,
});

export const fetchPartnerEmployees = () =>
  createAsyncGetAction(routes.partnersJobPermissionsRoute(), [
    actionTypes.FETCH_PARTNER_EMPLOYEES_REQUEST,
    actionTypes.FETCH_PARTNER_EMPLOYEES_SUCCESS,
    actionTypes.FETCH_PARTNER_EMPLOYEES_FAILURE,
  ]);

// # Actions
export const updateSession = data => ({
  type: actionTypes.UPDATE_SESSION,
  payload: data,
});

export const updateSecurityMfaDetails = payload => ({
  payload,
  type: actionTypes.UPDATE_SECURITY_MFA_DETAILS,
});

export const updateExperiments = data => ({
  type: actionTypes.UPDATE_EXPERIMENTS,
  payload: data,
});

export const updateCurrentCompany = payload => ({
  type: actionTypes.UPDATE_CURRENT_COMPANY,
  payload,
});

export const updateCurrentLocation = payload => ({
  type: actionTypes.UPDATE_CURRENT_LOCATION,
  payload,
});

export const updateFeatureFlags = payload => ({
  type: actionTypes.UPDATE_FEATURE_FLAGS,
  payload,
});

export const updatePayrollPaymentAttemptsInfo = payload => ({
  type: actionTypes.UPDATE_PAYROLL_PAYMENT_ATTEMPTS_INFO,
  payload,
});

export const updateActiveUserCountByOne = payload => ({
  type: actionTypes.UPDATE_ACTIVE_USERS_COUNT,
  payload,
});

export const createMonetizationCtaEvent = eventType =>
  createAsyncPostAction(
    routes.monetizationCtaRoute(),
    [
      actionTypes.CREATE_MONETIZATION_CTA_EVENT_REQUEST,
      actionTypes.CREATE_MONETIZATION_CTA_EVENT_SUCCESS,
      actionTypes.CREATE_MONETIZATION_CTA_EVENT_FAILURE,
    ],
    {
      body: { monetization_cta: { cta_type: eventType } },
    }
  );

// If you're adding new keys, please make sure to also add the key here:
// lib/classes/first_time_popups.rb
export const disableFirstTimePopup = (key, callback) =>
  withAlerts(
    createAsyncPutAction(
      routes.disableFTUPopup(),
      [
        { type: actionTypes.DISABLE_FIRST_TIME_POPUP_REQUEST, meta: { key } },
        { type: actionTypes.DISABLE_FIRST_TIME_POPUP_SUCCESS, meta: { key } },
        { type: actionTypes.DISABLE_FIRST_TIME_POPUP_FAILURE, meta: { key } },
      ],
      { body: { key } }
    ),
    {
      onSuccess: callback,
    }
  );

export const disableQuickbooksAuthModal = () =>
  withAlerts(
    createAsyncPostAction(
      routes.disableQBAuthModal(),
      [
        { type: actionTypes.DISABLE_QUICKBOOKS_AUTH_MODAL_REQUEST },
        { type: actionTypes.DISABLE_QUICKBOOKS_AUTH_MODAL_SUCCESS },
        { type: actionTypes.DISABLE_QUICKBOOKS_AUTH_MODAL_FAILURE },
      ],
      {}
    )
  );

export const setExperimentValue = (experimentName, experimentValue) =>
  putJSON(routes.setExperimentRoute(), {
    experiment_name: experimentName,
    experiment_value: experimentValue,
  });

export const disableOneTimeEvent = key =>
  putJSON('/user/disable_one_time_event/', { key });

// DEPRECATED action. use disableFirstTimePopup instead of this one
// If you're adding new keys, please make sure to also add the key here:
// lib/classes/first_time_popups.rb
export const disableApplicantFirstTimePopup = key =>
  createAsyncPutAction(
    routes.disableFTUPopup(),
    [
      {
        type: actionTypes.DISABLE_APPLICANT_FIRST_TIME_POPUP_REQUEST,
        meta: { key },
      },
      {
        type: actionTypes.DISABLE_APPLICANT_FIRST_TIME_POPUP_SUCCESS,
        meta: { key },
      },
      {
        type: actionTypes.DISABLE_APPLICANT_FIRST_TIME_POPUP_FAILURE,
        meta: { key },
      },
    ],
    {
      body: { key },
      bailout: state =>
        sessionSelectors.getCurrentApplicantFirstTimePopupShown(state, {
          popupKey: key,
        }),
    }
  );

export const setCompanyAddsEmployeesManually = () => {
  putJSON(routes.setAddsEmployeesManuallyRoute(), {
    adds_employees_manually: true,
  });

  return { type: actionTypes.SET_COMPANY_ADDS_EMPLOYEES_MANUALLY };
};

export const setFeatureThrottling = (feature, expirationTimeInSeconds) =>
  createAsyncPostAction(
    routes.featureThrottling(),
    [
      { type: actionTypes.UPDATE_FEATURE_THROTTLING_REQUEST },
      { type: actionTypes.UPDATE_FEATURE_THROTTLING_SUCCESS },
      { type: actionTypes.UPDATE_FEATURE_THROTTLING_FAILURE },
    ],
    {
      body: {
        feature,
        expiration_time_in_seconds: expirationTimeInSeconds,
      },
    }
  );

export const setAnnouncementCanShowToFalse = payload => ({
  type: actionTypes.SET_ANNOUNCEMENT_CAN_SHOW_TO_FALSE,
  payload,
});

export const refreshAnnouncementsTargetPathMatch = payload => ({
  type: actionTypes.REFRESH_ANNOUNCEMENTS_TARGET_PATH_MATCH,
  payload,
});

export const updateProviderInfo = payload => ({
  type: actionTypes.UPDATE_PROVIDER_INFO,
  payload,
});

export const updateCompanyPayrollState = payload => ({
  type: actionTypes.UPDATE_COMPANY_PAYROLL_STATE,
  payload,
});

export const updateCompanyEligibleForMultiLocationPayroll = payload => ({
  type: actionTypes.UPDATE_COMPANY_ELIGIBLE_FOR_MULTI_LOCATION_PAYROLL,
  payload,
});

export const closePasswordResetModal = () => ({
  type: actionTypes.CLOSE_PASSWORD_RESET_MODAL,
});

export const createOneTimeEvent = (eventName, ownerType = 'Account') =>
  createAsyncPostAction(
    routes.oneTimeEventsRoute(),
    [
      actionTypes.CREATE_ONE_TIME_EVENT_REQUEST,
      actionTypes.CREATE_ONE_TIME_EVENT_SUCCESS,
      actionTypes.CREATE_ONE_TIME_EVENT_FAILURE,
    ],
    {
      body: {
        one_time_event: {
          event_name: eventName,
          owner_type: ownerType,
        },
      },
      bailout: state => {
        if (ownerType === 'Location') return false;
        return sessionSelectors[`get${ownerType}OneTimeEventDone`](
          state,
          eventName
        );
      },
    }
  );

export const forceShowScrim = () => ({
  type: actionTypes.FORCE_SHOW_SCRIM,
});

export const toggleHealthQuestionsEnabled = show => ({
  type: actionTypes.TOGGLE_HEALTH_QUESTIONS_ENABLED,
  payload: { show },
});

export const setGoogleApisScriptLoaded = () => ({
  type: actionTypes.UPDATE_GOOGLE_API_SCRIPT_LOADED,
});

export const updatePayrollFeatureFlags = payload => ({
  type: actionTypes.UPDATE_PAYROLL_FLAGS,
  payload,
});

export const updatePayrollQuizBanner = payload => ({
  type: actionTypes.UPDATE_PAYROLL_QUIZ_BANNER,
  payload,
});

export const updateAutoPayrollFeatureEnabled = payload => ({
  type: actionTypes.UPDATE_AUTO_PAYROLL_FEATURE_ENABLED,
  payload,
});

export const updatePredictedFailureWireIntention = companyID =>
  createAsyncPostAction(
    routes.updatePredictedFailureWireIntentionRoute(companyID),
    [
      actionTypes.UPDATE_PREDICTED_FAILURE_WIRE_INTENTION_REQUEST,
      actionTypes.UPDATE_PREDICTED_FAILURE_WIRE_INTENTION_SUCCESS,
      actionTypes.UPDATE_PREDICTED_FAILURE_WIRE_INTENTION_FAILURE,
    ],
    {
      body: {
        company_id: companyID,
      },
    }
  );

export const updatePredictedFundingFailureRequirement = () => ({
  type: actionTypes.UPDATE_PREDICTED_REQUIREMENT_SUCCESS,
});

export const setActiveTipPolicyId = payload => ({
  type: actionTypes.SET_ACTIVE_TIP_POLICY_ID,
  payload,
});
