import { df, moment } from 'util/dateTime';
import { capitalizeFirstLetter } from 'util/formatter';
import { toI18n } from 'util/i18n';

import { PendingState, ShiftWithLocation } from '../slice';

const I18N_KEY = 'dashboard.user.shift_trade_requests';

interface TradeableShift {
  start_at: string;
  end_at: string;
  role_name: string | null;
}

export const durationKey = (hours: number, minutes: number): string => {
  if (hours === 0) return `${I18N_KEY}.minutes`;
  if (minutes === 0)
    return hours === 1 ? `${I18N_KEY}.hour` : `${I18N_KEY}.hours`;
  return hours === 1
    ? `${I18N_KEY}.hour_and_minutes`
    : `${I18N_KEY}.hours_and_minutes`;
};

export const formatShift = (shift: ShiftWithLocation | TradeableShift) => {
  const startAt = moment(shift.start_at);
  const endAt = moment(shift.end_at);

  const durationMinutes = endAt.diff(startAt, 'minutes');
  const hours = Math.floor(durationMinutes / 60);
  const minutes = durationMinutes % 60;

  const durationStr = toI18n(durationKey(hours, minutes), {
    props: { hours, minutes },
  });

  const roleName = shift.role_name ? `(${shift.role_name})` : '';

  return {
    date: startAt.format(df('pretty_more_commas')),
    time: `${startAt.format(df('time_short'))} - ${endAt.format(
      df('time_short')
    )}`,
    isTradable: startAt >= moment(),
    duration: durationStr,
    roleName,
  };
};

export const stateToPillVariant = (
  state: PendingState,
  expired?: boolean
): 'primary' | 'error' | 'success' | 'warning' => {
  if (expired) return 'error';

  switch (state) {
    case 'pending':
      return 'primary';
    case 'rejected':
      return 'error';
    case 'accepted':
      return 'warning';
    case 'approved':
      return 'success';
    default:
      return 'primary';
  }
};

export const getPendingState = (
  state: PendingState,
  expired?: boolean
): string => {
  if (expired) return 'Expired';

  if (state === 'accepted') {
    return toI18n(`${I18N_KEY}.pending_manager_approval`);
  }

  return capitalizeFirstLetter(state);
};

interface RequestHeaderProps {
  type: 'incoming' | 'outgoing';
  isTrade: boolean;
  requesterName: string;
  receiverName: string;
}

export const getRequestHeader = ({
  type,
  isTrade,
  requesterName,
  receiverName,
}: RequestHeaderProps) => {
  const REQUEST_I18N_KEYS = {
    incoming: {
      trade: `${I18N_KEY}.trade.pending`,
      cover: `${I18N_KEY}.cover.pending`,
    },
    outgoing: {
      trade: `${I18N_KEY}.you_would_like_to_trade_with`,
      cover: `${I18N_KEY}.you_would_like_to_cover_shift`,
    },
  } as const;

  return {
    headerI18nKey: REQUEST_I18N_KEYS[type][isTrade ? 'trade' : 'cover'],
    headerI18nProps: {
      name: type === 'incoming' ? requesterName : receiverName,
    },
  };
};
