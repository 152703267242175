import './TrialAwarenessUpgradeButton.scss';

import React, { useCallback } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';

import GoToManagePlanPageButton from 'features/managePlan/GoToManagePlanPageButton';
import useGoToManagePlanPage from 'features/productGrowth/hooks/useGoToManagePlanPage';

import { Desktop } from 'components/MediaQuery';

import { cxHelpers } from 'util/className';
import { formatDate } from 'util/dateTime';
import { toI18n } from 'util/i18n';
import { trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';

const { cx, cxEl } = cxHelpers('TrialAwarenessUpgradeButton');

const TrialAwarenessUpgradeButton = ({
  onCollapseLeftNav,
  activeTrialEndDate,
}) => {
  const upgradeButtonText = toI18n('nav_links.tiers.trial_awareness.see_plans');

  const handleClick = useCallback(() => {
    trackUxEvent({
      actionType: TRACK_ACTION_TYPES.CLICK,
      productArea: PRODUCT_AREAS.NAVIGATION,
      eventAction: EVENT_ACTIONS.BUTTON_CLICKED,
      eventCategory: EVENT_CATEGORIES.TRIAL_INDICATOR,
      properties: { button_text: upgradeButtonText },
    });
    onCollapseLeftNav?.();
  }, [onCollapseLeftNav, upgradeButtonText]);

  const { handleOnClick } = useGoToManagePlanPage({
    onClick: handleClick,
  });

  return (
    <Desktop>
      {desktop =>
        desktop && (
          <Box
            flex
            vcenter
            pv={5}
            ph={10}
            gap={10}
            bradiuss
            bgcolor="mono0"
            className={cx()}
          >
            <div onClick={handleOnClick} className={cxEl('clickable-link')}>
              <Text color="primary700" variant="bodySmBoldTight">
                {toI18n('nav_links.tiers.trial_awareness.trial_end', {
                  props: {
                    date: formatDate(activeTrialEndDate, 'month_day_ordinal'),
                  },
                })}
              </Text>
            </div>
            <GoToManagePlanPageButton
              size="small"
              onClick={handleClick}
              id={cxEl('manage_plan')}
              baseComponent="designbase"
            >
              {upgradeButtonText}
            </GoToManagePlanPageButton>
          </Box>
        )
      }
    </Desktop>
  );
};

export default TrialAwarenessUpgradeButton;
