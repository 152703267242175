export const SLICE_NAME = 'sidePanel';

export const SIDE_PANELS = {
  GET_HELP_PANEL: 'GET_HELP_PANEL',
  AUTHORIZED_SIGNER_PANEL: 'AUTHORIZED_SIGNER_PANEL',
  DBA_PANEL: 'DBA_PANEL',
  REQUIRED_FORMATTING_PANEL: 'REQUIRED_FORMATTING_PANEL',
  CONTRACTOR_W2_DIFFERENCE_PANEL: 'CONTRACTOR_W2_DIFFERENCE_PANEL',
  PAYROLL_TEAM_MEMBERS_ONBOARD_PREFERENCE_PANEL:
    'PAYROLL_TEAM_MEMBERS_ONBOARD_PREFERENCE_PANEL',
  IMPLEMENTATION_DASHBOARD_PANEL: 'IMPLEMENTATION_DASHBOARD_PANEL',
  PAYROLL_SUPPORTED_DOCUMENTS_PANEL: 'PAYROLL_SUPPORTED_DOCUMENTS_PANEL',
  PAYROLL_SUPPORTED_INDUSTRY_TYPES_PANEL:
    'PAYROLL_SUPPORTED_INDUSTRY_TYPES_PANEL',
};
