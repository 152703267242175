import { deleteJSON, fetchJSON, postFormData, postJSON } from 'api/fetch';

import { resolveError } from 'util/error';

export const postPayrollFirstPayDate = (start_date: string) =>
  postJSON('/payroll/post_signup/first_payday', {
    start_date,
  }).catch(resolveError);

export const postUploadDocument = (document: File) =>
  postFormData('/payroll/post_signup/upload_document', {
    document,
  });

export const validateMarketingDiscountCoupon = ({ code }: { code: string }) =>
  postJSON(`/growth/marketing_discount_coupons/validate?code=${code}`);

export const getMarketingDiscountCoupon = () =>
  fetchJSON('/growth/marketing_discount_coupons/attached_coupon');

export const getTranslatedMarketingDiscountCoupon = ({
  code,
  planName,
}: {
  [key: string]: string;
}) =>
  fetchJSON(
    `/growth/marketing_discount_coupons/translate?code=${code}&plan_name=${planName}`
  );

export const unattachMarketingDiscountCoupon = () =>
  deleteJSON('/growth/marketing_discount_coupons');
