import { combineReducers } from '@reduxjs/toolkit';

import { reducer as jobApplications } from './features/jobApplications/slice';
import { reducer as jobDescription } from './features/jobDescription/slice';
import { reducer as jobRequests } from './features/jobRequests/slice';

export const reducer = combineReducers({
  jobApplications,
  jobRequests,
  jobDescription,
});
