import { batch } from 'react-redux';

import { deleteRow, updateRow } from 'actions/timesheets';

import { getColumnVisibility } from 'selectors/timecard';

import { getDailyReviewRowIds } from 'features/timesheets/TimesheetsPage/dailyReview/selectors';
import { actions as dailyReviewActions } from 'features/timesheets/TimesheetsPage/dailyReview/slice';
import { updateDailyReviewRow } from 'features/timesheets/TimesheetsPage/dailyReview/thunks';
import {
  editTimecard,
  timecardModalActions,
} from 'features/timesheets/TimesheetsPage/TimecardModal/slices';
import {
  convertOldShiftDataToNewFormat,
  determineContext,
} from 'features/timesheets/TimesheetsPage/TimecardModal/utils';
export const actionTypes = {
  SUBMIT_CHANGES_SUCCESS: 'TIMECARD/SUBMIT_CHANGES_SUCCESS',
  ENABLE_EDIT_MODE: 'TIMECARD/ENABLE_EDIT_MODE',
};

export const enableEditMode = (fieldName, forceUpdate) => ({
  type: actionTypes.ENABLE_EDIT_MODE,
  payload: { fieldName, forceUpdate },
});

export const showTimecardModal =
  (shiftId, eventName, shiftData, timecardOptions = {}) =>
  (dispatch, getState) => {
    const state = getState();

    let shifts;

    if (!shiftId) {
      shifts = [
        {
          date: shiftData.date,
          job_id: shiftData.job_id,
          role_id: shiftData.role_id,
        },
      ];
    } else if (timecardOptions.groupRows) {
      shifts = timecardOptions.groupRows.map(shift =>
        convertOldShiftDataToNewFormat(shift, state)
      );
    } else if (timecardOptions.context === 'dailyReview') {
      const sortedRowIds = getDailyReviewRowIds(state);

      shifts = sortedRowIds
        .map(rowId =>
          state
            .getIn(['dailyReview', 'rows'])
            .find(row => row.get('id') === rowId)
        )
        .toJS();
    } else if (shiftData) {
      shifts = [shiftData];
    } else {
      shifts = [{ id: shiftId }];
    }

    dispatch(
      timecardModalActions.openTimecardModal({
        shifts,
        backboneEventName: eventName,
        currentShiftId: shiftId,
        hideTipsSection:
          !getColumnVisibility(state, 'cash_tips') &&
          !getColumnVisibility(state, 'credit_tips'),
        startingPath: timecardOptions.startingPath,
        expandBreaks: timecardOptions.expandBreaks,
        editMode: timecardOptions.editMode,
        autofillTimes: timecardOptions.autofillTimes,
        autofillBreaks: timecardOptions.autofillBreaks,
        autofillBreaksInfo: timecardOptions.autofillBreaksInfo,
      })
    );

    if (timecardOptions.editMode || !shiftId) {
      dispatch(editTimecard());
    }
  };

export const fixDuplicate =
  (scheduledShiftId, shiftId, originPath) => dispatch => {
    batch(() => {
      const context = determineContext(originPath);
      if (context === 'daily_review') {
        dispatch(dailyReviewActions.deleteDailyReviewRow(shiftId));
        dispatch(updateDailyReviewRow(scheduledShiftId));
      } else {
        dispatch(updateRow(scheduledShiftId));
        dispatch(deleteRow(shiftId));
      }
    });
  };
