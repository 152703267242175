import { fromJS, Map, OrderedMap } from 'immutable';
import { uniqueId } from 'lodash';

import { buildInitialFormState, INITIAL_FIELD_STATE } from 'util/forms';
import { toI18n } from 'util/i18n';

export const NAMESPACE = 'EMPLOYEE_FORM';
export const EMPLOYEE_FORM_NAME = NAMESPACE;

export const OPTION_TYPES = {
  add: toI18n('match_employees.option_types.add'),
  new: toI18n('match_employees.option_types.new'),
  assign: toI18n('match_employees.option_types.assign'),
  skip: toI18n('match_employees.option_types.skip'),
};

export const convertLabel = (name, meta, data = {}) =>
  JSON.stringify({ name, meta, ...data });

export const OLD_TERMINATE_EMPLOYEE_MODAL_TYPE = 'OLD_TERMINATE_EMPLOYEE_MODAL';
export const PAY_ANYWHERE_WARNING_MODAL_TYPE =
  'PAY_ANYWHERE_WARNING_MODAL_TYPE';
export const TERMINATION_FORM_NAME = 'TERMINATE_EMPLOYEE_FORM';

export const INFO_SECTION_FIELDS = [
  { name: 'first_name', label: 'First Name' },
  { name: 'last_name', label: 'Last Name' },
  { name: 'email', label: 'Email' },
  { name: 'phone', label: 'Phone' },
  { name: 'date_of_birth', label: 'Birthday' },
];

export const INFO_SECTION_FIELD_NAMES = INFO_SECTION_FIELDS.map(
  field => field.name
);

export const PHONE_NUMBER_IN_USE_ERROR =
  'That phone number is already in use. ' +
  'Please contact Customer Support by emailing help@joinhomebase.com.';

export const BELONGS_TO_CURRENT_COMPANY_ERRORS = {
  phone: 'A user with that phone number already exists at this company.',
  email: 'A user with that email address already exists at this company.',
};

export const INITIAL_EMPLOYEE_FORM_STATE = buildInitialFormState(
  {
    phone: INITIAL_FIELD_STATE,
    email: INITIAL_FIELD_STATE,
    date_of_birth: INITIAL_FIELD_STATE,
    emergency_contact: {
      full_name: INITIAL_FIELD_STATE,
      phone: INITIAL_FIELD_STATE,
    },
    certificates: OrderedMap(),
    job_attributes: OrderedMap(),
    send_invite: INITIAL_FIELD_STATE.merge({ value: true }),
    address: {
      address_1: INITIAL_FIELD_STATE,
      address_2: INITIAL_FIELD_STATE,
      city: INITIAL_FIELD_STATE,
      state: INITIAL_FIELD_STATE,
      zip: INITIAL_FIELD_STATE,
      country_code: INITIAL_FIELD_STATE,
    },
  },
  {
    matchingUser: Map(),
  }
);

export const INITIAL_JOB_ATTRIBUTES_STATE = fromJS({
  archived_at: INITIAL_FIELD_STATE,
  claim_open_shift: INITIAL_FIELD_STATE.merge({ value: true }),
  default_role_name: INITIAL_FIELD_STATE,
  enabled: INITIAL_FIELD_STATE,
  excluded_late_alert: INITIAL_FIELD_STATE.merge({ value: false }),
  excluded_timeclock_errors: INITIAL_FIELD_STATE.merge({ value: false }),
  in_schedule: INITIAL_FIELD_STATE.merge({ value: true }),
  job_id: INITIAL_FIELD_STATE,
  level: INITIAL_FIELD_STATE.merge({ value: 'Employee' }),
  location_name: INITIAL_FIELD_STATE,
  payroll_id: INITIAL_FIELD_STATE,
  permanent: INITIAL_FIELD_STATE,
  pin: INITIAL_FIELD_STATE,
  role_wages: [],
  historical_wages: [],
  wage_rate: INITIAL_FIELD_STATE,
  wage_type: INITIAL_FIELD_STATE.merge({ value: 0 }),
});

export const TERMINATION_FORM_VIEW_OPTIONS = {
  FORM: 'FORM',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const TERMINATION_FORM_ALL_LOCATIONS_OPTION_DATA = {
  value: 'all',
  label: 'All',
};

export const INITIAL_TERMINATION_FORM_STATE = fromJS({
  fields: {
    reason: INITIAL_FIELD_STATE,
    notes: INITIAL_FIELD_STATE,
    rehire: INITIAL_FIELD_STATE.merge({ value: true, initial: true }),
    job_id: INITIAL_FIELD_STATE,
  },
  errors: Map(),
  initial: Map(),
  isSubmitting: false,
  currentView: TERMINATION_FORM_VIEW_OPTIONS.FORM,
  hireReplacementRedirectPath: null,
  lastHireDate: null,
});

export const NEW_NOTE_ID = uniqueId('NEW_JOB_NOTE');

export const WAGE_TYPE_HOURLY = 0;
export const WAGE_TYPE_ANNUAL = 1;

export const WAGE_TYPE_OPTIONS = [
  { value: 0, label: 'Hourly' },
  { value: 1, label: 'Annual' },
];

export const NEW_USER_ID = 'new';
export const SKIP_USER_ID = 'skip';

export const ADD_DOCUMENT_MODAL_TYPE = 'EMPLOYEE_VIEW_ADD_DOCUMENT_MODAL';
