import React, { PureComponent } from 'react';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import { TabletAndBelow } from 'components/MediaQuery';
import Text from 'components/Text';

import cxHelpers from 'util/className';
import { trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
} from 'util/tracking_constants';

import { DASHBOARD_ROUTES } from '../../constants';
import withNavItemActive from '../../hocs/withNavItemActive';
import withNavItemData from '../../hocs/withNavItemData';
import NavItem from '../NavItem';
import NavItemWithSubNav from '../NavItemWithSubNav';

@withNavItemActive(DASHBOARD_ROUTES)
@withNavItemData('dashboard')
@cxHelpers('DashboardNavItem')
export default class DashboardNavItem extends PureComponent {
  static propTypes = {
    url: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  };

  handleClick = () => {
    trackUxEvent({
      productArea: PRODUCT_AREAS.NAVIGATION,
      eventCategory: EVENT_CATEGORIES.NAV,
      eventAction: EVENT_ACTIONS.HOMEBASE_TAB_CLICKED,
    });
  };

  render() {
    const { currentRoute, url, ...props } = omit(this.props, 'whiteText');
    const Component = props.menuItems.size > 0 ? NavItemWithSubNav : NavItem;

    return (
      <Component
        {...props}
        href={url}
        currentRoute={currentRoute}
        className={this.cx()}
        onClick={this.handleClick}
      >
        <TabletAndBelow>
          {mobile =>
            mobile ? (
              <Text fs14 i18n="nav_links.dashboard.top_level" />
            ) : (
              <Box mrxs>
                <Text white fs24 fw700>
                  homebase
                </Text>
              </Box>
            )
          }
        </TabletAndBelow>
      </Component>
    );
  }
}
