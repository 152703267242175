import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';

import {
  getCanManageTier,
  getCurrentCompanyHasBasicLocation,
  getCurrentCompanyHasMultipleLocations,
  getCurrentLocationTierId,
  getCurrentUserIsManagerial,
  getCurrentUserIsOwner,
  getGhostEmployeeCapV1RolloutEnabled,
  getGhostPackagingManagePlanPageExperimentEnabled,
  getHasActiveTrialPeriod,
  getMultiLocationEnforcementRolloutEnabled,
  getPriceIncreaseExperimentEnrolled,
  selectActiveUsersCount,
} from 'selectors/session';

import {
  selectLocationBiller,
  selectTierBasic,
} from 'features/biller/selectors';

import { daysDiffFromToday, momentDate } from 'util/dateTime';

export const selectIsCurrentLocationTierBasic = createSelector(
  getCurrentLocationTierId,
  selectTierBasic,
  (currentLocationTierId, basicTier) =>
    currentLocationTierId === basicTier?.get('id')
);

export const selectEmployeeCapLimit = state =>
  state.getIn(['session', 'enforcement', 'employeeCapLimit']);

export const selectExceedsCapLimit = createSelector(
  selectActiveUsersCount,
  selectEmployeeCapLimit,
  (activeUsersCount, employeeCapLimit) => activeUsersCount > employeeCapLimit
);

export const selectIsAtOrOverCapLimit = createSelector(
  selectActiveUsersCount,
  selectEmployeeCapLimit,
  (activeUsersCount, employeeCapLimit) => activeUsersCount >= employeeCapLimit
);

export const selectIsAtCapLimit = createSelector(
  selectActiveUsersCount,
  selectEmployeeCapLimit,
  (activeUsersCount, employeeCapLimit) => activeUsersCount === employeeCapLimit
);

export const selectGhostEmployeeCapV1ExperimentValue = state =>
  state.getIn(['session', 'company_experiments', 'ghost_employee_cap_v1']);

export const selectGhostEmployeeCapV2ExperimentValue = state =>
  state.getIn(['session', 'company_experiments', 'ghost_employee_cap_v2']);

export const selectMultiLocationEnforcementExperimentValue = state =>
  state.getIn(['session', 'company_experiments', 'multi_location_enforcement']);

export const selectMultiLocationEnforcementDeadLine = state => {
  const value = selectMultiLocationEnforcementExperimentValue(state);
  return state.getIn([
    'session',
    'enforcement',
    'deadlines',
    'multi_location_enforcement',
    `cohort_${value}`,
  ]);
};

export const selectGhostEmployeeCapV1ExperimentEnabled = state => {
  const experimentValue = selectGhostEmployeeCapV1ExperimentValue(state);

  return experimentValue && experimentValue !== '0';
};

export const selectGhostEmployeeCapV2ExperimentEnabled = state => {
  const experimentValue = selectGhostEmployeeCapV2ExperimentValue(state);

  return experimentValue && experimentValue !== '0';
};

export const selectMultiLocationEnforcementExperimentEnabled = state => {
  const experimentValue = selectMultiLocationEnforcementExperimentValue(state);

  return experimentValue && experimentValue !== '0';
};

export const selectIsPastDeadline = createSelector(
  selectGhostEmployeeCapV1ExperimentValue,
  deadline => daysDiffFromToday(deadline) > -1
);

export const selectIsPastV2Deadline = createSelector(
  selectGhostEmployeeCapV2ExperimentValue,
  deadline => daysDiffFromToday(deadline) > -1
);

export const selectIsLessThan7DaysPastV2Deadline = createSelector(
  selectGhostEmployeeCapV2ExperimentValue,
  deadline => {
    const isPastDeadline = daysDiffFromToday(deadline);

    return isPastDeadline > -1 && isPastDeadline < 7;
  }
);

const selectIsGhostEmployeeCapV1Enabled = createSelector(
  getGhostEmployeeCapV1RolloutEnabled,
  selectGhostEmployeeCapV1ExperimentEnabled,
  (ghostEmployeeCapV1RolloutEnabled, ghostEmployeeCapV1ExperimentEnabled) =>
    ghostEmployeeCapV1RolloutEnabled && ghostEmployeeCapV1ExperimentEnabled
);

const selectIsGhostEmployeeCapV2Enabled = createSelector(
  getGhostEmployeeCapV1RolloutEnabled,
  selectGhostEmployeeCapV2ExperimentEnabled,
  (ghostEmployeeCapV1RolloutEnabled, ghostEmployeeCapV2ExperimentEnabled) =>
    ghostEmployeeCapV1RolloutEnabled && ghostEmployeeCapV2ExperimentEnabled
);

export const selectMultiLocationEnforcementEnabled = createSelector(
  getMultiLocationEnforcementRolloutEnabled,
  selectMultiLocationEnforcementExperimentEnabled,
  (
    multiLocationEnforcementRolloutEnabled,
    multiLocationEnforcementExperimentEnabled
  ) =>
    multiLocationEnforcementRolloutEnabled &&
    multiLocationEnforcementExperimentEnabled
);

export const selectMultiLocationEnforcementIsPastDeadline = createSelector(
  selectMultiLocationEnforcementDeadLine,
  deadline =>
    deadline && daysDiffFromToday(momentDate(deadline, 'MM-DD-YYYY')) > -1
);

export const selectShouldEnforceMultiLocationPayment = createSelector(
  selectLocationBiller,
  selectMultiLocationEnforcementEnabled,
  selectMultiLocationEnforcementIsPastDeadline,
  getCurrentCompanyHasMultipleLocations,
  getCurrentCompanyHasBasicLocation,
  getCurrentUserIsManagerial,
  (
    locationBiller,
    multiLocationEnforcementEnabled,
    multiLocationEnforcementIsPastDeadline,
    currentCompanyHasMultipleLocations,
    currentCompanyHasBasicLocation,
    currentUserIsManagerial
  ) =>
    locationBiller === 'Stripe' &&
    multiLocationEnforcementEnabled &&
    multiLocationEnforcementIsPastDeadline === false &&
    currentCompanyHasMultipleLocations &&
    currentCompanyHasBasicLocation &&
    currentUserIsManagerial
);

export const selectEnforcementBannerVariant = createSelector(
  getCurrentCompanyHasMultipleLocations,
  getHasActiveTrialPeriod,
  selectIsCurrentLocationTierBasic,
  selectExceedsCapLimit,
  getCurrentUserIsOwner,
  selectIsGhostEmployeeCapV1Enabled,
  selectIsPastDeadline,
  selectIsPastV2Deadline,
  selectIsGhostEmployeeCapV2Enabled,
  selectShouldEnforceMultiLocationPayment,
  (
    currentCompanyHasMultipleLocations,
    hasActiveTrialPeriod,
    isCurrentLocationTierBasic,
    exceedsCapLimit,
    currentUserIsOwner,
    isGhostEmployeeCapV1Enabled,
    isPastDeadline,
    isPastV2Deadline,
    isGhostEmployeeCapV2Enabled,
    shouldEnforceMultiLocationPayment
  ) => {
    if (shouldEnforceMultiLocationPayment) {
      return 'multiLocation';
    }

    if (
      currentCompanyHasMultipleLocations ||
      hasActiveTrialPeriod ||
      !isCurrentLocationTierBasic ||
      !exceedsCapLimit ||
      !currentUserIsOwner
    ) {
      return null;
    }

    if (isGhostEmployeeCapV1Enabled && !isPastDeadline) {
      return 'preDeadline';
    }

    if (!isPastV2Deadline && isGhostEmployeeCapV2Enabled) {
      return 'preDeadlineV2';
    }

    return null;
  }
);

const selectCanSeeEnforcementDialog = createSelector(
  selectIsGhostEmployeeCapV1Enabled,
  getGhostPackagingManagePlanPageExperimentEnabled,
  selectIsPastDeadline,
  selectIsCurrentLocationTierBasic,
  selectIsGhostEmployeeCapV2Enabled,
  selectIsPastV2Deadline,
  getCanManageTier,
  getPriceIncreaseExperimentEnrolled,
  (
    isGhostEmployeeCapV1Enabled,
    ghostPackagingManagePlanPageExperimentEnabled,
    isPastDeadline,
    isCurrentLocationTierBasic,
    isGhostEmployeeCapV2Enabled,
    isPastV2Deadline,
    canManageTier,
    priceIncreaseExperimentEnrolled
  ) =>
    isCurrentLocationTierBasic &&
    canManageTier &&
    (ghostPackagingManagePlanPageExperimentEnabled ||
      (isGhostEmployeeCapV1Enabled && isPastDeadline) ||
      (isGhostEmployeeCapV2Enabled && isPastV2Deadline) ||
      priceIncreaseExperimentEnrolled)
);

export const selectShouldShowEnforcementDialog = createSelector(
  selectIsAtOrOverCapLimit,
  selectCanSeeEnforcementDialog,
  (isAtOrOverCapLimit, canSeeEnforcementDialog) =>
    isAtOrOverCapLimit && canSeeEnforcementDialog
);

export const selectShouldShowEnforcementDialogOnNavigation = createSelector(
  selectExceedsCapLimit,
  selectCanSeeEnforcementDialog,
  (exceedsCapLimit, canSeeEnforcementDialog) =>
    exceedsCapLimit && canSeeEnforcementDialog
);

export const selectCanCloseEnforcementDialog = createSelector(
  selectIsGhostEmployeeCapV2Enabled,
  selectIsLessThan7DaysPastV2Deadline,
  selectIsAtCapLimit,
  (isGhostEmployeeCapV2Enabled, isLessThan7DaysPastV2Deadline, isAtCapLimit) =>
    (isGhostEmployeeCapV2Enabled && isLessThan7DaysPastV2Deadline) ||
    isAtCapLimit
);

const selectMultiLocationEnforcementUnsortedLocations = state =>
  state.getIn(['session', 'enforcement', 'locations']).toJS();

export const selectMultiLocationEnforcementLocations = createSelector(
  selectMultiLocationEnforcementUnsortedLocations,
  locations => sortBy(locations, ['tier_id'])
);

const selectProducts = state =>
  state.getIn(['session', 'enforcement', 'mleProducts']).toJS();

export const selectMultiLocationEnforcementProducts = createSelector(
  selectTierBasic,
  selectProducts,
  (basicTier, products) =>
    products.filter(product => product.tier_name !== basicTier?.get('name'))
);
