import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Context } from 'fe-core/UxTracking/types';
import { ToastProps } from 'fe-design-base/molecules/Toast/Toast';

import { SLICE_NAME } from './constants';

export interface GlobalToastState {
  isToastOpen?: boolean;
  content: string | undefined;
  initiatedFrom?: string;
  toastProps?: Partial<ToastProps>;
  uxContext?: Context;
}

export const initialState: GlobalToastState = {
  isToastOpen: false,
  content: undefined,
  initiatedFrom: undefined,
  toastProps: {},
  uxContext: {},
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,

  reducers: {
    openGlobalToast: (state, action: PayloadAction<GlobalToastState>) => {
      state.isToastOpen = true;
      state.content = action.payload.content;
      state.initiatedFrom = action.payload.initiatedFrom;
      state.toastProps = action.payload.toastProps || {};
      state.uxContext = action.payload.uxContext;
    },

    closeGlobalToast: state => {
      state.isToastOpen = false;
      state.content = undefined;
      state.initiatedFrom = undefined;
      state.uxContext = {};
      // we do not reset toastProps to prevent UI from flashing the default state
    },
  },
});

export const { actions, reducer } = slice;
