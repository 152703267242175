interface ErrorTrackingOptions {
  tags?: {
    [key: string]: string;
  };
  extra?: {
    [key: string]: any;
  };
}

interface ErrorTrackingType {
  captureException: (error: any, options?: ErrorTrackingOptions) => void;
  captureMessage: (
    message: string,
    level?: 'debug' | 'info' | 'warning' | 'error' | 'fatal',
    options?: ErrorTrackingOptions
  ) => void;
  setExtra: (key: string, value: any) => void;
}

interface SentryConfig {
  dsn: string;
  release: string;
  environment: string;
  ignoreErrors?: string[];
}

interface SentryUser {
  id: string;
  email: string;
  location_id: string | null;
}

interface SentryType extends ErrorTrackingType {
  init(config: SentryConfig): void;
  setUser(user: SentryUser): void;
}

declare global {
  interface Window {
    errorTrackingService?: ErrorTrackingType;
    Sentry?: SentryType;
  }
}

if (window.Sentry) {
  window.errorTrackingService = window.Sentry;
} else {
  console.warn('Sentry is not initialized on the window object');
}

const getErrorTrackingService = (): ErrorTrackingType | undefined => {
  if (window.errorTrackingService) {
    return window.errorTrackingService;
  }
  console.warn(
    'Error tracking service is not initialized on the window object'
  );
  return undefined;
};

const captureException = (error: unknown, options?: ErrorTrackingOptions) => {
  const service = getErrorTrackingService();
  if (service) {
    service.captureException(error, options);
  }
};

const captureMessage = (
  message: string,
  level: 'debug' | 'info' | 'warning' | 'error' | 'fatal' = 'info',
  options?: ErrorTrackingOptions
) => {
  const service = getErrorTrackingService();
  if (service) {
    service.captureMessage(message, level, options);
  }
};

const setExtra = (key: string, value: any) => {
  const service = getErrorTrackingService();
  if (service) {
    service.setExtra(key, value);
  }
};

export { captureException, captureMessage, setExtra };
