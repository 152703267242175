import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';

import { actionTypes } from 'actions/hiring';

import * as constants from 'features/hiring/constants';

import * as formatter from 'util/formatter';
import * as formsUtil from 'util/forms';

import interviews from './interviews';
import manageApplicants from './manageApplicants';

const ADD_APPLICANTS_MANUALLY_DRAWER_INITIAL_STATE = fromJS({
  defaultLocation: null,
  defaultRole: null,
});

const APPLICATION_QUESTIONS_INITIAL_STATE = fromJS({
  isSubmitting: false,
});

const dashboard = (state = constants.INITIAL_DASHBOARD_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DASHBOARD_DATA_REQUEST:
      return state.merge({ isFetching: true });

    case actionTypes.FETCH_DASHBOARD_DATA_SUCCESS:
      return state.merge({
        isFetching: false,
        companyProfileLogo: action.payload.company_profile_logo_gray,
        companyProfilePath: action.payload.company_profile_path,
        companyProfileTitle: action.payload.company_profile_title,
        textToApplyNumber: action.payload.text_to_apply_number,
        shiftPayEnabled: action.payload.shift_pay_enabled,
        textToApplyCode: action.payload.text_to_apply_code,
        showBlankStateControl: action.payload.show_blank_state_control,
        customRoles: action.payload.custom_roles,
      });

    case actionTypes.FETCH_DASHBOARD_DATA_FAILURE:
      return state.merge({ isFetching: false });

    case actionTypes.CREATE_JOB_POST_REQUEST:
      return state.merge({ isSubmittingJobPosting: true });

    case actionTypes.CREATE_JOB_POST_SUCCESS:
      return state.merge({
        isSubmittingJobPosting: false,
        partnerToggles: action.payload.partner_toggles,
      });

    case actionTypes.UPDATE_JOB_POST_SUCCESS:
      return state.merge({
        partnerToggles: action.payload.partner_toggles,
      });

    case actionTypes.CREATE_JOB_POST_FAILURE:
      return state.merge({ isSubmittingJobPosting: false });

    case actionTypes.CREATE_JOB_POST_BOOST_REQUEST:
      return state.merge({ isLoading: true });

    case actionTypes.CREATE_JOB_POST_BOOST_SUCCESS:
    case actionTypes.CREATE_JOB_POST_BOOST_FAILURE:
      return state.merge({ isLoading: false });

    default:
      return state;
  }
};

const addApplicantsManuallyDrawer = (
  state = ADD_APPLICANTS_MANUALLY_DRAWER_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.ADD_APPLICANTS_MANUALLY_DRAWER:
      return state.merge({
        defaultRole: action.payload.defaultRole,
        defaultLocation: action.payload.defaultLocation,
      });
    default:
      return state;
  }
};

const promote = (state = constants.INITIAL_PROMOTE_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROMOTE_DATA_REQUEST:
      return state.merge({ isFetching: true });

    case actionTypes.FETCH_PROMOTE_DATA_SUCCESS:
      return state.merge({ isFetching: false });

    case actionTypes.FETCH_PROMOTE_DATA_FAILURE:
      return state.merge({ isFetching: false });

    default:
      return state;
  }
};

const companyProfile = (
  state = constants.INITIAL_COMPANY_PROFILE_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.FETCH_COMPANY_PROFILE_REQUEST:
      return state.merge({ isFetching: true });

    case actionTypes.TOGGLE_EDITING_COMPANY_PROFILE_FORM:
      return state.merge({ isEditing: action.payload.isEditing });

    case actionTypes.FETCH_COMPANY_PROFILE_SUCCESS:
      return state.merge({
        isFetching: false,
        canEdit: action.payload.can_edit,
      });

    case actionTypes.FETCH_COMPANY_PROFILE_FAILURE:
      return state.merge({ isFetching: false });

    case actionTypes.UPDATE_COMPANY_PROFILE_SUCCESS:
      return state.set('isEditing', false);

    default:
      return state;
  }
};

const companyProfileForm = (
  state = constants.INITIAL_COMPANY_PROFILE_FORM_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.TOGGLE_EDITING_COMPANY_PROFILE_FORM: {
      if (action.payload.isEditing) {
        return formsUtil.handleInitializeForm(
          constants.INITIAL_COMPANY_PROFILE_FORM_STATE,
          action
        );
      }

      return state;
    }

    case actionTypes.UPDATE_COMPANY_PROFILE_FORM_FIELD:
      return formsUtil.handleUpdateFormField(state, action);

    case actionTypes.UPDATE_COMPANY_PROFILE_REQUEST:
      return formsUtil.handleSubmitRequest(state);

    case actionTypes.UPDATE_COMPANY_PROFILE_FAILURE:
      return formsUtil.handleSubmitFailure(state, action);

    case actionTypes.UPDATE_COMPANY_PROFILE_SUCCESS:
      return state.set('isSubmitting', false);

    default:
      return state;
  }
};

const jobRequestApplicationForm = (
  state = constants.INITIAL_JOB_REQUEST_APPLICATION_FORM_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.SUBMIT_JOB_REQUEST_APPLICATION_FORM_REQUEST:
    case actionTypes.FETCH_JOB_REQUEST_SUCCESS_DATA_REQUEST:
      return state.merge({ isSubmitting: true });

    case actionTypes.SUBMIT_JOB_REQUEST_APPLICATION_FORM_FAILURE:
    case actionTypes.FETCH_JOB_REQUEST_SUCCESS_DATA_SUCCESS:
    case actionTypes.FETCH_JOB_REQUEST_SUCCESS_DATA_FAILURE:
      return state.merge({ isSubmitting: false });

    default:
      return state;
  }
};

const locationApplicationForm = (
  state = constants.INITIAL_LOCATION_APPLICATION_FORM_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.SUBMIT_LOCATION_APPLICATION_FORM_REQUEST:
      return state.merge({ isSubmitting: true });

    case actionTypes.SUBMIT_LOCATION_APPLICATION_FORM_FAILURE:
      return state.merge({ isSubmitting: false });

    default:
      return state;
  }
};

const applicantProfile = (
  state = constants.INITIAL_APPLICANT_PROFILE_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.TOGGLE_EDITING_APPLICANT_PROFILE:
      return state.merge({ isEditing: action.payload.isEditing });

    case actionTypes.FETCH_APPLICANT_PROFILE_SUCCESS:
      return state.merge({
        customRoles: action.payload.custom_roles,
      });

    case actionTypes.SUBMIT_APPLICANT_PROFILE_SUCCESS:
      return state.merge({
        isEditing: false,
        serverErrors: {},
        customRoles: action.payload.custom_roles,
      });

    case actionTypes.SUBMIT_APPLICANT_PROFILE_FAILURE: {
      const serverErrors = formatter.formatServerErrors(
        action.payload.response
      );
      return state.merge({ serverErrors });
    }

    case actionTypes.SET_MOBILE_APPLICANT_PROFILE_TAB_VIEW: {
      return state.merge({
        mobileTabView: action.payload.view,
      });
    }

    default:
      return state;
  }
};

const settings = (state = constants.INITIAL_SETTINGS_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DASHBOARD_DATA_SUCCESS:
      return state.merge({
        verification_complete:
          action.payload.company_settings_whitelisting_verification_complete,
        hiring_settings: action.payload.hiring_settings,
        business_address: action.payload.business_address,
      });

    case actionTypes.FETCH_PROMOTE_DATA_SUCCESS:
    case actionTypes.SUBMIT_WHITELISTING_WIDGET_SUCCESS:
      return state.merge({
        verification_complete:
          action.payload.whitelisting_verification_complete,
        hiring_settings: action.payload.hiring_settings,
        business_address: action.payload.business_address,
      });
    case actionTypes.REQUEST_ADD_APPLICANT_ACCESS_SUCCESS:
      return state.setIn(
        ['hiring_settings', 'add_applicant_manually_state'],
        'requested'
      );
    case actionTypes.CHECK_STATUS_OF_ABILITY_TO_ADD_APPLICANTS_SUCCESS:
      return state.setIn(
        ['hiring_settings', 'add_applicant_manually_state'],
        action.payload.qualified ? 'enabled' : 'disabled'
      );
    default:
      return state;
  }
};

// TODO: decouple this from the Hiring dashboard since these values are global in nature?
const boostUpsell = (state = constants.INITIAL_BOOST_UPSELL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DASHBOARD_DATA_SUCCESS:
    case actionTypes.FETCH_JOB_REQUEST_SUCCESS_DATA_SUCCESS:
      return state.merge({ ...action.payload.job_request_boost });
    default:
      return state;
  }
};

const applicationQuestions = (
  state = APPLICATION_QUESTIONS_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.SUBMIT_APPLICATION_QUESTIONS_REQUEST:
      return state.merge({ isSubmitting: true });
    case actionTypes.SUBMIT_APPLICATION_QUESTIONS_SUCCESS:
      return state.merge({ isSubmitting: false });
    case actionTypes.SUBMIT_APPLICATION_QUESTIONS_FAILURE:
      return state.merge({ isSubmitting: false });
    default:
      return state;
  }
};

export default combineReducers({
  dashboard,
  promote,
  companyProfile,
  companyProfileForm,
  jobRequestApplicationForm,
  locationApplicationForm,
  manageApplicants,
  applicantProfile,
  settings,
  interviews,
  boostUpsell,
  addApplicantsManuallyDrawer,
  applicationQuestions,
});
