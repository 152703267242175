import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgMic = ({
  alt = 'Mic Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <g transform="translate(3, 0)">
      <path
        fill="currentColor"
        d="M9 0a4.501 4.501 0 0 0-4.5 4.5V12a4.501 4.501 0 0 0 9 0V4.5C13.5 2.016 11.484 0 9 0ZM3 10.125C3 9.502 2.498 9 1.875 9S.75 9.502.75 10.125V12a8.253 8.253 0 0 0 7.125 8.175v1.575h-2.25c-.623 0-1.125.502-1.125 1.125S5.002 24 5.625 24h6.75c.623 0 1.125-.502 1.125-1.125s-.502-1.125-1.125-1.125h-2.25v-1.575A8.253 8.253 0 0 0 17.25 12v-1.875c0-.623-.502-1.125-1.125-1.125S15 9.502 15 10.125V12a6 6 0 1 1-12 0v-1.875Z"
      />
    </g>
  </svg>
);
export default SvgMic;
