import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as routes from 'api';
import { fetchJSON } from 'api/fetch';
import { RootState } from 'initializers/types';

import { toI18n } from 'util/i18n';

import { TimeOffPolicyOptions } from './Sections/TimeOffSection/types';

type Category = {
  category: string;
  name: string;
  policies: Policy[];
};

type Policy = {
  id: number;
  name: string;
};

const noPolicyOption = {
  value: 'none',
  label: toI18n('team.employee_profile.time_offs.no_policy'),
};

export const fetchPolicyOptions = createAsyncThunk(
  'addEmployeeForm/fetchPolicyOptions',
  async () => {
    try {
      const response = await fetchJSON(routes.getPoliciesByCategoryRoute());
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const addEmployeeFormSlice = createSlice({
  name: 'addEmployeeForm',
  initialState: {
    policyOptions: [],
    loadingPolicyOptions: false,
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPolicyOptions.pending, state => {
      state.loadingPolicyOptions = true;
    });
    builder.addCase(fetchPolicyOptions.fulfilled, (state, action) => {
      state.loadingPolicyOptions = false;
      state.policyOptions = action.payload.categories;
    });
    builder.addCase(fetchPolicyOptions.rejected, state => {
      state.loadingPolicyOptions = false;
    });
  },
});

export const selectPolicyOptions = (
  state: RootState,
  policyType: 'pto' | 'paid_sick_leave'
): TimeOffPolicyOptions[] => {
  const addEmployeeForm = state.get('addEmployeeForm');
  const options =
    addEmployeeForm?.policyOptions
      .find((category: Category) => category.category === policyType)
      ?.policies.map((policy: Policy) => ({
        value: policy.id,
        label: policy.name,
      })) || [];
  return [noPolicyOption, ...options];
};

export const selectLoadingPolicyOptions = (state: RootState): boolean => {
  const addEmployeeForm = state.get('addEmployeeForm');
  const loading = addEmployeeForm?.loadingPolicyOptions;
  return loading;
};

export const reducer = addEmployeeFormSlice.reducer;
