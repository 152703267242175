import { createSlice } from '@reduxjs/toolkit';

import {
  createPayrollFirstPayDate,
  createUploadDocument,
  SLICE_NAME,
} from './actions';

interface ProductGrowthState {
  isPending: boolean;
  error: Record<string, string | null>;
  success: Record<string, string | null>;
  uploadDocumentIsPending: boolean;
  uploadDocumentError: Record<string, string | null>;
  uploadDocumentSuccess: Record<string, string | null>;
}

export const initialState: ProductGrowthState = {
  isPending: false,
  error: {},
  success: {},
  uploadDocumentIsPending: false,
  uploadDocumentError: {},
  uploadDocumentSuccess: {},
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,

  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      createPayrollFirstPayDate.pending,
      (state: ProductGrowthState) => {
        state.isPending = true;
        state.error = {};
      }
    );

    builder.addCase(createPayrollFirstPayDate.fulfilled, (state, action) => {
      state.isPending = false;
      state.success = { message: action.payload };
      state.error = {};
    });

    builder.addCase(
      createPayrollFirstPayDate.rejected,
      (state: ProductGrowthState, action) => {
        state.isPending = false;
        state.error = { message: action.payload as string };
        state.success = {};
      }
    );

    builder.addCase(createUploadDocument.pending, state => {
      state.uploadDocumentIsPending = true;
      state.uploadDocumentError = {};
    });

    builder.addCase(createUploadDocument.fulfilled, (state, action) => {
      state.uploadDocumentIsPending = false;
      state.uploadDocumentSuccess = { message: action.payload };
      state.uploadDocumentError = {};
    });

    builder.addCase(createUploadDocument.rejected, (state, action) => {
      state.uploadDocumentIsPending = false;
      state.uploadDocumentError = { message: action.payload as string };
      state.uploadDocumentSuccess = {};
    });
  },
});

export const { actions, reducer } = slice;
