import * as routes from 'api';
import { pollWorkerStatus } from 'api/workerStatus';

import * as meteringActions from 'actions/metering';

import { buildGetDrawerOpen } from 'selectors/drawers';
import { getScheduleTemplates } from 'selectors/scheduleBuilder';
import {
  getCurrentLocationPartnerName,
  getPreviousWeeksOnJumpstartDrawerEnabled,
} from 'selectors/session';

import {
  EMPTY_SCHEDULE_DRAWER_KEY,
  PRINT_DRAWER_KEY,
  PUBLISH_DRAWER_KEY,
  TEMPLATES_DRAWER_KEY,
} from 'features/drawers/keys';
import {
  trackPublishDrawerUxEvents,
  trackSuccessBannerEvent,
} from 'features/scheduleBuilder/tracking';

import { df } from 'util/dateTime';
import * as flashNotice from 'util/flashNotice';
import { toI18n } from 'util/i18n';
import {
  createAsyncDeleteAction,
  createAsyncGetAction,
  createAsyncPostAction,
  createAsyncPutAction,
  withAlerts,
} from 'util/redux';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { showUpgradeModal } from 'util/upgrades';

import { toggleExperiment } from '../features/scheduleBuilder/ScheduleBuilderView/actions/toggleExperiment';

export const actionTypes = {
  UPDATE_CURRENT_TEMPLATE: 'SCHEDULE_BUILDER/UPDATE_CURRENT_TEMPLATE',
  COPY_WEEK_REQUEST: 'SCHEDULE_BUILDER/COPY_WEEK_REQUEST',
  COPY_WEEK_SUCCESS: 'SCHEDULE_BUILDER/COPY_WEEK_SUCCESS',
  COPY_WEEK_FAILURE: 'SCHEDULE_BUILDER/COPY_WEEK_FAILURE',
  TOGGLE_PUBLISH_DRAWER: 'SCHEDULE_BUILDER/TOGGLE_PUBLISH_DRAWER',
  TOGGLE_EMPTY_SCHEDULE_DRAWER: 'SCHEDULE_BUILDER/TOGGLE_EMPTY_SCHEDULE_DRAWER',
  TOGGLE_TEMPLATES_DRAWER: 'SCHEDULE_BUILDER/TOGGLE_TEMPLATES_DRAWER',
  TOGGLE_PRINT_DRAWER: 'SCHEDULE_BUILDER/TOGGLE_PRINT_DRAWER',
  TOGGLE_SETTINGS_DRAWER: 'SCHEDULE_BUILDER/TOGGLE_SETTINGS_DRAWER',
  PUBLISH_SCHEDULE_REQUEST: 'SCHEDULE_BUILDER/PUBLISH_SCHEDULE_REQUEST',
  PUBLISH_SCHEDULE_SUCCESS: 'SCHEDULE_BUILDER/PUBLISH_SCHEDULE_SUCCESS',
  PUBLISH_SCHEDULE_FAILURE: 'SCHEDULE_BUILDER/PUBLISH_SCHEDULE_FAILURE',
  FETCH_TEMPLATES_REQUEST: 'SCHEDULE_BUILDER/FETCH_TEMPLATES_REQUEST',
  FETCH_TEMPLATES_SUCCESS: 'SCHEDULE_BUILDER/FETCH_TEMPLATES_SUCCESS',
  FETCH_TEMPLATES_FAILURE: 'SCHEDULE_BUILDER/FETCH_TEMPLATES_FAILURE',
  CREATE_FROM_TEMPLATE_REQUEST: 'SCHEDULE_BUILDER/CREATE_FROM_TEMPLATE_REQUEST',
  CREATE_FROM_TEMPLATE_SUCCESS: 'SCHEDULE_BUILDER/CREATE_FROM_TEMPLATE_SUCCESS',
  CREATE_FROM_TEMPLATE_FAILURE: 'SCHEDULE_BUILDER/CREATE_FROM_TEMPLATE_FAILURE',
  DELETE_TEMPLATE_REQUEST: 'SCHEDULE_BUILDER/DELETE_TEMPLATE_REQUEST',
  DELETE_TEMPLATE_SUCCESS: 'SCHEDULE_BUILDER/DELETE_TEMPLATE_SUCCESS',
  DELETE_TEMPLATE_FAILURE: 'SCHEDULE_BUILDER/DELETE_TEMPLATE_FAILURE',
  RENAME_TEMPLATE_REQUEST: 'SCHEDULE_BUILDER/RENAME_TEMPLATE_REQUEST',
  RENAME_TEMPLATE_SUCCESS: 'SCHEDULE_BUILDER/RENAME_TEMPLATE_SUCCESS',
  RENAME_TEMPLATE_FAILURE: 'SCHEDULE_BUILDER/RENAME_TEMPLATE_FAILURE',
  SHOW_AFTER_PUBLISH_DRAWER: 'SCHEDULE_BUILDER/SHOW_AFTER_PUBLISH_DRAWER',
  SHOW_ONBOARDING_AFTER_PUBLISH_DRAWER:
    'SCHEDULE_BUILDER/SHOW_ONBOARDING_AFTER_PUBLISH_DRAWER',
  SET_SYNC_ERRORS: 'SCHEDULE_BUILDER/SET_SYNC_ERRORS',
  TOGGLE_SYNC_SHIFTS_DRAWER: 'SCHEDULE_BUILDER/TOGGLE_SYNC_SHIFTS_DRAWER',
  UPDATE_HAS_CREATED_NOTE: 'SCHEDULE_BUILDER/UPDATE_HAS_CREATED_NOTE',
  ENROLL_IN_SCHEDULE_BUILDER_BETA: 'ENROLL_IN_SCHEDULE_BUILDER_BETA',
};

export const showAfterPublishDrawer = () => ({
  type: actionTypes.SHOW_AFTER_PUBLISH_DRAWER,
});

export const checkPublishJobDoneLegacy = (dispatch, getState, jobId) => {
  const partnerName = getCurrentLocationPartnerName(getState());

  pollWorkerStatus(jobId)
    .then(resp => {
      const errors = JSON.parse(resp.errors);

      if (errors.length) {
        flashNotice.show(
          'error',
          toI18n('alerts.shifts_sync_failure_flash', {
            props: { partner: partnerName },
          })
        );
      } else {
        flashNotice.show(
          'notice',
          toI18n('alerts.shifts_sync_success_flash', {
            props: { partner: partnerName },
          })
        );
      }
      window.Backbone.Mediator.pub('schedule:sync-errors:add', errors);
    })
    .catch(() => {
      flashNotice.show(
        'error',
        toI18n('alerts.shifts_sync_failure_flash', {
          props: { partner: partnerName },
        })
      );
    });
};

export const togglePublishDrawer = (show, data, published = false) => ({
  type: actionTypes.TOGGLE_PUBLISH_DRAWER,
  payload: {
    data,
    published,
    drawerKey: PUBLISH_DRAWER_KEY,
    drawerOpen: show,
  },
});

export const publishScheduleLegacy =
  ({ startDate, endDate, departmentId, sendNotifications, afterSuccess }) =>
  (dispatch, getState) => {
    window.Homebase.showPreloader();

    return dispatch(
      withAlerts(
        createAsyncPutAction(
          routes.publishScheduleRoute(),
          [
            { type: actionTypes.PUBLISH_SCHEDULE_REQUEST },
            { type: actionTypes.PUBLISH_SCHEDULE_SUCCESS },
            { type: actionTypes.PUBLISH_SCHEDULE_FAILURE },
          ],
          {
            body: {
              department_id: departmentId,
              send_notifications: sendNotifications,
              start_date: startDate.format(df('parsable_date')),
              end_date: endDate.format(df('parsable_date')),
            },
          }
        ),
        {
          noErrorFlash: true,
          onError: response => {
            const { access_error, feature, min_tier } =
              response.payload.response;
            window.Homebase.hidePreloader();
            if (access_error) {
              showUpgradeModal(min_tier, feature);
            }
          },
          onSuccess: async response => {
            trackPublishDrawerUxEvents({
              eventAction: EVENT_ACTIONS.SCHEDULE_PUBLISHED,
            });

            window.Backbone.Mediator.pub(
              'schedule:after_publish',
              departmentId,
              response.payload
            );

            const jobId = response.payload.job_id;
            if (jobId) {
              checkPublishJobDoneLegacy(dispatch, getState, jobId);
            }

            dispatch(showAfterPublishDrawer());

            dispatch(meteringActions.fetchMeteringShiftNotesForDateCount());

            window.Homebase.hidePreloader();

            if (afterSuccess) {
              afterSuccess();
            }
          },
        }
      )
    );
  };

export const toggleEmptyScheduleDrawer = ({ visible, ...params }) => ({
  type: actionTypes.TOGGLE_EMPTY_SCHEDULE_DRAWER,
  payload: {
    ...params,
    drawerKey: EMPTY_SCHEDULE_DRAWER_KEY,
    drawerOpen: visible,
  },
});

export const updateCurrentTemplate = id => ({
  type: actionTypes.UPDATE_CURRENT_TEMPLATE,
  payload: { id },
});

export const togglePrintDrawer = (show, viewType, startDate, endDate) => ({
  type: actionTypes.TOGGLE_PRINT_DRAWER,
  payload: {
    viewType,
    startDate,
    endDate,
    drawerKey: PRINT_DRAWER_KEY,
    drawerOpen: show,
  },
});

export const toggleTemplatesDrawer =
  (forceShow, currentTemplateId) => (dispatch, getState) => {
    const show =
      forceShow === undefined
        ? !buildGetDrawerOpen(TEMPLATES_DRAWER_KEY)(getState())
        : forceShow;

    dispatch({
      type: actionTypes.TOGGLE_TEMPLATES_DRAWER,
      payload: {
        drawerKey: TEMPLATES_DRAWER_KEY,
        drawerOpen: !!show,
        currentTemplateId,
      },
    });

    window.Homebase.RailsReactBridge.templatesDrawerToggled(show);
  };

export const fetchScheduleTemplates = () =>
  createAsyncGetAction(routes.scheduleTemplatesRoute(), [
    { type: actionTypes.FETCH_TEMPLATES_REQUEST },
    { type: actionTypes.FETCH_TEMPLATES_SUCCESS },
    { type: actionTypes.FETCH_TEMPLATES_FAILURE },
  ]);

export const renameScheduleTemplate = (templateId, name) =>
  createAsyncPutAction(
    routes.scheduleTemplateRoute(templateId),
    [
      { type: actionTypes.RENAME_TEMPLATE_REQUEST },
      { type: actionTypes.RENAME_TEMPLATE_SUCCESS },
      { type: actionTypes.RENAME_TEMPLATE_FAILURE, meta: { templateId, name } },
    ],
    {
      body: {
        template: { name },
      },
    }
  );

export const deleteScheduleTemplate = templateId =>
  createAsyncDeleteAction(routes.scheduleTemplateRoute(templateId), [
    { type: actionTypes.DELETE_TEMPLATE_REQUEST, meta: { templateId } },
    { type: actionTypes.DELETE_TEMPLATE_SUCCESS, meta: { templateId } },
    { type: actionTypes.DELETE_TEMPLATE_FAILURE, meta: { templateId } },
  ]);

const initializeCreateFromTemplate = (templateId, date, createEvent) =>
  createAsyncPostAction(
    routes.createFromTemplateRoute(templateId),
    [
      { type: actionTypes.CREATE_FROM_TEMPLATE_REQUEST },
      {
        type: actionTypes.CREATE_FROM_TEMPLATE_SUCCESS,
        meta: { createEvent, templateId },
      },
      { type: actionTypes.CREATE_FROM_TEMPLATE_FAILURE },
    ],
    { body: { template_id: templateId, date: date.format('MM/DD/YYYY') } }
  );

const showError = () => {
  window.Homebase.hidePreloader();
  flashNotice.show('error', toI18n('errors.generic'));
};

const handleCreateFromTemplateResponse = async (response, getState) => {
  if (response.type === actionTypes.CREATE_FROM_TEMPLATE_SUCCESS) {
    try {
      const templates = getScheduleTemplates(getState());
      const template = templates.find(
        t => t.get('id') === response.meta.templateId
      );

      await pollWorkerStatus(response.payload.id);

      if (getPreviousWeeksOnJumpstartDrawerEnabled(getState())) {
        trackSuccessBannerEvent(
          EVENT_ACTIONS.JUMPSTART_TEMPLATE_SUCCESS_BANNER_SHOWN,
          TRACK_ACTION_TYPES.VIEW
        );
      }

      flashNotice.success(
        toI18n('schedule_builder.templates_drawer.success_banner', {
          props: { name: template.get('name') },
        })
      );
      window.Backbone.Mediator.pub('schedule:navigation:rerender');
    } catch (e) {
      showError();
    }
  } else {
    showError();
  }
};

export const createFromTemplate =
  (templateId, date) => async (dispatch, getState) => {
    window.Homebase.showPreloader();

    const response = await dispatch(
      initializeCreateFromTemplate(templateId, date, false)
    );

    handleCreateFromTemplateResponse(response, getState);
  };

const initializeCreateTemplate = (date, { repeat, ...params }) =>
  createAsyncPostAction(
    routes.scheduleTemplatesRoute(),
    [
      { type: actionTypes.CREATE_FROM_TEMPLATE_REQUEST },
      {
        type: actionTypes.CREATE_FROM_TEMPLATE_SUCCESS,
        meta: { createEvent: !params.repeating },
      },
      { type: actionTypes.CREATE_FROM_TEMPLATE_FAILURE },
    ],
    { body: { template: params, date, repeat } }
  );

export const createTemplate =
  (date, { successMessage, ...params }) =>
  dispatch => {
    window.Homebase.showPreloader();

    dispatch(
      withAlerts(initializeCreateTemplate(date, params), {
        onDone: window.Homebase.hidePreloader,
        success: successMessage,
      })
    );
  };

export const createTemplateAndApply =
  (templateDate, scheduleDate, params) => (dispatch, getState) => {
    window.Homebase.showPreloader();

    dispatch(
      withAlerts(initializeCreateTemplate(templateDate, params), {
        onSuccess: async response => {
          const createTemplateResponse = await dispatch(
            initializeCreateFromTemplate(
              response.payload.id,
              scheduleDate,
              true
            )
          );
          handleCreateFromTemplateResponse(createTemplateResponse, getState);
        },
      })
    );
  };

export const updateHasCreatedNote = hasCreatedNote => ({
  type: actionTypes.UPDATE_HAS_CREATED_NOTE,
  payload: {
    hasCreatedNote,
  },
});

export const enrollInScheduleBuilderBeta = () => {
  window.Homebase.showPreloader();
  toggleExperiment('1').then(() => {
    window.location.reload();
  });

  return {
    type: actionTypes.ENROLL_IN_SCHEDULE_BUILDER_BETA,
    payload: {},
  };
};
