import { fromJS, List, Map, OrderedMap, Set } from 'immutable';
import { every, isEmpty, values } from 'lodash';

import { ssnPublicKey } from 'features/employeeOnboarding/constants';

import { moment } from 'util/dateTime';
import { encryptWithPublicKey } from 'util/encryption';
import * as formsUtil from 'util/forms';

import * as constants from './constants';

// # form data => server data
const serverFormatters = {
  certificateExpirationDate: val => {
    if (!val) {
      return val;
    }

    const [month, day, year] = val.split('/');
    return `${month}/${day}/${year}`;
  },

  certificates: (certificateFields, initialCertificateFields) => {
    const currentCertificates = certificateFields.reduce(
      (certificatePayloads, certificate, id) => {
        const newCertificate = id && id.startsWith('NEW');
        const certificateImage = certificate.getIn(['image', 'value']);
        certificatePayloads.push({
          name: certificate.getIn(['name', 'value']),
          expiration_date: certificate.getIn(['expiration_date', 'value']),
          image: certificate.getIn(['image', 'value']),
          remove_image: !newCertificate && !certificateImage,
          id: newCertificate ? null : id,
        });

        return certificatePayloads;
      },
      []
    );

    const currentCertificateIds = Set(certificateFields.keys());
    const persistedCertificateIds = Set(initialCertificateFields.keys());
    const deletedIds = persistedCertificateIds.subtract(currentCertificateIds);

    // Gets keys for any deleted certificates and formats them for what the server expects
    const finalcertificatePayloads = currentCertificates.concat(
      deletedIds.map(id => ({ id, _destroy: 1 })).toArray()
    );

    return finalcertificatePayloads;
  },

  emergencyContactAttributes: (fields, initial) => {
    const emergencyContactName = fields.getIn([
      'emergency_contact',
      'full_name',
      'value',
    ]);
    const emergencyContactPhone = fields.getIn([
      'emergency_contact',
      'phone',
      'value',
    ]);
    const emergencyContactId = initial.getIn([
      'emergency_contact',
      'id',
      'value',
    ]);

    if (emergencyContactName || emergencyContactPhone) {
      return {
        id: emergencyContactId,
        full_name: emergencyContactName,
        phone: emergencyContactPhone,
      };
    } else if (emergencyContactId) {
      return {
        id: emergencyContactId,
        _destroy: '1',
      };
    }
  },

  roleWagesAttributes: (roleWages, initialRoleWages = List()) => {
    const roleWagesAttrs = [];

    let persistedRoleWageIds = initialRoleWages
      .map(roleWage => roleWage.getIn(['id', 'value']))
      .toSet();

    roleWages.forEach(roleWage => {
      const id = roleWage.getIn(['id', 'value']);
      const baseParams = {
        role_name: roleWage.getIn(['role_name', 'value']),
        payroll_id: roleWage.getIn(['payroll_id', 'value']),
        remove_historical_wages: roleWage.getIn([
          'remove_historical_wages',
          'value',
        ]),
      };

      // do not send wage fields if manager can't manage them (if they are not rendered on the form)
      if (roleWage.getIn(['wage_rate', 'value']) !== null) {
        baseParams.wage_rate = roleWage.getIn(['wage_rate', 'value']);
      }

      const initialWage = initialRoleWages.get(id);

      if (
        !initialWage ||
        (!baseParams.remove_historical_wages &&
          initialWage.getIn(['wage_rate', 'value']) !== baseParams.wage_rate)
      ) {
        baseParams.effective_date = roleWage.getIn(['effective_date', 'value']);
      }

      if (typeof id === 'string' && id.startsWith('NEW')) {
        roleWagesAttrs.push(baseParams);
      } else {
        persistedRoleWageIds = persistedRoleWageIds.delete(id);
        roleWagesAttrs.push({ ...baseParams, id });
      }
    });

    // Any persisted role wages left over must have been deleted.
    persistedRoleWageIds.forEach(id =>
      roleWagesAttrs.push({ id, _destroy: 1 })
    );

    return roleWagesAttrs;
  },

  jobsAttributes: (fields, initial, locations) => {
    let jobsFields = fields.get('job_attributes');
    const initialJobsFields = initial.get('job_attributes');
    const userId = fields.getIn(['id', 'value']);

    jobsFields = jobsFields.filter((jobFields, locationId) =>
      locations.get(locationId)
    );

    const currentJobAttributes = jobsFields.map((jobFields, locationId) => {
      const initialJobFields = initialJobsFields.get(locationId);
      const isEnabled = jobFields.getIn(['enabled', 'value']);
      const existingJobId =
        initialJobFields && initialJobFields.getIn(['job_id', 'value']);
      const defaultRoleName = jobFields.getIn(['default_role_name', 'value']);
      const defaultRoleId = defaultRoleName ? null : 0;
      const claimOpenShift = jobFields.getIn(['claim_open_shift', 'value']);

      const attrs = {
        claim_open_shift: claimOpenShift,
        in_schedule: jobFields.getIn(['in_schedule', 'value']) || false,
        excluded_late_alert: jobFields.getIn(['excluded_late_alert', 'value']),
        excluded_timeclock_errors: jobFields.getIn([
          'excluded_timeclock_errors',
          'value',
        ]),
        user_id: userId,
        location_id: locationId,
        wage_type: jobFields.getIn(['wage_type', 'value']),
        default_role_name: jobFields.getIn(['default_role_name', 'value']),
        default_role_id: defaultRoleId,
        wage_payroll_id: jobFields.getIn(['wage_payroll_id', 'value']),
        permanent: jobFields.getIn(['permanent', 'value']),
        hire_date: jobFields.getIn(['hire_date', 'value']),
        remove_historical_wages: jobFields.getIn([
          'remove_historical_wages',
          'value',
        ]),
      };

      // do not send wage fields if manager can't manage them (if they are not rendered on the form)
      if (jobFields.getIn(['wage_rate', 'value']) !== null) {
        attrs.wage_rate = jobFields.getIn(['wage_rate', 'value']);
      }

      if (!attrs.remove_historical_wages) {
        attrs.effective_date = jobFields.getIn(['effective_date', 'value']);
      }

      const roleWages = jobFields.get('role_wages');
      const initialRoleWages =
        initialJobFields && initialJobFields.get('role_wages');
      if (roleWages) {
        attrs.role_wages_attributes = serverFormatters.roleWagesAttributes(
          roleWages,
          initialRoleWages
        );
      }

      // Brand new - this user has never had a job at this location.
      if (!existingJobId) {
        // If disabled, only the checkbox values should be set. Indicate that it's not permanent
        // and eject without setting level, pin, or payroll_id.
        attrs.permanent = !!isEnabled;
        if (!isEnabled) {
          return attrs;
        }

        // User already has a job at this location. May be archived.
      } else {
        const wasArchived = initialJobFields.getIn(['archived_at', 'value']);

        attrs.id = existingJobId;

        if (!isEnabled && claimOpenShift) {
          attrs.permanent = false;
        }

        // If disabled, only the checkbox values should be set. Indicate whether this job needs
        // to be archived (i.e., it used to be enabled but now isn't) and eject without setting
        // level, pin, or payroll_id.
        if (!isEnabled) {
          if (!wasArchived) {
            attrs.archive = true;
            attrs.level = 'Employee';
          }
          return attrs;
        }

        if (wasArchived) {
          attrs.archive = false;
        }
      }

      // This job is enabled - add level, pin, and payroll_id
      attrs.level = jobFields.getIn(['level', 'value']);
      attrs.pin = jobFields.getIn(['pin', 'value']);
      attrs.payroll_id = jobFields.getIn(['payroll_id', 'value']);

      return attrs;
    });

    return currentJobAttributes.toArray();
  },

  wageRate: rate => {
    if (rate || rate === 0) {
      return rate.toFixed(2);
    }

    return rate;
  },

  addressAttributes: (fields, initial) => {
    const attributes = {
      id: initial.getIn(['address', 'id', 'value']),
      address_1: fields.getIn(['address', 'address_1', 'value']),
      address_2: fields.getIn(['address', 'address_2', 'value']),
      city: fields.getIn(['address', 'city', 'value']),
      state: fields.getIn(['address', 'state', 'value']),
      zip: fields.getIn(['address', 'zip', 'value']),
      country_code: fields.getIn(['address', 'country_code', 'value']),
    };

    // When employee has no address and another employee information section
    // like "Emergency Contact" is edited we don't send address_attributes
    // so the other information can be saved correctly.
    // If we don't do this they will get an error that zipcode is missing (due to
    // address model validations) and other edited information won't be saved
    if (every(values(attributes), isEmpty)) {
      return null;
    }

    return attributes;
  },
};

// Map :: Object
export const formatFormUserDataForServer = (fields, initial, locations) => {
  const userId = fields.getIn(['id', 'value']);
  const initialUserId = initial.getIn(['id', 'value']);

  const payload = {
    id: userId,
    first_name: fields.getIn(['first_name', 'value']),
    last_name: fields.getIn(['last_name', 'value']),
    phone: formsUtil.serverFormatters.phone(fields.getIn(['phone', 'value'])),
    email: fields.getIn(['email', 'value']),
    date_of_birth: formsUtil.serverFormatters.date(
      fields.getIn(['date_of_birth', 'value'])
    ),
    address_attributes: serverFormatters.addressAttributes(fields, initial),
    encrypted_ssn: encryptWithPublicKey(
      ssnPublicKey(),
      fields.getIn(['ssn', 'value'])
    ),
    dismissed_unpublished_shift_banner: fields.getIn([
      'dismissed_unpublished_shift_banner',
      'value',
    ]),
    certificates_attributes: serverFormatters.certificates(
      fields.get('certificates'),
      initial.get('certificates')
    ),
    emergency_contact_attributes: serverFormatters.emergencyContactAttributes(
      fields,
      initial
    ),
    jobs_attributes: serverFormatters.jobsAttributes(
      fields,
      initial,
      locations
    ),
    send_invite: fields.getIn(['send_invite', 'value']),
  };

  // If the user's ID has been changed, it means that we need to combine the new user
  // with the initial user. The back-end knows to do this when it receives an additional
  // parameter, `combine_with_user_id`, containing the initial user's ID
  if (userId !== initialUserId) {
    payload.combine_with_user_id = initialUserId;
  }

  return payload;
};

// # server data => form data
export const formFormatters = {
  certificateExpirationDate: val => {
    if (!val) {
      return val;
    }

    const [month, day, year] = val.split('/');
    return `${month}/${day}/${year}`;
  },

  certificates: certificates => {
    if (!certificates) {
      return List();
    }

    return certificates.map(certificate =>
      certificate.update(
        'expiration_date',
        formFormatters.certificateExpirationDate
      )
    );
  },
};

// Map :: Map
export const buildEmployeeFormDataForUser = user => {
  const jobs = user.get('jobs');
  const certificates = user.get('certificates');
  // Sort to reliably get the same job as "first"
  const firstJob = jobs.sortBy(job => job.get('id')).first();

  return fromJS({
    id: formsUtil.buildInitialFieldState(user.get('id')),
    first_name: formsUtil.buildInitialFieldState(user.get('first_name')),
    last_name: formsUtil.buildInitialFieldState(user.get('last_name')),
    phone: formsUtil.buildInitialFieldState(
      formsUtil.formFormatters.phone(user.get('phone'))
    ),
    email: formsUtil.buildInitialFieldState(user.get('email')),
    date_of_birth: formsUtil.buildInitialFieldState(
      formsUtil.formFormatters.date(user.get('date_of_birth'))
    ),
    ssn: formsUtil.buildInitialFieldState(user.get('decrypted_ssn')),
    address: {
      id: formsUtil.buildInitialFieldState(user.getIn(['address', 'id'])),
      address_1: formsUtil.buildInitialFieldState(
        user.getIn(['address', 'address_1'])
      ),
      address_2: formsUtil.buildInitialFieldState(
        user.getIn(['address', 'address_2'])
      ),
      city: formsUtil.buildInitialFieldState(user.getIn(['address', 'city'])),
      state: formsUtil.buildInitialFieldState(user.getIn(['address', 'state'])),
      zip: formsUtil.buildInitialFieldState(user.getIn(['address', 'zip'])),
      country_code: formsUtil.buildInitialFieldState(
        user.getIn(['address', 'country_code'])
      ),
    },
    emergency_contact: {
      id: formsUtil.buildInitialFieldState(
        user.getIn(['emergency_contact', 'id'])
      ),
      full_name: formsUtil.buildInitialFieldState(
        user.getIn(['emergency_contact', 'full_name'])
      ),
      phone: formsUtil.buildInitialFieldState(
        formsUtil.formFormatters.phone(
          user.getIn(['emergency_contact', 'phone'])
        )
      ),
    },
    certificates: formFormatters.certificates(certificates).reduce(
      (orderedMap, certificate) =>
        orderedMap.set(
          certificate.get('id').toString(),
          Map({
            name: formsUtil.buildInitialFieldState(certificate.get('name')),
            expiration_date: formsUtil.buildInitialFieldState(
              certificate.get('expiration_date')
            ),
            image: formsUtil.buildInitialFieldState(
              certificate.get('image_url')
            ),
            id: certificate.get('id').toString(),
          })
        ),
      OrderedMap()
    ),
    job_attributes: jobs.reduce(
      (jobAttributes, job) =>
        jobAttributes.set(
          job.get('location_id').toString(),
          Map({
            location_name: formsUtil.buildInitialFieldState(
              job.get('location_name')
            ),
            archived_at: formsUtil.buildInitialFieldState(
              job.get('archived_at')
            ),
            enabled: formsUtil.buildInitialFieldState(
              !job.get('archived_at') && job.get('permanent')
            ),
            permanent: formsUtil.buildInitialFieldState(job.get('permanent')),
            level: formsUtil.buildInitialFieldState(job.get('level')),
            pin: formsUtil.buildInitialFieldState(job.get('pin')),
            payroll_id: formsUtil.buildInitialFieldState(job.get('payroll_id')),
            in_schedule: formsUtil.buildInitialFieldState(
              job.get('in_schedule')
            ),
            excluded_late_alert: formsUtil.buildInitialFieldState(
              job.get('excluded_late_alert')
            ),
            excluded_timeclock_errors: formsUtil.buildInitialFieldState(
              job.get('excluded_timeclock_errors')
            ),
            claim_open_shift: formsUtil.buildInitialFieldState(
              job.get('claim_open_shift')
            ),
            job_id: formsUtil.buildInitialFieldState(job.get('id')),
            wage_rate: formsUtil.buildInitialFieldState(
              serverFormatters.wageRate(job.get('wage_rate'))
            ),
            wage_type: formsUtil.buildInitialFieldState(job.get('wage_type')),
            wage_payroll_id: formsUtil.buildInitialFieldState(
              job.get('wage_payroll_id')
            ),
            default_role_name: formsUtil.buildInitialFieldState(
              job.get('default_role_name') || ''
            ),
            hire_date: formsUtil.buildInitialFieldState(job.get('hire_date')),
            role_wages: (job.get('role_wages') || List())
              .sortBy(roleWage => roleWage.get('id'))
              .map(roleWage =>
                Map({
                  id: formsUtil.buildInitialFieldState(roleWage.get('id')),
                  payroll_id: formsUtil.buildInitialFieldState(
                    roleWage.get('payroll_id')
                  ),
                  role_name: formsUtil.buildInitialFieldState(
                    roleWage.get('role_name')
                  ),
                  remove_historical_wages:
                    formsUtil.buildInitialFieldState(false),
                  effective_date: formsUtil.buildInitialFieldState(
                    moment().format('MM/DD/YYYY')
                  ),
                  wage_rate: formsUtil.buildInitialFieldState(
                    serverFormatters.wageRate(roleWage.get('wage_rate'))
                  ),
                })
              ),
            historical_wages: job.get('historical_wages') || List(),
            shift_exists: job.get('shift_exists'),
            quickbooks_linked: formsUtil.buildInitialFieldState(
              job.get('quickbooks_linked')
            ),
            remove_historical_wages: formsUtil.buildInitialFieldState(false),
            effective_date: formsUtil.buildInitialFieldState(
              moment().format('MM/DD/YYYY')
            ),
          })
        ),
      OrderedMap()
    ),

    // For stashing values to be referenced when building the server-side payload
    meta: {
      current_job_id: firstJob.get('id'),
    },
  });
};

export const buildEmployeeFormDataForNewUser = currentLocation => {
  const state = constants.INITIAL_EMPLOYEE_FORM_STATE.get('fields').setIn(
    ['job_attributes', currentLocation.get('id').toString()],
    constants.INITIAL_JOB_ATTRIBUTES_STATE.merge({
      enabled: formsUtil.buildInitialFieldState(true),
    })
  );
  return state.set(
    'id',
    formsUtil.buildInitialFieldState(constants.NEW_USER_ID)
  );
};

export const fieldContainsErrors = (errors, errorType) =>
  errors && errors.type === errorType;
