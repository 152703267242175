import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'initializers/types';

import { JobApplication, JobApplicationsState } from './types';

const SLICE_NAME = 'jobApplications';

const initialState: JobApplicationsState = {
  jobApplications: [],
  loading: true,
  error: null,
  meta: {
    total_count: 0,
    screened_count: 0,
    shortlisted_count: 0,
  },
};

const jobApplicationsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setJobApplications(state, action: PayloadAction<JobApplication[]>) {
      state.jobApplications = action.payload;
    },
    setJobApplicationsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setJobApplicationsError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    setJobApplicationsTotalCount(state, action: PayloadAction<number>) {
      state.meta.total_count = action.payload;
    },
    setJobApplicationsScreenedCount(state, action: PayloadAction<number>) {
      state.meta.screened_count = action.payload;
    },
    setJobApplicationsShortlistedCount(state, action: PayloadAction<number>) {
      state.meta.shortlisted_count = action.payload;
    },
  },
});

// Selectors
const getJobApplicationsState = (state: RootState) =>
  state.get('hiringV2').jobApplications;

export const selectJobApplications = (state: RootState): JobApplication[] =>
  getJobApplicationsState(state).jobApplications;

export const selectJobApplicationsLoading = (state: RootState): boolean =>
  getJobApplicationsState(state).loading;

export const selectJobApplicationsError = (state: RootState): string | null =>
  getJobApplicationsState(state).error;

export const selectJobApplicationsTotalCount = (state: RootState): number =>
  getJobApplicationsState(state).meta.total_count;

export const selectJobApplicationsScreenedCount = (state: RootState): number =>
  getJobApplicationsState(state).meta.screened_count;

export const selectJobApplicationsShortlistedCount = (
  state: RootState
): number => getJobApplicationsState(state).meta.shortlisted_count;

export const {
  setJobApplications,
  setJobApplicationsLoading,
  setJobApplicationsError,
  setJobApplicationsTotalCount,
  setJobApplicationsScreenedCount,
  setJobApplicationsShortlistedCount,
} = jobApplicationsSlice.actions;

export const reducer = jobApplicationsSlice.reducer;
