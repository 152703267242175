import React, { PureComponent } from 'react';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import Text from 'components/Text';

import cxHelpers from 'util/className';
import { setOriginContextByUrl, trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
} from 'util/tracking_constants';

import { TEAM_ROUTES } from '../../constants';
import withNavItemActive from '../../hocs/withNavItemActive';
import withNavItemData from '../../hocs/withNavItemData';
import NavItem from '../NavItem';
import NavItemWithSubNav from '../NavItemWithSubNav';

@withNavItemActive(TEAM_ROUTES)
@withNavItemData('team')
@cxHelpers('TeamNavItem')
export default class TeamNavItem extends PureComponent {
  static propTypes = {
    url: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  };

  // Opens in browser tab if cmd+click; otherwise, renders without full refresh
  handleClick = e => {
    setOriginContextByUrl(this.props.url);
    trackUxEvent({
      productArea: PRODUCT_AREAS.NAVIGATION,
      eventCategory: EVENT_CATEGORIES.NAV,
      eventAction: EVENT_ACTIONS.TEAM_TAB_CLICKED,
    });

    if (!e.metaKey) {
      e.preventDefault();
      window.Homebase.RailsReactBridge.navigateToReactView(this.props.url);
      return false;
    }
  };

  render() {
    const { url, currentRoute, ...props } = omit(this.props, 'whiteText');
    const Component = props.menuItems ? NavItemWithSubNav : NavItem;

    return (
      <Component
        {...props}
        currentRoute={currentRoute}
        href={url}
        onClick={this.handleClick}
        className={this.cx()}
      >
        <Text fs14 i18n="nav_links.team.top_level" />
      </Component>
    );
  }
}
